<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1 class="has-tooltip">{{ 'games' | translate }}
                <button #turnsTooltip="matTooltip" mat-raised-button
                        matTooltip="{{ 'gameListToolTip' | translate}}">
                    ?
                </button>
            </h1>
        </div>
        <div *ngIf="currentUser.role !== null && currentUser.role !== 'student'"
             class="container-flex align-items-center">
            <button [routerLink]="['/games/create']" class="button-primary">{{ 'createNewGame' | translate }}</button>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div class="row">
    <mat-form-field appearance="standard">
        <mat-label>{{ 'searchByName' | translate }}</mat-label>
        <input #searchInput matInput>
    </mat-form-field>
</div>

<div [hidden]="dataSource.data?.length <= 0" [ngClass]="{'d-none': loading}" class="row">
    <table #gameTable [dataSource]="dataSource" class="mat-selectable" mat-table matSort>
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let row" mat-cell></td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'name' | translate}} </th>
            <td *matCellDef="let game" mat-cell><span class="column-width">{{ game.name | gameName }}</span></td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'id' | translate}} </th>
            <td *matCellDef="let game" mat-cell> {{game.id}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'status' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell>{{ game.is_active === false && game.is_complete === false ? (states.pending | translate) : game.is_complete === true ? (states.complete | translate) : (states.inProgress | translate) }}</td>
        </ng-container>

        <ng-container matColumnDef="game_start">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'gameStart' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{ !game.start_time ? ('manual' | translate) : 'automatic' | translate }} </td>
        </ng-container>

        <ng-container matColumnDef="turn_advancement">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'turnCompletion' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{ game.turn_advancement === 'Timed' ? ('automatic' | translate) : game.turn_advancement.toLowerCase() | translate }} </td>
        </ng-container>

        <ng-container matColumnDef="quarter_duration">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'quarterDuration' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{game.quarter_duration ? game.quarter_duration === 1440 ? ('24Hours' | translate) : game.quarter_duration : 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="scenario">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'scenario' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{game.scenario_id === null ? ('none' | translate) : (game.scenario_name | translate) }} </td>
        </ng-container>

        <ng-container matColumnDef="quarters">
            <th *matHeaderCellDef mat-header-cell> {{'quarters' | translate}} </th>
            <td *matCellDef="let game" mat-cell> {{'currentGameQuarterOfGameDuration' | translate:{
                current_quarter: game.current_quarter + (game.is_active === false && game.is_complete === false ? 0 : 1),
                game_duration: game.duration
            } }} </td>
        </ng-container>

        <ng-container matColumnDef="starting_cash">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'difficulty' | translate}} </th>
            <td *matCellDef="let game" mat-cell> {{game.starting_cash  | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="is_singleplayer">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'type' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{ game.is_singleplayer === true ? ('singlePlayer' | translate) : ('multiplayer' | translate) }} </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'role' | translate}} </th>
            <td *matCellDef="let game"
                mat-cell> {{ currentUserId === game.uid ? ('creator' | translate) : ('participant' | translate) }} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" [routerLink]="[ '/games/detail', row.id ]"
            mat-row></tr>
    </table>

    <div class="my-3">
        <mat-paginator (page)="updatePage($event)"
                       [length]="pageTotal"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageSize]="pageSize">
        </mat-paginator>
    </div>
</div>
<ng-container *ngIf="dataSource.data.length === 0 && loading === false">
    <div [ngSwitch]="currentUser.role" class="flex-container empty-container">
        <div *ngSwitchCase="'student'" class="p-5 text-center"><p>{{ 'noGamesFoundAlert' | translate }}</p></div>
        <div *ngSwitchDefault class="p-5 text-center"><p>{{ 'noGamesFoundAlert' | translate }}</p></div>
    </div>
</ng-container>
