<h1 mat-dialog-title>{{ 'warning' | translate }}</h1>
<div mat-dialog-content style="padding-bottom: 0.5em">
    <div *ngIf="data.sponsors">
        <p>
            {{ 'deleteSponsorAlert' | translate}}
        </p>
        <table [dataSource]="data.sponsors" class="mat-elevation-z8" mat-table>
            <ng-container matColumnDef="id">
                <th *matHeaderCellDef mat-header-cell> {{ 'id' | translate }} </th>
                <td *matCellDef="let element" mat-cell> {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th *matHeaderCellDef mat-header-cell> {{ 'title' | translate }} </th>
                <td *matCellDef="let element" mat-cell> {{element.title}} </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" class="button-primary" mat-button
            style="margin: 0 auto;">{{ 'cancel' | translate }}</button>
    <button [mat-dialog-close]="true" cdkFocusInitial class="button-primary button-warning" mat-button
            style="margin: 0 auto;">{{ 'delete' | translate }}</button>
</div>
