import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {UploadService} from '../../shared/upload.service';
import {SponsorshipService} from '../sponsorships.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpEventType} from '@angular/common/http';
import {Observable} from 'rxjs';
import {debounceTime, finalize, map, startWith, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {GameNamePipe} from '../../shared/pipes/gamename.pipe';

@Component({
    selector: 'app-sponsorship-create',
    templateUrl: './sponsorships-create.component.html',
    styleUrls: ['./sponsorships-create.component.scss'],
    providers: [
        GameNamePipe
    ],
})
export class SponsorshipCreateComponent implements OnInit {

    areaControl = new UntypedFormControl();
    gameControl = new UntypedFormControl();
    areaOptions = [];
    filteredAreaOptions: Observable<string[]>;
    filteredGameOptions: string[] = [];
    editMode = false;
    loading = false;
    isDisabled = false;
    isLoadingGames = false;

    error: string;

    testImage = new Image();

    uploadResponse: { status: string, message: number, filePath: string } = null;
    // @ts-ignore
    uploadStatus = {
        'logo': this.uploadResponse
    };

    /* Form related */
    typeGroupValue = 'small';
    rulesGroupValue = 'area';
    formGroup = this._formBuilder.group({
        title: ['', Validators.required],
        logo: ['', Validators.required],
        active: true/* ,
      sizeValid: [false, Validators.requiredTrue] */
    });
    sizeValues = [
        {
            label: 'small',
            width: 230,
            height: 70
        },
        {
            label: 'large',
            width: 500,
            height: 500
        }
    ];

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private uploadService: UploadService,
        private sponsorshipService: SponsorshipService,
        private route: ActivatedRoute,
        private router: Router,
        private readonly translateService: TranslateService,
        private readonly gameNamePipe: GameNamePipe
    ) {
    }

    ngOnInit() {
        this.loading = true;

        this.gameControl.valueChanges.pipe(
            debounceTime(500),
            tap(() => this.isLoadingGames = true),
            switchMap(value => this.sponsorshipService.getGamesByName(value, 20).pipe(
                finalize(() => this.isLoadingGames = false),
            ))
        ).subscribe(games => this.filteredGameOptions = games);

        this.uploadStatus.logo = {status: '', message: 0, filePath: ''};
        this.sponsorshipService.getAreas().subscribe(areas => {
            this.areaOptions = areas;
            this.filteredAreaOptions = this.areaControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filter_areas(value))
            );
            const sponsorId = this.route.snapshot.params.id;
            if (sponsorId) {
                // Get sponsor from DB via API call
                this.sponsorshipService.getSponsor(sponsorId).subscribe(res => {
                    console.log('res.active', res.active);
                    this.populateForm(res);
                    this.editMode = true;
                    this.isDisabled = true;
                    console.log('this.isDisabled', this.isDisabled);
                    this.loading = false;

                });
            } else {
                this.loading = false;
            }
        });
    }

    populateForm(response) {
        // Set select-form value(s)
        if (response.area_id) {
            this.rulesGroupValue = 'area';

            let selected_area;
            this.areaOptions.forEach(area => {
                if (response.area_id === area.id) {
                    selected_area = area;
                }
            });
            this.areaControl.setValue(selected_area);
        } else if (response.game_id) {
            this.rulesGroupValue = 'game';
            this.sponsorshipService.getGameById(response.game_id).subscribe(game => {
                this.gameControl.setValue(game);
            });
        }
        this.uploadStatus['id'] = response.id;

        // set Sponsor Type toggle fields
        this.typeGroupValue = response.sponsor_type;

        // Set image fields
        this.uploadStatus.logo['status'] = 'progress';
        this.uploadStatus.logo['message'] = 100;
        this.uploadStatus.logo['filePath'] = response.logo_url;
        this.uploadStatus.logo['fileId'] = response.logo;

        // Set text fields
        this.formGroup.patchValue({
            title: response.title,
            logo: [''],
            active: response.active
        });

        console.log('formgroup active', this.formGroup.controls['active'].value);
    }

    getArea(nameOrObject) {
        const isAnObject = nameOrObject && nameOrObject.hasOwnProperty('name');
        let name;
        let found = null;
        if (isAnObject) {
            name = nameOrObject.name;
        } else {
            name = nameOrObject;
        }
        this.areaOptions.forEach(area => {
            if (name === area.name) {
                found = area;
            }
        });
        return found;
    }

    ensureDisabledCondition() {
        // Used to determine 'Upload Sponsor' button visibility
        // - Checks if the given control (based on user selection) is null
        let value;
        let expression;
        switch (this.rulesGroupValue) {
            case 'area':
                value = this.areaControl.value;
                expression = value === null || this.getArea(value) === null;
                return expression;
            case 'game':
                value = this.gameControl.value;
                expression = value === null || !value.id;
                return expression;
            default:
                return false;
        }
    }

    _filter_areas(value) {
        const is_object = value instanceof Object;
        if (is_object && value.hasOwnProperty('name')) {
            value = value.name;
        }
        if (value) {
            const filteredValue = value.toLowerCase();
            return this.areaOptions.filter(option => option.name.toLowerCase().indexOf(filteredValue) === 0);
        }
    }

    displayAreaName(area) {
        return area && area.name ? area.name : '';
    }

    displayGameName(game) {
        return game && game.name ? this.gameNamePipe.transform(game.name) : '';
    }

    updateTypeGroupValue(new_value) {
        // Called by HTML code to update our components state so we know
        // which rule has been selected.
        this.typeGroupValue = new_value;

        // delete image
        // this.uploadStatus.logo['status'] = '';
        // this.uploadStatus.logo['message'] = 0;
        // this.uploadStatus.logo['filePath'] = null;
        this.uploadStatus.logo['fileId'] = '';

        this.uploadStatus['logo'].status = 'progress';
        this.uploadStatus['logo'].message = 0;
        this.uploadStatus['logo'].filePath = null;
        console.log('this.uploadStatus[\'logo\'].filePath', this.uploadStatus['logo'].filePath);
        // Set text fields
        this.formGroup.patchValue({
            logo: null
        });

    }

    updateRulesGroupValue(new_value) {
        // Called by HTML code to update our components state so we know
        // which rule has been selected.
        this.rulesGroupValue = new_value;
    }

    createSponsorship() {
        // Build json request object
        const data = {
            title: this.formGroup.value.title,
            active: this.formGroup.value.active,
            game_id: null,
            area_id: null,
            sponsor_type: this.typeGroupValue
        };

        // Set relevant file id's (logo, sponsorship_image)
        const keys = Object.keys(this.uploadStatus);
        keys.forEach((key) => {
            data[key] = this.uploadStatus[key].fileId;
        });

        // Check if the rule (area or game) is selected, if so, get the `id` of either object type
        const rule_is_set = this.checkIfRuleIsSet();
        if (this.rulesGroupValue === 'area' && rule_is_set) {
            data['area_id'] = this.areaControl.value.id;
        } else if (this.rulesGroupValue === 'game' && rule_is_set) {
            data['game_id'] = this.gameControl.value.id;
        }
        if (rule_is_set) {
            if (this.editMode) {
                if (this.uploadStatus.hasOwnProperty('id')) {
                    // Edit existing sponsor
                    data['id'] = this.uploadStatus['id'];
                    this.sponsorshipService.editSponsor(data).subscribe((response) => {
                        if (response && response.hasOwnProperty('id')) {
                            this.router.navigate(['/sponsorships']);
                        }
                    });
                }
            } else {
                // Create new sponsor
                this.uploadService.uploadSponsor(data).subscribe((response) => {
                    if (response && response.hasOwnProperty('id')) {
                        this.router.navigate(['/sponsorships']);
                    }
                });
            }
        }
    }

    checkIfRuleIsSet() {
        // Return true if a rule is selected, otherwise returns false
        let result = null;
        switch (this.rulesGroupValue) {
            case 'area':
                if (this.areaControl.value && this.areaControl.value.hasOwnProperty('name')) {
                    result = this.getArea(this.areaControl.value);
                }
                break;
            case 'game':
                result = this.gameControl.value && this.gameControl.value.id;
                break;
            default:
                result = true;
        }
        return result;
    }

    onFileChange(event, field: string) {
        let file = event.target.files[0];

        if (event.target.files.length > 0) {
            file = event.target.files[0];
        }

        if (file) {
            const reader = new FileReader();

            reader.onloadstart = (e) => {
                this.uploadStatus[field].status = 'progress';
                this.uploadStatus[field].message = 0;
            };


            reader.readAsDataURL(file);

            reader.onload = (e) => {
                const path = reader.result;
                console.log('path', path);
                const image = new Image();
                image.src = '' + path + '';
                console.log('image', image);

                image.onload = (load) => {

                    // check image sizes against currently selected size
                    const imageSizes = this.sizeValues.filter(obj => {
                        return obj.label === this.typeGroupValue;
                    });

                    if (imageSizes[0].width !== image.width || imageSizes[0].height !== image.height) {
                        console.log('image size doesn\'t match!');

                        /* this.formGroup.patchValue({
                            sizeValid: [false]
                        }); */

                        // eslint-disable-next-line max-len
                        const sizeErrorMessage = 'Please resize image to ' + imageSizes[0].width + 'px width x ' + imageSizes[0].height + 'px height';
                        const errorDetail = {detail: sizeErrorMessage};

                        const error = {statusText: '', error: errorDetail};
                        this.fileUploadError(error, 'logo');

                    } else {
                        reader.readAsBinaryString(file);
                        this.onUpload(file, field);

                    }
                };


            };


        }
    }

    fileUploadError(error, field) {
        console.log('error, field', error);
        let errorStatusText = '';
        if (error.statusText !== '') {
            errorStatusText = '(' + error.statusText + ') ';
        }
        this.error = errorStatusText + this.translateService.instant(error.error.detail.code);
        this.uploadStatus[field].status = 'error';
        this.uploadStatus[field].message = this.error;
    }

    fileUploadProgress(event, field) {
        const progress = Math.round(100 * event.loaded / event.total);
        this.uploadStatus[field].status = 'progress';
        this.uploadStatus[field].message = progress;
    }

    fileUploadCompleted(event, field) {
        // File upload task is complete but not neccessarily successful
        console.log('upload complete');
    }

    fileUploadSuccess(event, field) {
        const res = event.body;
        console.log('event.body', event.body);
        this.uploadResponse = res;
        this.uploadStatus[field].filePath = res.url;
        this.uploadStatus[field].fileId = res.id;
    }

    onUpload(file, field) {
        const formData = new FormData();
        formData.append('file', file);
        this.uploadService.uploadFile(formData).subscribe(
            (event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.fileUploadProgress(event, field);
                        break;

                    case HttpEventType.Response:
                        this.fileUploadSuccess(event, field);
                        break;

                    default:
                        if (event.hasOwnProperty('type')
                            && event.hasOwnProperty('loaded')
                            && event.hasOwnProperty('total')
                            && event['loaded'] === event['loaded']) {
                            this.fileUploadCompleted(event, field);
                        }
                        break;
                }
            },
            (err) => {
                this.fileUploadError(err, field);
            }
        );
    }
}
