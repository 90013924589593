<div *ngIf="loading" class="d-flex justify-content-center h-100">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!loading">

    <header>
        <div class="container container-flex justify-content-between p-0">
            <div>
                <h1 class="d-inline-block">{{ company?.name }}</h1>
                <h3 class="role-status d-inline-block">({{ status | translate }})</h3>
                <h3>
                    <span class="text-underline">{{ game?.name | gameName }}</span> -
                    <ng-container *ngFor="let user of companyUsers; let last = last">
                        {{ user.full_name !== null ? user.full_name : user.sub }}{{ last ? '' : ',' }}
                    </ng-container>
                    <ng-container *ngIf="game?.scenario_id !== null">
                        - [Scenario Type]
                        <div class="data-header">{{ scenario?.title | translate }}</div>
                        <div class="data-content">{{ 'scenario' | translate }}</div>
                    </ng-container>
                </h3>
            </div>
        </div>
    </header>

</ng-container>

<div class="container">
    <div class="row">
        <button [routerLink]="['/games/detail', gameId]" class="button-primary">{{ 'backToGame' | translate }}</button>
    </div>
</div>

<div class="container quarter-box">
    <app-company-detail-quarter *ngIf="game && company" [companyId]="company?.id" [gameId]="game?.id"
                                [totalQuarters]="game?.duration"></app-company-detail-quarter>
</div>

<ng-container *ngIf="game?.current_quarter > 0 && controls === true">
    <ng-container *ngIf="!chartsLoading; else loader">
        <app-game-detail-graph [activeCompaniesInput]="activeCompanies" [category]="'revenue'" [game]="game"
                               [resultsInput]="quarterResults" [singleView]="false" [topCompany]="false"
                               [userCompanyId]="company?.id"></app-game-detail-graph>
    </ng-container>
    <ng-template #loader>
        <div class="d-flex justify-content-center h-100 my-5">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</ng-container>
