import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';

import {BreadCrumb} from './breadcrumb';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent implements OnInit {

    breadcrumbs$ = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged(),
        map(event => this.buildBreadCrumb(this.activatedRoute.root))
    );

    // Build your breadcrumb starting with the root route of your current activated route
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private readonly translateService: TranslateService) {
    }

    ngOnInit() {
    }

    buildBreadCrumb(route: ActivatedRoute, url: string = '',
                    breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb> {

        // console.log(route);
        // If no routeConfig is avalailable we are on the root path
        const label = route.routeConfig ? this.translateService.instant(route.routeConfig.data['breadcrumb']) : '';
        const path = route.routeConfig ? route.routeConfig.path : '';
        // In the routeConfig the complete path is not available,
        // so we rebuild it each time
        const nextUrl = `${url}${path}/`;
        const breadcrumb = {
            label: label,
            url: nextUrl,
        };
        const newBreadcrumbs = [...breadcrumbs, breadcrumb];
        if (route.firstChild) {
            // If we are not on our current path yet,
            // there will be more children to look after, to build our breadcumb
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }
}
