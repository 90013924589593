<nav aria-label="Breadcrumb" class="breadcrumb">
    <ol>
        <li *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast;"
            [ngClass]="{'active': isLast}"
            aria-current="page" class="breadcrumb-item">
            <a *ngIf="!isLast; else lastRoute"
               [routerLink]="[breadcrumb.url]"
               routerLinkActive="active">
                {{ breadcrumb.label }}
            </a>
            <ng-template #lastRoute>{{ breadcrumb.label }}</ng-template>
        </li>
    </ol>
</nav>