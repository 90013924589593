import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import { Team } from '../../teams/team.interface';
import { TeamsService } from '../../teams/teams.service';

import { GameService, CompanyService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { AuthenticationService } from 'src/app/auth/authentication.service';

import { User } from '../../users/user.interface';


@Component({
  selector: 'app-game-detail-quarter',
  templateUrl: './game-detail-quarter.component.html',
  styleUrls: ['./game-detail-quarter.component.scss']
})
export class GameDetailQuarterComponent implements OnInit, OnChanges {

  @Input() gameId: number;
  @Input() singlePlayer: boolean;
  @Input() quarter: number;
  @Input() activeCompaniesInput: Company[];
  @Input() userCompanyId: number;

  loading = false;

  currentUser: User;

  model: Company;
  teams: [{model}];

  activeCompanies: Company[];
  quarterResults: CompanyQuarterResult[];
  combinedCompanyResults: any[];

  displayedColumns: string[] = ['rank', 'company', 'companyLink', 'revenue', 'expenses', 'profit'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    console.log('numSelected', numSelected);
    console.log('numRows', numRows);
    return numSelected === numRows;
  }

  /** Whether there are any number of selected elements. */
  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAnySelected()  ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** ARIA Accessibility: change label for the checkbox on the passed row */
  checkboxLabel(row?: Team, i?: number): string {
    if (!row) {
      return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }


  constructor(
    private router: Router,
    private gameService: GameService,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
    private teamService: TeamsService
  ) {
    this.dataSource = new MatTableDataSource(this.combinedCompanyResults);
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.currentUser = this.authenticationService.currentUserValue;

    // this.getCompanyQuarterResults();

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', this.gameId);

    if (changes['quarter']) {
      if ( this.quarter !== null ) {
        console.log('this.quarter', this.quarter);
        this.getCompanyQuarterResults();
      }
    }
  }

  getCompanyQuarterResults() {

    this.loading = true;

    console.log('activeCompanies Input', this.activeCompaniesInput);
    if ( this.gameId !== null && this.gameId !== undefined && this.quarter !== null && this.quarter !== undefined ) {
      this.gameService.readGameCompanyQuarterResultsApiGameGameIdQuarterQuarterCompanyResultsGet(this.gameId, this.quarter)
          .subscribe(
            results => {
              console.log('Game' + this.gameId, results);
              this.quarterResults = results;

              // sort by profit and reverse array
              // eslint-disable-next-line max-len
              this.quarterResults.sort((a, b) => (a.net_profit > b.net_profit) ? 1 : -1).reverse();

              // add a rank property to quarterResults
              this.quarterResults.forEach((element, index) => {
                (element as any).rank = index;
                (element as any).linkState = true;
              });

              // TODO change linkState to restrict access to only their company
              if ( this.currentUser.role === 'student' && this.singlePlayer === false) {
                this.quarterResults.forEach((element, index) => {
                  (element as any).linkState = false;

                  if ( element.company_id === this.userCompanyId) {
                    (element as any).linkState = true;
                  }
                });
              }

              // merge array by matching properties
              const merge = (arr1, arr2) => {
                const temp = [];

                arr1.forEach(x => {
                  arr2.forEach(y => {
                    if (x.company_id === y.id) {
                      temp.push({ ...x, ...y });
                    }
                  });
                });

                return temp;
              };

              this.combinedCompanyResults = merge(this.quarterResults, this.activeCompaniesInput);
              this.dataSource.data = this.combinedCompanyResults;

              // console.log('this.dataSource', this.dataSource);
              this.loading = false;

            }
          );
      }
  }

}
