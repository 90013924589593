import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';

import {Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import {Sponsor} from '../sponsor.interface';
import {SponsorshipService} from '../sponsorships.service';
import {AlertService} from '../../alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {SponsorshipDeleteDialogComponent} from '../sponsorship-delete-dialog/sponsorship-delete-dialog.component';

@Component({
    selector: 'app-sponsorships',
    templateUrl: './sponsorships-list.component.html',
    styleUrls: ['./sponsorships-list.component.scss']
})
export class SponsorshipsListComponent implements OnInit, AfterViewInit, AfterContentChecked {

    model: Sponsor;
    sponsors: [{ model }] = null;

    sponsorships: any[];

    @ViewChild('sponsorshipsTable') table: MatTable<any>;

    statusValue: string;
    interstitials: any;
    bottomBars: any;
    sponsorshipLimit = 10;

    visibilityStates = ['active', 'inactive'];

    displayedColumns: string[] = ['select', 'id', 'title', 'active', 'area_name', 'sponsor_type', 'logo_url'];
    dataSource = new MatTableDataSource(this.sponsors);
    selection = new SelectionModel(true, []);

    loading = false;
    formGroup = this._formBuilder.group({
        statusValue: [{value: '', disabled: true}]
    });

    constructor(
        private router: Router,
        private _formBuilder: UntypedFormBuilder,
        private sponsorshipService: SponsorshipService,
        private alertService: AlertService,
        private cdref: ChangeDetectorRef,
        public dialog: MatDialog
    ) {
    }

    @ViewChild(MatSort) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    editSponsor(row) {
        const editLink = '/sponsorships/edit/' + row.id;
        this.router.navigate([editLink]);
    }

    /** Whether there are any number of selected elements. */
    isAnySelected() {
        // console.log('this.selection.selected.length', this.selection.selected.length);

        if (this.selection.selected.length === 0) {
            this.formGroup.controls['statusValue'].disable();
        } else {
            this.formGroup.controls['statusValue'].enable();
        }

        const numSelected = this.selection.selected.length;
        return numSelected > 0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAnySelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** ARIA Accessibility: change label for the checkbox on the passed row */
    checkboxLabel(row?: Sponsor, i?: number): string {
        if (!row) {
            return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.title}`;
    }

    ngOnInit() {
        this.getSponsorships();

        this.statusValue = '';

        this.router.events.subscribe(() => {
            if (this.table) {
                // Re-render table and deselect all rows
                this.table.renderRows();
            }
        });

        //   this.dataSource.sort = this.sort;
    }

    ngAfterViewInit() {
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'area_name':
                    // Custom sort for Game Status
                    console.log(item);
                    // eslint-disable-next-line max-len
                    return (item as any).area_name !== null ? (item as any).area_name : (item as any).game_name !== null ? (item as any).game_name : '';
                default:
                    return item[property];
            }
        };
        // this.dataSource.sort = this.sort;
    }

    getSponsorships() {
        this.loading = true;
        this.dataSource.data = [];

        this.sponsorshipService.getSponsors().subscribe(response => {
            console.log('response', response);
            this.dataSource.data = response;
            this.loading = false;

            this.checkActiveSponsors(response);
        });
    }

    checkActiveSponsors(response?) {
        if (!response) {
            this.loading = true;
            this.dataSource.data = [];

            this.sponsorshipService.getSponsors().subscribe(sponsors => {
                console.log('response', sponsors);
                response = sponsors;
                this.loading = false;

            });
        }

        // const found = response.find( location => location['area_id'] === 111 );
        // console.log('found', found);

        if (response) {
            this.interstitials = response.filter(sponsor => {
                return sponsor.sponsor_type === 'large' && sponsor.active === true;
            });
        }

        console.log('this.interstitials', this.interstitials);


        this.bottomBars = response.filter(sponsor => {
            return sponsor.sponsor_type === 'small' && sponsor.active === true;
        });

        console.log('this.bottomBars', this.bottomBars);

        // initialize messages
        let insterstitialMessage = '';
        const insterstitialAlertIds = [];
        let insterstitialMessage2 = '';
        let insterstitialSponsorTitles = [];
        let bottombarMessage = '';
        const bottombarAlertIds = [];

        const allSponsorships = [this.interstitials, this.bottomBars];
        console.log('allSponsorships', allSponsorships);

        allSponsorships.forEach((sponsor_type, i) => {

            const interstitialTypes = ['area_id', 'game_id'];
            const sortedInterstitials = {};

            // Create new object organized by Area or Game IDs
            interstitialTypes.forEach((type, index) => {
                console.log('index', index);
                const sortedSponsors = {};
                for (let ii = 0, max = sponsor_type.length; ii < max; ii++) {

                    // console.log('sponsor_type[ii][type]', sponsor_type[ii][type]);

                    if (sponsor_type[ii][type] !== null) {
                        if (sortedSponsors[sponsor_type[ii][type]] === undefined) {
                            sortedSponsors[sponsor_type[ii][type]] = [];
                        }
                        sortedSponsors[sponsor_type[ii][type]].push(sponsor_type[ii]);
                    }
                }

                console.log('sortedSponsors ' + type, sortedSponsors);

                sortedInterstitials[type] = sortedSponsors;
            });

            console.log('sortedInterstitials', sortedInterstitials);

            // loop through both types of interstitials
            for (const property in sortedInterstitials) {
                if (sortedInterstitials.hasOwnProperty(property)) {

                    console.log(`${property}`, sortedInterstitials[property]);

                    // loop through each ID and check to see if the length is over the Sponsorship Limit
                    for (const id in sortedInterstitials[property]) {
                        // eslint-disable-next-line max-len
                        // if ((sortedInterstitials[property][id].length + this.selection.selected.length) > this.sponsorshipLimit ) {}

                        // eslint-disable-next-line max-len
                        if (sortedInterstitials[property].hasOwnProperty(id) && id !== 'null' && sortedInterstitials[property][id].length > this.sponsorshipLimit) {

                            console.log(`ID ${id}: ${id} `);
                            console.log('property', property);

                            // console.log(`sortedInterstitials[${property}][${id}].length + this.selection.selected.length`,
                            // sortedInterstitials[property][id].length + this.selection.selected.length);
                            console.log('response', response);


                            // replace underscores in property name and capitalize
                            let propTitle = property.replace('_id', '');

                            // Find name of Display Location
                            const findId = +id;
                            const found = response.find(location => location[property] === findId);
                            const propName = found[propTitle + '_name'];
                            // console.log('found', found[propName]);

                            // eslint-disable-next-line max-len
                            propTitle = propTitle.replace(/\w\S*/g, function (txt) {
                                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                            });


                            // 0 is interstitials ("Between Quarters"), 1 is bottom bar
                            if (i === 0) {

                                // eslint-disable-next-line max-len
                                insterstitialMessage2 = 'For the following Display Locations: \n';
                                insterstitialAlertIds.push(` ${propTitle} ${propName}`);

                                console.log('insterstitialAlertIds', insterstitialAlertIds);

                                // map the array to IDs only
                                const idsOnly = sortedInterstitials[property][id].map(a => a.id);
                                console.log(`sortedInterstitials[${property}][${id}]`, sortedInterstitials[property][id]);
                                console.log('idsOnly', idsOnly);

                                // slice the overflow ids off the array
                                const overflow = idsOnly.slice(this.sponsorshipLimit);
                                console.log('overflow', overflow);

                                /* const found = Math.max(...idsOnly);
                                console.log(found);

                                const data = {id: found, active: false};

                                console.log('data', data); */

                                insterstitialMessage = `It is required to have no more than ${this.sponsorshipLimit}
                                        "Between Quarters" Sponsorships per Game or Area active at one time.
                                        The following Sponsorship(s) have been set to "inactive": `;

                                const sponsorTitles = sortedInterstitials[property][id].filter(el => {
                                    return overflow.find(element => {
                                        return element === el.id;
                                    });
                                });

                                // console.log('sponsorTitles', sponsorTitles);
                                insterstitialSponsorTitles = sponsorTitles.map(a => a.title);

                                let itemsProcessedId = 0;
                                console.log('itemsProcessedId', itemsProcessedId);

                                // match rows with overflow ids, select them, and set to inactive
                                this.dataSource.data.forEach(row => {

                                    if (overflow.includes((row as any).id)) {
                                        console.log('row', row);

                                        this.selection.select(row);
                                        console.log('this.selection', this.selection);
                                        // this.updateStatus('inactive', false);

                                        (row as any).active = false;
                                        this.sponsorshipService.editSponsor(row).subscribe(
                                            (editResponse) => {
                                                itemsProcessedId++;
                                                // only invoke callback after all items have been set
                                                if (itemsProcessedId === overflow.length) {
                                                    this.userAlert(insterstitialMessage, bottombarMessage,
                                                        insterstitialSponsorTitles, bottombarAlertIds);
                                                }
                                                console.log('editResponse', editResponse);
                                            }, () => {
                                                itemsProcessedId++;
                                                // only invoke callback after all items have been set
                                                if (itemsProcessedId === overflow.length) {
                                                    this.userAlert(insterstitialMessage, bottombarMessage,
                                                        insterstitialSponsorTitles, bottombarAlertIds);
                                                }
                                            }
                                        );
                                    } else {
                                        itemsProcessedId++;
                                        // only invoke callback after all items have been set
                                        if (itemsProcessedId === overflow.length) {
                                            this.userAlert(insterstitialMessage, bottombarMessage,
                                                insterstitialSponsorTitles, bottombarAlertIds);
                                        }
                                    }
                                });
                                this.selection.clear();

                            } else {
                                // eslint-disable-next-line max-len
                                bottombarMessage = `It is recommended to have no more than ${this.sponsorshipLimit}
                                    "Bottom Bar" Sponsorships per Game active at one time.
                                    The following Display Locations have more than ${this.sponsorshipLimit}: \n`;

                                bottombarAlertIds.push(`${propTitle} ${propName}`);

                                this.userAlert(insterstitialMessage, bottombarMessage,
                                    insterstitialSponsorTitles, bottombarAlertIds);

                            }
                        } /* else {
                            // reset messages
                            insterstitialMessage = '';
                            insterstitialAlertIds = [];
                            insterstitialMessage2 = '';
                            insterstitialSponsorTitles = [];
                            bottombarMessage = '';
                            bottombarAlertIds = [];
                            this.alertService.clear();

                        } */
                    }
                }
            }
        });
    }

    userAlert(insterstitialMessage, bottombarMessage, insterstitialSponsorTitles, bottombarAlertIds) {
        console.log('user alert');
        let alertMessage = '';

        // alert user
        if (insterstitialMessage !== '' || bottombarMessage !== '') {
            let linebreak = '';

            if (insterstitialMessage !== '' && bottombarMessage !== '') {
                linebreak = '\n';
            }

            alertMessage = insterstitialMessage + ' ' + insterstitialSponsorTitles.join() + ' '
                + linebreak + bottombarMessage + bottombarAlertIds.join();

            this.alertService.error(alertMessage);

            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });

        }
    }

    bulkAction(value) {
        console.log('this.selection.selected.length', this.selection.selected.length);
        console.log('value', value);

        if (value === 'delete') {
            this.delete();
        } else if (value) {
            this.updateStatus(value);
        }
    }

    delete() {
        const sponsors = this.selection.selected;
        const dialogRef = this.dialog.open(SponsorshipDeleteDialogComponent, {
            width: '460px',
            height: '550px',
            data: {
                sponsors: sponsors
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.formGroup.patchValue({
                statusValue: ''
            });
            if (result) {
                this.selection.selected.forEach((sponsor) => {
                    this.sponsorshipService.deleteSponsor(sponsor).subscribe(() => {
                        this.dataSource.data.splice(this.dataSource.data.indexOf(sponsor), 1);
                        if (this.table) {
                            this.table.renderRows();
                            this.dataSource._updateChangeSubscription();
                        }
                    });
                });
            }
        });
    }

    updateStatus(value) {
        let itemsProcessed = 0;
        const active = value === 'active';
        this.selection.selected.forEach((row) => {
            // console.log('row id', row.id);
            row.active = active;
            this.sponsorshipService.editSponsor(row).subscribe(
                () => {
                    itemsProcessed++;
                    // only invoke callback after all items have been processed
                    if (itemsProcessed === this.selection.selected.length) {
                        this.updateForm(true, active);
                    }
                },
                error => {
                    itemsProcessed++;
                    // only invoke callback after all items have been processed
                    if (itemsProcessed === this.selection.selected.length) {
                        this.updateForm(true, active);
                    }
                    console.log(error);
                }
            );
        });
    }

    updateForm(checkStatus, active) {
        this.selection.clear();

        this.formGroup.patchValue({
            statusValue: ''
        });

        // if checkStatus is true, check status after finished
        if (checkStatus) {
            // console.log('checkStatus', checkStatus);
            this.getSponsorships();
            // window.location.reload();
        }
        if (!active) {
            this.alertService.clear();
        }
    }

    ngAfterContentChecked() {
        this.cdref.detectChanges();
    }


}
