import {
  Component,
  AfterContentChecked,
  ChangeDetectorRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {
  UntypedFormBuilder
} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {
  UserService,
  ScenarioService,
  BracketService
} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {TranslateService} from '@ngx-translate/core';
import {BaseGameCreateComponent} from '../../common/base.game-create.component';


@Component({
  selector: 'app-game-create',
  templateUrl: './create.component.html',
  styleUrls: [
    './create.component.scss'
  ]
})
export class BracketCreateComponent extends BaseGameCreateComponent implements AfterContentChecked {

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    protected scenarioService: ScenarioService,
    private bracketService: BracketService,
    private router: Router,
    protected route: ActivatedRoute,
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected cdref: ChangeDetectorRef,
    protected readonly translateService: TranslateService
  ) {
    super(_formBuilder, scenarioService, userService, route, dialog, translateService, cdref, datePipe);
  }

  loadEntityFromId(bracketId: number) {
    // no implementation
  }

  createEntity(bracketCreate?: any) {
    const groups = [];

    this.teamArray.forEach(team => {
      const teamObject = {users: []};
      teamObject.users = team;
      groups.push(teamObject);
    });

    bracketCreate.groups = groups;

    this.bracketService.createBracketApiBracketPost(false, bracketCreate)
      .subscribe(
        async () => {
          await this.router.navigate(['/brackets/']);
        }
      );
  }
}
