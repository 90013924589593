import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ScenarioService {


    constructor(
        private http: HttpClient,
        private globals: Globals
    ) {
    }

    getScenarioEvents(scenarioId?: Number) {
        let endpoint;
        if (scenarioId) {
            endpoint = this.globals.backendUrl + '/api/scenario_event/all/' + scenarioId;
        } else {
            endpoint = this.globals.backendUrl + '/api/scenario_event/all';
        }
        return this.http.get<any>(endpoint, {})
            .pipe(map((events) => {
                return events;
            }));
    }

    getScenarios() {
        const site = `${this.globals.backendUrl}/api/scenario/`;
        return this.http.get<any>(site, {})
            .pipe(map(response => {
                return response;
            }));
    }

    getScenario(id) {
        return this.http.get<any>(this.globals.backendUrl + '/api/scenario/' + id, {})
            .pipe(map((scenario) => {
                return scenario;
            }));
    }

    createScenario(data) {
        return this.http.post<any>(this.globals.backendUrl + '/api/scenario/', data)
            .pipe(map((scenario) => {
                return scenario;
            }));
    }

    editScenario(id, data) {
        return this.http.put<any>(this.globals.backendUrl + '/api/scenario/' + id, data)
            .pipe(map((scenario) => {
                return scenario;
            }));
    }

    deleteScenario(scenario) {
        return this.http.delete<any>(this.globals.backendUrl + '/api/scenario/' + scenario.id, {})
            .pipe(map((deletedScenario) => {
                return deletedScenario;
            }));
    }

    // ScenarioEvent
    editScenarioEvent(data) {
        const scenario_event_id = data.id;
        return this.http.put<any>(this.globals.backendUrl + '/api/scenario_event/' + scenario_event_id, data)
            .pipe(map((scenario_event) => {
                return scenario_event;
            }));
    }

    createScenarioEvent(data) {
        return this.http.post<any>(this.globals.backendUrl + '/api/scenario_event/', data)
            .pipe(map((scenario_event) => {
                return scenario_event;
            }));
    }

    deleteScenarioEvent(scenario_event_id) {
        return this.http.delete<any>(this.globals.backendUrl + '/api/scenario_event/' + scenario_event_id)
            .pipe(map((scenario_event) => {
                return scenario_event;
            }));
    }

}
