import { Component, OnInit, Input } from '@angular/core';

import { GameService, ScenarioService, CompanyUserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Game, ScenarioGameOut, Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';

import * as d3 from 'd3';
import {TranslateService} from '@ngx-translate/core';

export interface QuarterData {
  'company_id': number;
  'revenue': number;
  'quarter':    number;
}

@Component({
  selector: 'app-game-detail-graph',
  templateUrl: './game-detail-graph.component.html',
  styleUrls: ['./game-detail-graph.component.scss']
})
export class GameDetailGraphComponent implements OnInit {

  @Input() game: Game;
  @Input() activeCompaniesInput: Company[];
  @Input() companyId?: number;
  @Input() category?: string;
  @Input() resultsInput: CompanyQuarterResult[];
  @Input() singleView: boolean;
  @Input() userCompanyId: number;
  @Input() topCompany: boolean;
  @Input() scenarioEvents?: any[];


  loading = false;

  quarterResults: CompanyQuarterResult[];
  topCompanies: Company[];
  scenario: any; // temp ScenarioGameOut;
  scenarioQuarter: number;
  combinedCompanyResults: any[]; // used to determine min/maxes
  display_companies: any[]; // used for actual line graph displays
  display_companies_total: any[]; // used for actual bar graph displays

  marker = {
    'type': '!',
  };


  constructor(
    private gameService: GameService,
    private scenarioService: ScenarioService,
    private companyUserService: CompanyUserService,
    private readonly translateService: TranslateService
    ) {}

  ngOnInit() {

    if (!this.category) { this.category = 'revenue'; }

    console.log('activeCompaniesInput', this.activeCompaniesInput);

    this.getCompanyQuarterResults();
  }


  getCompanyQuarterResults() {

    this.loading = true;

    console.log('activeCompanies', this.activeCompaniesInput);

    if ( this.game.id !== null && this.game.id !== undefined ) {
          this.quarterResults = this.resultsInput;

          // Sort by Quarter for line graphs
          this.quarterResults.sort((a, b) => {
            if (a.quarter < b.quarter) { return -1;
            } else if (a.quarter > b.quarter) { return 1;
            } else { return 0; }
          });

          // this.quarterResults.sort((a, b) => {
          //   if (a.company_id < b.company_id) { return -1;
          //   } else if (a.company_id > b.company_id) { return 1;
          //   } else { return 0; }
          // });

          console.log('quarter results after sorting', this.quarterResults);
          // TODO identify user's company and include

          // merge array by matching properties
          const merge = (arr1, arr2) => {
            const temp = [];

            arr1.forEach(x => {
              arr2.forEach(y => {
                if (x.company_id === y.id) {
                  temp.push({ ...x, ...y });
                }
              });
            });

            return temp;
          };

          this.combinedCompanyResults = merge(this.quarterResults, this.activeCompaniesInput);
          console.log('combinedCompanyResults', this.combinedCompanyResults);

          // if is Scenario game, get Scenario
          if ( this.game.scenario_id !== undefined && this.game.scenario_id !== null ) {
            this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(this.game.scenario_id)
              .subscribe(
                scenario => {
                  this.scenario = scenario;
                  console.log('scenario', scenario);
                }
              );
          }

          // reduce list to 4 companies
          this.getTopCompanies(this.combinedCompanyResults, this.game);

          this.loading = false;

    }
  }

  getTopCompanies(combinedCompanyResults, game) {

    const category = this.category;

    let topCompanyId = 0;
    let userCompanyId = 0;

    let display_companies = [];
    let display_companies_total = [];

    /*
    Top Company
    */

      if (this.singleView === false && this.topCompany === true  ) {

        // find top value of most recently completed quarter
        const res = Math.max.apply(Math, this.combinedCompanyResults.map(function(o) {
          const last_completed_quarter = game.is_complete === true ? game.duration - 1 : game.current_quarter - 1;
          return last_completed_quarter === o.quarter ? o[category] : '';
        }));
        // console.log('res', res);

        // get company object of company with top value
        const top_object = this.combinedCompanyResults.find(function(o) { return o[category] === res; });

        console.log('top_object', top_object);

        if ( top_object !== null && top_object !== undefined) {
          topCompanyId = top_object.company_id;

          console.log(`topCompanyId ${topCompanyId}, this.userCompanyId ${this.userCompanyId}`);

          let company_type = this.translateService.instant('topCompany');

          if ( this.userCompanyId !== null && this.userCompanyId !== undefined && topCompanyId === this.userCompanyId) {
            company_type = this.translateService.instant('topCompanyMyCompany');
          }

          // Assemble new top_company object containing all quarters
          const top_company = [];

          this.combinedCompanyResults.forEach((element, index) => {
            if ( element.company_id === topCompanyId ) {
              element.company_type = company_type;
              top_company.push(element);
            }
          });

          console.log('top_company all quarters', top_company);

          // Remove Top Company from combinedCompanyResults
          // eslint-disable-next-line max-len
          this.combinedCompanyResults = this.combinedCompanyResults.filter(function(value, index, arr) { return value.company_id !== topCompanyId; });

          display_companies = top_company;
          display_companies_total = top_company;
        }
      }
      console.log('new this.combinedCompanyResults', this.combinedCompanyResults);

    /*
    Current User's Company
    */

      console.log('this.userCompanyId', this.userCompanyId);

      // If current user has a company, find user's company
      if ( this.userCompanyId !== null && this.userCompanyId !== undefined ) {
        console.log('this.userCompanyId after', this.userCompanyId);

        userCompanyId = this.userCompanyId;
        // Assemble new currentUser_company object containing all quarters
        const currentUser_company = [];

        this.combinedCompanyResults.forEach((element, index) => {

          console.log('element this.userCompanyId', this.userCompanyId);

          if ( element.company_id === userCompanyId ) {
            element.company_type = this.translateService.instant('myCompany');
            currentUser_company.push(element);
          }
        });
        console.log('currentUser_company', currentUser_company);

        // Remove User's Company from combinedCompanyResults
        // eslint-disable-next-line max-len
        this.combinedCompanyResults = this.combinedCompanyResults.filter(function(value, index, arr) { return value.company_id !== userCompanyId; });

        display_companies = display_companies.concat(currentUser_company);
        display_companies_total = display_companies_total.concat(currentUser_company);

      }

      console.log('new this.combinedCompanyResults', this.combinedCompanyResults);


    /*
    Average Company - Get Average of the rest, create new company object
    */

      // nest `combinedCompanyResults` by quarters, so we can grab the mean for each Q
      const quarterArray = d3.nest()
        .key(function(d) {return (d as any).quarter; })
        .entries(this.combinedCompanyResults);

      console.log('quarterArray', quarterArray);

      const averageCompany = [];

      // return mean values for each quarter
      quarterArray.forEach((element, index) => {

        // mean values for revenue
        const mean_profit = d3.mean(quarterArray[element.key].values, function(d) {
          return (d as any).revenue;
        });

        // mean values for csr_score
        const mean_csr = d3.mean(quarterArray[element.key].values, function(d) {
          return (d as any).csr_score;
        });

        // mean values for total_completed_projects
        const mean_projects = d3.mean(quarterArray[element.key].values, function(d) {
          return (d as any).total_completed_projects;
        });

        // mean values for units_produced
        const mean_produced = d3.mean(quarterArray[element.key].values, function(d) {
          return (d as any).units_produced;
        });

        // mean values for units_sold array
        const units_sold_mean = [];

        for (let index2 = 0; index2 < 3; index2++) {
          const units_sold = d3.mean(quarterArray[element.key].values, function(d) {
            return (d as any).units_sold_by_demographic[index2];
          });
          units_sold_mean.push(units_sold);
        }

        // add company quarter object to averageCompany array
        averageCompany.push(
          {
            company_id: 0,
            quarter: index,
            trade_dress: {
              'logos': [
                {
                  'color_id': '4A4A4A'
                }
              ]
            },
            [this.category]: Math.trunc(mean_profit),
            units_sold_by_demographic: [...units_sold_mean],
            csr_score: Math.trunc(mean_csr),
            total_completed_projects: Math.trunc(mean_projects),
            units_produced: Math.trunc(mean_produced),
            name: 'Average',
            company_type: 'average'
          }
        );
      });

      console.log('averageCompany', averageCompany);

      // If this is not a Company Detail view combine Top Company and newly created Average Company
      display_companies = display_companies.concat(averageCompany);

      console.log('display_companies', this.display_companies);


      // do we still need this?
      // this.display_companies[0].quarter = 0;

      // graph results
      this.display_companies = display_companies;
      console.log('display_companies', this.display_companies);

      // this.category, this.game, this.combinedCompanyResults, this.scenarioQuarter);

    /*
    All Companies
    */

      const totalCompany = [];

      // return sum values for each quarter
      quarterArray.forEach((element, index) => {

        // sum values for units_sold array
        const units_sold_sum = [];

        for (let index2 = 0; index2 < 3; index2++) {
          const units_sold = d3.sum(quarterArray[element.key].values, function(d) {
            return (d as any).units_sold_by_demographic[index2];
          });
          units_sold_sum.push(units_sold);
        }

        const sold = units_sold_sum.reduce(function(a, b) {
          return a + b;
        }, 0);

        console.log('units_sold_sum', units_sold_sum);

        // add company quarter object to totalCompany array
        totalCompany.push(
          {
            company_id: 0,
            quarter: index,
            trade_dress: {
              'logos': [
                {
                  'color_id': '4A4A4A'
                }
              ]
            },
            units_sold_by_demographic: [...units_sold_sum],
            sold: sold,
            name: 'Total',
            company_type: 'Total'
          }
        );
      });

      display_companies_total = display_companies_total.concat(totalCompany);
      console.log('display_companies_total', display_companies_total);

      this.display_companies_total = display_companies_total;

  }

}

