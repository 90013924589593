import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {User} from '../../users/user.interface';
import {CompetitionBaseInDBWithGames, CompetitionService, GameWithCompanies, ScenarioService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {GameStatus} from '../../games/game-status.enum';
import {AuthenticationService} from '../../auth/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {firstValueFrom} from 'rxjs';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-competition-detail',
  templateUrl: './competition-detail.component.html',
  styleUrls: ['./competition-detail.component.scss']
})
export class CompetitionDetailComponent implements OnInit {

  @ViewChild('competitionTable') table: MatTable<any>;

  loading = false;
  currentUser: User;
  competitionId: number;
  competition: CompetitionBaseInDBWithGames;
  games: [GameWithCompanies];
  states = GameStatus;
  status: string;

  displayedColumns: string[] = [
    'name', 'round_number', 'status', 'game_start', 'turn_advancement',
    'quarter_duration', 'scenario', 'quarters', 'starting_cash', 'companies'
  ];
  dataSource: MatTableDataSource<any>;

  constructor(
    private competitionService: CompetitionService,
    private scenarioService: ScenarioService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    readonly translateService: TranslateService
  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.params.subscribe(() => {
      this.competitionId = +this.route.snapshot.paramMap.get('id');
      this.getCompetition(this.competitionId);
    });
  }

  async getCompetition(id: number) {
    this.loading = true;

    try {
      const competition = await firstValueFrom(this.competitionService.getCompetitionApiCompetitionCompetitionIdGet(id));
      this.status = competition.is_complete ?
        this.states.complete : competition.is_in_progress ?
          this.states.inProgress : this.states.pending;
      this.competition = competition;
      let scenarioIdNameMap = new Map<number, string>();

      if (competition.games) {
        for(let i=0; i < competition.games.length; i++) {
          const game = competition.games[i];
          if ( game.scenario_id !== undefined && game.scenario_id !== null ) {
            if (!scenarioIdNameMap.has(game.scenario_id)) {
              const scenario = await firstValueFrom(this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(game.scenario_id));
              scenarioIdNameMap.set(scenario.id, scenario.title);
            }
            (game as any).scenario_name = scenarioIdNameMap.get(game.scenario_id);
          }
        }
      }
      this.dataSource.data = competition.games;
      this.loading = false;
    } catch (e) {
      console.error(e);
    }
  }

  deleteCompetition() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: {
        message: this.translateService.instant('deleteCompetitionAlert', {'competition_id': this.competition.id}),
        reject: this.translateService.instant('cancel')
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.competitionService.deleteCompetitionApiCompetitionCompetitionIdDelete(this.competition.id)
          .subscribe({
            next: () => {
              this.router.navigate(['/competitions']);
            },
            error: error => {
              console.error(error);
            }
          });
      }
    });
  }
}
