import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ScenarioService, ScenarioUpdate, User, UserService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {AbstractControl, UntypedFormBuilder, Validators} from '@angular/forms';
import {CustomValidators} from '../shared/validator/customvalidators';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import Utils from '../utils';
import {ActivatedRoute} from '@angular/router';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {MatStepper} from '@angular/material/stepper';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';
import {ResizeEvent} from 'angular-resizable-element';
import {DatePipe} from '@angular/common';
import GameOptions from './game-options';

@Component({
  selector: 'game-create-base',
  template: `<p>dummy</p>`,
  styles: []
})
export class BaseGameCreateComponent implements OnInit, AfterViewInit, AfterContentChecked {

  mode: string = null;
  nameMaxLength = 26;
  scenarioOptions = [];
  classes: any[];
  today = new Date();
  hours: string = '';
  minutes: string = '';
  startTime = this.hours + ':' + this.minutes;
  startDate = this.today.toISOString();
  courseId: number;
  companyBoxHeight: string;
  userBoxHeight: string;
  minDate: Date;
  createDisabled = false;
  updateDisabled = false;
  createLoading = false;

  gameType: string;
  selectedScenario: ScenarioUpdate;
  scenarioDifficulty: { starting_cash: number, difficulty: string };

  /* User Options */
  users: [User] = null;
  userDisplayedColumns: string[] = ['select', 'sub'];
  userDataSource = new MatTableDataSource(this.users);
  userSelectionMap = new Map<number, SelectionModel<User>>();

  /* Team Options  */
  teams: [{ teamModel }];
  teamDisplayedColumns: string[] = ['select', 'sub'];
  teamDataSource = new MatTableDataSource();
  teamSelection = new SelectionModel(true, []);
  // For Skipping Team Step Button
  teamBack = 2;
  // Start First Team at 1
  teamArray = [];

  @ViewChild('stepper', {static: true}) myStepper: MatStepper;
  @ViewChild('teamGroup') teamGroup: MatButtonToggleGroup;
  @ViewChild('teamTable') table: MatTable<any>;

  /* Simulation Options */
  readonly quarterDurationOptions = GameOptions.quarterDurationOptions;
  readonly completionOptions = GameOptions.completionOptions;
  readonly cashOptions = GameOptions.cashOptions;
  readonly durationOptions = GameOptions.durationOptions;
  readonly gameTypeOptions = GameOptions.gameTypeOptions;
  readonly scenarioDifficulties = GameOptions.scenarioDifficulties;
  readonly roundDurationOptions = GameOptions.roundDurationOptions;

  /* Table-Stepper Structure */
  formGroup = this._formBuilder.group({
    formArray: this._formBuilder.array([
      this._formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength), CustomValidators.noWhitespaceValidator]],
        turn_advancement: this.completionOptions[0].value,
        quarter_duration: 0,
        starting_cash: this.cashOptions[0].value,
        duration: this.durationOptions[0].value.toString(),
        scenario_id: null,
        automatic_start: false,
        start_date: [this.startDate, Validators.required],
        start_time: [this.startTime, Validators.required],
        round_duration: [0, this.mode === 'create-bracket' || this.mode === 'create-competition' ? Validators.required : null],
        round_count: [2, this.mode === 'create-competition' ? Validators.required : null],
        max_game_company_count: [6, this.mode === 'create-competition' ? Validators.required : null],
        course_id: 0
      }),
      this._formBuilder.group({
      }),
      this._formBuilder.group({
        teamList: ['', Validators.required],
      })
    ])
  });

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected scenarioService: ScenarioService,
    protected userService: UserService,
    protected route: ActivatedRoute,
    protected dialog: MatDialog,
    protected readonly translateService: TranslateService,
    protected cdref: ChangeDetectorRef,
    protected datePipe: DatePipe
  ) {
    this.resetTime();
    this.minDate = new Date();
  }

  /* Based on https://stackblitz.com/edit/example-angular-material-stepper-single-form-q6waon */
  /** Returns a FormArray with the name 'formArray'. */
  get formArray(): AbstractControl | null {
    return this.formGroup.get('formArray');
  }

  loadMode() {
    this.route.url
      .subscribe(
        paths => {
          if (this.route.routeConfig) {
            this.mode = this.route.routeConfig.data['mode'];
          } else {
            this.mode = paths[0].path;
          }
        }
      );
  }

  getAllScenarios() {
    this.scenarioService.readAllScenariosApiScenarioGet()
      .subscribe(
        scenarios => {
          this.scenarioOptions = scenarios;
        }
      );
  }

  getAllUsersClasses() {
    this.userService.listClassesAndUsersApiUserClassesGet()
      .subscribe({
        next: usersClasses => {
          this.classes = usersClasses;
        },
        error: () => {
          console.error('no users found');
        }
      });
  }

  resetLocalStorageGameParameters() {
    for (let key in localStorage) {
      if (key.substring(0, 17) == 'create_game_step_') {
        localStorage.removeItem(key);
      }
    }
  }

  protected resetTime() {
    // Round minutes to nearest 5 minute increments
    let roundedMinutes = Math.ceil(this.today.getMinutes() / 5) * 5;
    let hoursIncrement = 0;
    if (roundedMinutes === 60) {
      hoursIncrement = 1;
      roundedMinutes = 0;
    }
    this.hours = Utils.formatNumber2Digits(this.today.getHours() + hoursIncrement)
    this.minutes = Utils.formatNumber2Digits(roundedMinutes);
  }

  getDatetime(dateStr, hourStr) {
    const date = new Date(dateStr + 'T' + hourStr + 'Z');
    const timeOffset = new Date().getTimezoneOffset();
    return new Date(date.getTime() + (timeOffset * 60 * 1000));
  }

  /* Game Settings */

  updateScenario(target) {
    const scenario = target.options[target.selectedIndex].dataset.scenario;
    const selected = this.selectedScenario = JSON.parse(scenario);
    this.scenarioDifficulty = this.scenarioDifficulties.find(function (scenarioDiff) {
      return scenarioDiff.starting_cash === selected.starting_cash;
    });
  }

  gameTypeChanged(event) {
    this.gameType = event.value;
  }


  /* User Table Methods */

  updateClass(classId) {
    // find the index of our class w/in the classes array
    const index = this.classes.findIndex(item => item.id === +classId);

    // update user list with selected class
    if (this.classes[index] !== undefined) {
      this.userDataSource.data = this.classes[index].users;
      this.courseId = this.classes[index].id;
      if (!this.userSelectionMap.has(this.courseId)) {
        this.userSelectionMap.set(this.courseId, new SelectionModel(true, []));
      }
    } else {
      this.userDataSource.data = [];
      this.courseId = 0;
    }
  }

  getUserSelection() {
    if (this.courseId && this.courseId > 0) {
      return this.userSelectionMap.get(this.courseId);
    }
    return new SelectionModel(true, []);
  }

  getAllSelectedUsers() {
    let result = [];
    this.userSelectionMap.forEach((value: SelectionModel<User>, key: number) => {
      value.selected.forEach(user => {
        result.push(user);
      })
    });
    return result;
  }

  getAllSelectedClassroomIds() {
    let result = [];
    this.userSelectionMap.forEach((value: SelectionModel<User>, key: number) => {
      if (value.selected.length > 0) {
        result.push(key);
      }
    });
    return result;
  }

  getClassroomNameFromId(classId) {
    const classroom = this.classes.find(item => item.id === +classId);
    return classroom.name;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllUserSelected() {
    const numSelected = this.getUserSelection().selected.length;
    const numRows = this.userDataSource.data.length;
    return numSelected === numRows;
  }

  /** Whether there are any number of selected elements. */
  isAnyUserSelected() {
    const numSelected = this.getUserSelection().selected.length;
    return numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterUserToggle() {
    this.isAnyUserSelected() ?
      this.getUserSelection().clear() :
      this.userDataSource.data.forEach(row => this.getUserSelection().select(row));
  }

  /** ARIA Accessibility: change label for the checkbox on the passed row */
  checkboxUserLabel(row?: User): string {
    if (!row) {
      return `${this.isAnyUserSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.getUserSelection().isSelected(row) ? 'deselect' : 'select'} row ${row.sub}`;
  }

  // Step 2, toggle whether user skips Step 3 (and sets back button index as well)
  onUsersAsCompaniesChange(event: any) {
    this.teamArray = [];

    if (event.value === 'individuals') {
      this.teamBack = 1;
    } else {
      this.teamBack = 2;
      this.teamArray = [];
    }
  }

  /* Team Table Methods */

  /** Whether there are any number of selected elements. */
  isAnyTeamSelected() {
    const numSelected = this.teamSelection.selected.length;
    return numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterTeamToggle() {
    this.isAnyTeamSelected() ?
      this.teamSelection.clear() :
      this.userDataSource.data.forEach(row => this.teamSelection.select(row));
  }

  /** ARIA Accessibility: change label for the checkbox on the passed row */
  checkboxTeamLabel(row?: User): string {
    if (!row) {
      return `${this.isAnyTeamSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.teamSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.sub}`;
  }

  addToTeam() {
    // Push selection to array of Teams
    this.teamArray.push(this.teamSelection.selected);
    // Save new array to our form input
    this.formArray['controls'][2].get('teamList').patchValue(this.teamArray);
    // Filter out selected users from Data Source for next iteration
    const newTeamArr = this.teamDataSource.data.filter(val => !this.teamSelection.selected.includes(val));
    this.teamDataSource.data = [...newTeamArr];
    // Re-render table and deselect all rows
    this.table.renderRows();
    this.masterTeamToggle();
  }

  removeFromTeam() {
    // remove most recent created team
    const previousTeam = this.teamArray.pop();
    // Add previous team back to user table
    previousTeam.forEach(element => {
      this.teamDataSource.data.push(element);
    });
    // Re-render table and deselect all rows
    this.table.renderRows();
    this.teamSelection.clear();
  }

  startTimeWarning(gameData: any, timeString: Date) {
    // round time string
    const coeff = 1000 * 60 * 5;
    timeString = new Date(Math.round(timeString.getTime() / coeff) * coeff);

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: {
        message: this.translateService.instant('StartTimeAlreadyPast', {'start_time': timeString}),
        confirm: 'OK'
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        gameData.start_time = timeString;
        this.createEntity(gameData);
      }
    });
  }

  formStepper(index: number) {
    const firstStepControls = this.formArray['controls'][0];

    if (index === 1) {
      let stepValid = true;

      // Validation for All and Automatic Games
      if (
        firstStepControls.get('quarter_duration').value === 0 &&
        firstStepControls.get('turn_advancement').value === 'Timed'
      ) {
        firstStepControls.get('quarter_duration').markAsDirty();

        window.scrollTo({
          top: 600,
          behavior: 'smooth'
        });
        stepValid = false;
      }

      if (
        firstStepControls.get('round_duration').value === 0 &&
        firstStepControls.get('automatic_start').value &&
        (this.mode === 'create-bracket' || this.mode === 'create-competition')
      ) {
        firstStepControls.get('round_duration').markAsDirty();

        window.scrollTo({
          top: 300,
          behavior: 'smooth'
        });
        stepValid = false;
      }

      if (firstStepControls.get('name').invalid) {
        firstStepControls.get('name').markAsDirty();
        window.scrollTo({
          top: 160,
          behavior: 'smooth'
        });
        stepValid = false;
      }

      // Validation toggle for Automatic Start Games
      if (firstStepControls.get('automatic_start').value) {
        firstStepControls.get('start_date').setValidators([Validators.required]);
        firstStepControls.get('start_time').setValidators([Validators.required]);
      } else {
        firstStepControls.get('start_date').setValidators(null);
        firstStepControls.get('start_time').setValidators(null);
      }

      // Validation toggle for Manual Turn Games
      if (firstStepControls.get('turn_advancement').value === 'Manual') {
        firstStepControls.get('quarter_duration').setValidators(null);
      } else {
        firstStepControls.get('quarter_duration').setValidators([Validators.required]);
      }

      // Validation toggle for Scenario & Custom Games
      if (firstStepControls.get('scenario_id').value === null && this.gameType === 'scenario') {
        firstStepControls.get('scenario_id').setValidators([Validators.required]);
        firstStepControls.get('scenario_id').markAsDirty();

        stepValid = false;
      } else {
        firstStepControls.get('scenario_id').setValidators(null);
      }
      // Validation for 'Scenario' games
      if (stepValid === true) {
        this.myStepper.selectedIndex = index;
      }

    } else if (index === 2) {
      this.teamArray = [];

      this.teamDataSource.data = this.getAllSelectedUsers();
      this.myStepper.selectedIndex = index;

    } else if (index === 3) {
      this.teamArray = [];

      if (this.teamGroup.value === 'individuals') {
        for (let i = 0; i < this.teamDataSource.data.length; i++) {
          this.teamArray.push([this.teamDataSource.data[i]]);
        }
        this.formArray['controls'][2].get('teamList').patchValue(this.teamArray);
      }
      this.myStepper.selectedIndex = index;
    }
  }

  // Store previous index's values
  stepChange(event: any) {
    const firstStepControls = this.formArray['controls'][0];

    /* from Step 1 to Step 2 */
    if (event.selectedIndex === 1 && event.previouslySelectedIndex === 0) {
      this.startTime = firstStepControls.get('start_time').value;
      this.startDate = firstStepControls.get('start_date').value;

      // Clean up unused data for Manual Start Games
      if (!firstStepControls.get('automatic_start').value) {
        firstStepControls.patchValue({
          'start_date': null,
          'start_time': null
        });
        firstStepControls.get('start_date').patchValue(null);
        firstStepControls.get('start_time').patchValue(null);
      }

      // Clean up unused data for Manual Turn Games
      if (firstStepControls.get('turn_advancement').value === 'Manual') {
        firstStepControls.patchValue({
          'quarter_duration': null
        });
        firstStepControls.get('quarter_duration').patchValue(null);
      }

      // Clean up unused data for Scenario Games
      if (this.gameType === 'scenario') {
        firstStepControls.get('starting_cash').patchValue(null);
        firstStepControls.get('duration').patchValue(null);
      } else {
        this.selectedScenario = null;
        firstStepControls.get('scenario_id').patchValue(null);
      }
    }
    /* from Step 2 back to Step 1: reset NULLed values */
    if (event.selectedIndex === 0 && event.previouslySelectedIndex === 1) {
      if (!firstStepControls.get('automatic_start').value) {
        // reset Start Time
        this.resetTime();
        // Patch form value with reset/rounded time
        firstStepControls.get('start_time').patchValue(this.hours + ':' + this.minutes);
        // reset Start Date
        firstStepControls.get('start_date').patchValue(this.today.toISOString());
      }
      if (firstStepControls.get('turn_advancement').value === 'Manual') {
        // Reset Quarter Duration
        firstStepControls.get('quarter_duration').patchValue(0);
      }
      if (this.gameType === 'scenario') {
        firstStepControls.patchValue({
          'starting_cash': this.cashOptions[0].value,
          'duration': this.durationOptions[0].value.toString()
        });
      }
    }

    /* From any step moving forward */
    if (event.selectedIndex > event.previouslySelectedIndex) {
      // eslint-disable-next-line max-len
      localStorage.setItem('create_game_step_' + event.previouslySelectedIndex, JSON.stringify(this.formArray.get([event.previouslySelectedIndex]).value));
    }
  }

  onUserResizeEnd(event: ResizeEvent): void {
    this.userBoxHeight = event.rectangle.height + 'px';
  }

  onCompanyResizeEnd(event: ResizeEvent): void {
    this.companyBoxHeight = event.rectangle.height + 'px';
  }

  createEntity(entityCreate?: any) {}

  loadEntityFromId(id: number) {}

  checkCreateTime() {
    // disabled button to prevent a double click
    this.createDisabled = true;
    this.createLoading = true;

    const gameCreate = JSON.parse(localStorage.getItem('create_game_step_0'));

    // update course_id, since this isn't selected by the time the other form fields are entered in localStorage
    gameCreate.course_id = this.courseId;
    gameCreate.course_ids = this.getAllSelectedClassroomIds();
    /*  Game Data Transforms */
    // concat and convert date
    // TODO instead of substring change the Parse settings on Material DatePicker Module
    if (gameCreate.start_date !== null && gameCreate.start_time !== null) {

      // convert date to local time using DatePipe
      const localDate = this.datePipe.transform(gameCreate.start_date, 'yyyy-MM-dd');
      let timeString = this.getDatetime(localDate, gameCreate.start_time);

      // if timeString is already past, set to current time
      if (new Date(timeString) < new Date()) {
        timeString = new Date();
        this.startTimeWarning(gameCreate, timeString);
      } else {
        gameCreate.start_time = timeString;
        this.createEntity(gameCreate);
      }
    } else {
      // Manual Games
      this.createEntity(gameCreate);
    }
  }

  ngOnInit() {
    this.gameType = 'custom';
    this.teamArray = [];

    this.loadMode();
    this.getAllScenarios();
    this.resetLocalStorageGameParameters();
    this.getAllUsersClasses();

    const firstStepControls = this.formArray['controls'][0];
    const id = +this.route.snapshot.paramMap.get('id');
    if (id) {
      this.loadEntityFromId(id);
    } else {
      // Patch form value with rounded time
      firstStepControls.get('start_time').patchValue(this.hours + ':' + this.minutes);
    }
  }

  ngAfterViewInit() {
    // remove stepper icons
    this.myStepper._getIndicatorType = () => 'number';
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}

