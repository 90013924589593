import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {switchMap} from 'rxjs/operators';


import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';

import {SelectionModel} from '@angular/cdk/collections';

import { BracketService, BracketBaseInDB } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameStatus } from '../../games/game-status.enum';

import { UserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { AuthenticationService } from 'src/app/auth/authentication.service';

import { User } from '../../users/user.interface';
import { timer } from 'rxjs';

@Component({
  selector: 'app-brackets-list',
  templateUrl: './brackets-list.component.html',
  styleUrls: ['./brackets-list.component.scss']
})
export class BracketsListComponent implements OnInit, OnDestroy {

  @ViewChild('gameTable') table: MatTable<any>;

  loading = false;

  initSubscription;
  pollingSubscription;

  brackets: [BracketBaseInDB] = null;
  currentUser: User;
  currentUserId = 0;
  states = GameStatus;

  dialogResult: string;

  @Input() controls = true;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['name', 'status', 'current_round'];
  dataSource = new MatTableDataSource(this.brackets);
  selection = new SelectionModel(false, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private userService: UserService,
    private bracketService: BracketService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  /** Whether there are any number of selected elements. */
  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  /** ARIA Accessibility: change label for the checkbox on the passed row */
  checkboxLabel(row?: BracketBaseInDB, i?: number): string {
    if (!row) {
      return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.dataSource.sort = this.sort;

    this.initSubscription = this.userService.readUserMeApiUserMeGet();

    this.initSubscription.subscribe(
      current_user => {
        console.log(current_user.id);
        this.currentUserId = current_user.id;
        this.getBrackets();

        // get number of seconds from NOW until next 5 minute increment
        const startDate = new Date();
        console.log('startDate', startDate);

        const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
        const roundUpTo5Minutes = roundUpTo(1000 * 60 * 5);
        const endDate = new Date(roundUpTo5Minutes(new Date()));

        const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        console.log('seconds', Math.trunc(seconds) );


        if ( this.currentUserId !== 0 ) {

          this.pollingSubscription = timer(seconds, 300000)
            .pipe(
              switchMap(() => this.bracketService.getAllBracketsApiBracketGet() )
            ).subscribe(results =>  {
              this.transformResults(results);
              console.log('interval');
            });

        }
      }
    );


  }

  getBrackets() {
    this.loading = true;

    if ( this.currentUserId !== 0 ) {
      this.bracketService.getAllBracketsApiBracketGet().subscribe(
        results => {
          // console.log('results', results);
          this.transformResults(results);
          this.loading = false;
        }, () => {
          // this.router.navigate(['/courses']);
          console.log('no users found');
        });
    }
  }

  transformResults(results: BracketBaseInDB[]) {

    results.forEach( bracket => {

      // Get "Status" of Bracket by checking status of all Games within a Bracket
      let tempStatus = '';
      const totalStatus = [];
      bracket.games.forEach(game => {
        tempStatus = game.is_active === false && game.is_complete === false ? this.states.pending :
        game.is_complete === true ? this.states.complete : this.states.inProgress;

        totalStatus.push(tempStatus);

      });

      function getAllIndexes(arr, val) {
        const indexes = [];
        let i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i] === val) {
                indexes.push(i);
            }
        }
        return indexes;
      }

      if (getAllIndexes(totalStatus, this.states.pending).length === totalStatus.length) {
        (bracket as any).status = this.states.pending;
      } else if ( getAllIndexes(totalStatus, this.states.complete).length < totalStatus.length ) {
        (bracket as any).status = this.states.inProgress;
      } else {
        (bracket as any).status = this.states.complete;
      }

    });

    this.dataSource.data = results;

  }

  ngOnDestroy() {
    // this.initSubscription.unsubscribe();
    if (this.pollingSubscription !== undefined) {
      this.pollingSubscription.unsubscribe();
    } else {
      this.authenticationService.logout();
      // this.router.navigate(['/login']);
    }
  }


}
