import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-area-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss']
})
export class AreaStatsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
