import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {User} from '../../users/user.interface';
import {
  CompetitionBaseInDBWithGames, CompetitionService,
  GameService,
  GameWithCompanies,
  ScenarioService,
  ScenarioUpdate
} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {GameStatus} from '../../games/game-status.enum';
import {GameUpdate} from '@jaworldwideorg/staging-jaworldwide-titan-sdk/model/gameUpdate';
import {MatButtonToggleGroup} from '@angular/material/button-toggle';
import {AuthenticationService} from '../../auth/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AlertService} from '../../alert/alert.service';
import {UntypedFormBuilder} from '@angular/forms';
import {timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import GameOptions from '../../common/game-options';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-competition-detail-round',
  templateUrl: './competition-detail-round.component.html',
  styleUrls: ['./competition-detail-round.component.scss']
})
export class CompetitionDetailRoundComponent implements OnInit, OnDestroy {

  @ViewChild('competitionTable') table: MatTable<any>;

  pollingSubscription;

  loading = false;
  currentUser: User;
  competitionId: number;
  competition: CompetitionBaseInDBWithGames;
  games: GameWithCompanies[];
  states = GameStatus;
  status: string;
  isRoundPending = true;
  isRoundLockedActive = true;
  isRoundLockedComplete = false;

  gameUpdate: GameUpdate;
  updateDisabled = false;
  startDisabled = false;
  roundLoading = false;

  displayedColumns: string[] = ['name', 'status', 'companies'];

  gameSettings: GameWithCompanies;

  gameTypeOptions = GameOptions.gameTypeOptions;
  cashOptions = GameOptions.cashOptions;
  durationOptions = GameOptions.durationOptions;

  gameType = 'custom';

  selectedScenario: ScenarioUpdate;
  scenarioOptions = [];

  @ViewChild('gameTypeValue', {static: true}) gameTypeValue: MatButtonToggleGroup;

  formGroup = this._formBuilder.group({
    starting_cash: this.cashOptions[0].value,
    duration: this.durationOptions[0].value.toString(),
    scenario_id: null
  });

  constructor(
    private competitionService: CompetitionService,
    private scenarioService: ScenarioService,
    private authenticationService: AuthenticationService,
    private gameService: GameService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder,
    readonly translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.params.subscribe(() => {
      this.competitionId = +this.route.snapshot.paramMap.get('id');
      this.getCompetition(this.competitionId);
    });

    this.getAllScenarios();

    if ( this.currentUser.id !== 0 ) {

      this.pollingSubscription = timer(0, 5000)
        .pipe(
          switchMap(() => this.competitionService.getCompetitionApiCompetitionCompetitionIdGet(this.competitionId) )
        ).subscribe(competition =>  {
          this.getGames(competition);
        });
    }
  }

  getCompetition(id: number) {
    this.loading = true;

    if ( id !== undefined ) {
      this.competitionService.getCompetitionApiCompetitionCompetitionIdGet(id)
        .subscribe({
          next: competition => {
            this.competition = competition;

            // Just going to grab the first game that matches the current_round
            this.gameSettings = competition.games.find(obj => obj.round_number === competition.current_round);

            this.getGames(competition);

            if ( this.gameSettings.scenario_id !== null ) {
              this.gameType = 'scenario';
            }

            this.formGroup.patchValue({
              'starting_cash': this.gameSettings.starting_cash !== null ? this.gameSettings.starting_cash.toString() : null,
              'duration': this.gameSettings.duration !== null ? this.gameSettings.duration.toString() : null,
              'scenario_id': this.gameSettings.scenario_id !== null ? +this.gameSettings.scenario_id : null,
            });

            this.loading = false;
          },
          error: error => {
            console.error(error);
          }
        });
    }
  }

  getGames( competition ) {
    // filter Games down to those that match current round, these are the games to update
    this.games = competition.games.filter(obj => obj.round_number === competition.current_round);

    /* Is Current Round of Games pending */
    this.isRoundPending = this.games.every(game => !game.is_active && !game.is_complete);

    /* Locks for Round Editing */

    // Lock editing if ANY game in current round is in progress or complete
    this.isRoundLockedActive = this.games.some(game => game.is_complete || game.is_active );

    // Lock editing if current round is complete
    this.isRoundLockedComplete = this.games.every(game => !game.is_active && game.is_complete);

    /* Set Status of Round: Pending, In Progress, Complete */
    if ( this.isRoundPending ) {
      this.status = this.states.pending;
    } else if ( this.isRoundLockedComplete ) {
      this.status = this.states.complete;
      this.router.navigate(['/competitions/detail', this.competitionId]);
    } else {
      this.status = this.states.inProgress;
    }
  }

  getAllScenarios() {
    this.scenarioService.readAllScenariosApiScenarioGet()
      .subscribe(
        scenarios => {
          this.scenarioOptions = scenarios;
        }
      );
  }

  gameTypeChanged(event) {
    this.gameType = event.value;
  }

  updateScenario(target) {
    const scenario = target.options[target.selectedIndex].dataset.scenario;
    this.selectedScenario = JSON.parse(scenario);
  }

  buildErrorIfElementsExist(list, errorKey) {
    if (list.length > 0) {
      return `${this.translateService.instant(errorKey, { number: list.length })} `;
    }
    return '';
  }

  showErrors(errorMessage) {
    this.alertService.error(errorMessage, false);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  clearErrorsAndRefreshCompetition() {
    this.startDisabled = false;
    this.alertService.clear();
    this.getCompetition(this.competition.id);
  }

  updateRound() {
    // disabled button to prevent a double click
    this.updateDisabled = true;

    this.gameUpdate = {};

    // Clean up unused data for Scenario Games
    if ( this.gameType === 'custom' ) {
      this.selectedScenario = null;
      this.gameUpdate.scenario_id = null;
      this.gameUpdate.duration = this.formGroup.controls['duration'].value;
      this.gameUpdate.starting_cash = this.formGroup.controls['starting_cash'].value;
    } else {
      this.gameUpdate.duration = null;
      this.gameUpdate.starting_cash = null;
      this.gameUpdate.scenario_id = +this.formGroup.controls['scenario_id'].value;
    }

    // update game settings
    this.competitionService.updateRoundGamesApiCompetitionCompetitionIdUpdateGamesInRoundPut(this.competitionId, this.gameUpdate)
      .subscribe({
        next: (result) => {
          if (result.success) {
            this.router.navigate(['/competitions/detail', +this.route.snapshot.paramMap.get('id')]);
          } else {
            let errorMessage = this.buildErrorIfElementsExist(result.games_started, 'competitionGamesAlreadyStartedError');
            errorMessage += this.buildErrorIfElementsExist(result.games_already_complete, 'competitionGamesAlreadyCompleteError');
            errorMessage += this.buildErrorIfElementsExist(result.games_not_updated, 'competitionGamesUpdateRoundError');
            this.showErrors(errorMessage);
          }
        },
        error: error => {
          const errorMsg = this.translateService.instant('competitionUpdateRoundError');
          this.showErrors(errorMsg)
          console.error(error);
        }
      });
  }

  startRound() {
    // disable button to prevent a double click
    this.startDisabled = true;

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: {
        message: this.translateService.instant('startCompetitionAlert', {'competition_id': this.competition.id}),
        reject: this.translateService.instant('cancel')
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.competitionService.startRoundApiCompetitionCompetitionIdStartRoundPost(this.competitionId)
          .subscribe({
            next: (result) => {
              if (result.success) {
                this.clearErrorsAndRefreshCompetition();
              } else {
                this.startDisabled = false;
                if (result.games_with_no_claimed_companies.length > 0) {
                  this.forceStartRound(result.games_with_no_claimed_companies.length);
                } else {
                  let errorMessage = `${this.translateService.instant('competitionStartRound2Error')} `;
                  errorMessage += this.buildErrorIfElementsExist(result.games_already_complete, 'competitionGamesAlreadyCompleteError');
                  errorMessage += this.buildErrorIfElementsExist(result.games_already_started, 'competitionGamesAlreadyStartedError');
                  this.showErrors(errorMessage);
                }
              }
            },
            error: error => {
              this.startDisabled = false;
              const errorMsg = this.translateService.instant('competitionStartRound1Error');
              this.showErrors(errorMsg);
              console.error(error);
            }
          });
      } else {
        this.startDisabled = false;
      }
    });
  }

  forceStartRound(noCompanyGameCount) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: {
        message: this.translateService.instant('competitionForceStartRoundAlert', {'number': noCompanyGameCount}),
        reject: this.translateService.instant('cancel')
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.competitionService.startRoundApiCompetitionCompetitionIdStartRoundPost(this.competitionId, true)
          .subscribe({
            next: (result) => {
              if (result.success) {
                this.router.navigate(['/competitions/detail/', this.competition.id]);
              } else {
                let errorMessage = `${this.translateService.instant('competitionStartRound3Error')} `;
                errorMessage += this.buildErrorIfElementsExist(result.games_not_started, 'competitionGamesCouldNotStartError');
                errorMessage += this.buildErrorIfElementsExist(result.games_not_deleted, 'competitionGamesCouldNotDeleteError');
                this.showErrors(errorMessage);
              }
            },
            error: error => {
              const errorMsg = this.translateService.instant('competitionStartRound1Error');
              this.showErrors(errorMsg);
              console.error(error);
            }
          });
      }
    });
  }

  endCurrentRound() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: {
        message: this.translateService.instant('endQuarterAlert'),
        reject: this.translateService.instant('cancel')
      },
      disableClose: true
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === true) {
          this.roundLoading = true;

          this.competitionService.advanceRoundQuarterApiCompetitionCompetitionIdAdvanceRoundQuarterPost(this.competitionId)
            .subscribe({
              next: result => {
                if (result.success) {
                  this.router.navigate(['/competitions/detail/', this.competition.id]);
                } else {
                  this.roundLoading = false;
                  let errorMessage = this.buildErrorIfElementsExist(result.games_already_complete, 'competitionGamesAlreadyCompleteError');
                  errorMessage += this.buildErrorIfElementsExist(result.games_not_started, 'competitionGamesNotStartedError');
                  errorMessage += this.buildErrorIfElementsExist(result.games_not_advanced, 'competitionGamesUpdateRoundError');
                  this.showErrors(errorMessage);
                }
              },
              error: (error) => {
                this.roundLoading = false;
                const errorMsg = this.translateService.instant('competitionEndQuarterError');
                this.showErrors(errorMsg);
                console.error(error);
              }
            });
        } else {
          this.roundLoading = false;
        }
      });
  }

  ngOnDestroy() {
    if (this.pollingSubscription !== undefined) {
      this.pollingSubscription.unsubscribe();
    } else {
      this.authenticationService.logout();
    }
  }

}
