<h4>{{'marketShare' | translate}}</h4>
<ul class="legend container-flex">
    <li *ngFor="let company of this.companyLegends; let i = index" class="pr-4">
        <ng-container [ngSwitch]="company.company_type">
            <h5 *ngSwitchCase="'Total'">{{'allOtherCompanies' | translate}}</h5>
            <h5 *ngSwitchDefault>{{company.company_type}}</h5>
        </ng-container>
        <div class="container-flex align-items-center">
            <svg height="28" viewBox="0 0 6 6" width="34" xmlns="http://www.w3.org/2000/svg">
                 
                <line [attr.stroke-dasharray]="company.company_type === 'average' ? '1 2' : '0'" [attr.stroke]="company.trade_dress.logos[0].color_id === 'F7F7F7FF' ? '#' + '999999': '#' + company.trade_dress.logos[0].color_id" stroke-linecap="round" x1="1"
                      x2="6"
                      y1="3"
                      y2="3"/>
            </svg>
            <span *ngIf="company.company_type !== 'average'">{{ company.name }}</span>
        </div>
    </li>
</ul>
<div id="chart"></div>
