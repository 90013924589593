import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import {Company} from '../company.interface';
import {CompaniesService} from '../companies.service';

@Component({
    selector: 'app-companies-list',
    templateUrl: './companies-list.component.html',
    styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {


    model: Company;
    companies: [{ model }] = null;

    displayedColumns: string[] = ['index', 'name', 'height', 'mass', 'hair_color', 'eye_color'];
    dataSource = new MatTableDataSource(this.companies);
    selection = new SelectionModel(true, []);

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private router: Router,
        private companyService: CompaniesService
    ) {
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        console.log('numSelected', numSelected);
        console.log('numRows', numRows);
        return numSelected === numRows;
    }

    /** Whether there are any number of selected elements. */
    isAnySelected() {
        const numSelected = this.selection.selected.length;
        return numSelected > 0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAnySelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** ARIA Accessibility: change label for the checkbox on the passed row */
    checkboxLabel(row?: Company, i?: number): string {
        if (!row) {
            return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;

        this.companyService.getUsers().subscribe(
            results => {
                console.log(results.results);
                this.dataSource.data = results.results;
            },
            error => {
                console.log('no users found');
            });
    }

}
