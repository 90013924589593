import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, FormGroupDirective, NgForm, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType } from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { isObject } from 'util';
import { ScenarioEvent } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { UploadService } from 'src/app/shared/upload.service';
import { ScenarioService } from '../economic-model.service';
import { EconomicModelCreateDialogComponent } from '../economic-model-create-dialog/economic-model-create-dialog.component';
import { EconomicModelAssignEventDialogComponent } from '../economic-model-assign-dialog/economic-model-assign-dialog.component';
// eslint-disable-next-line max-len
import { EconomicModelDeleteEventDialogComponent } from './../economic-model-delete-event-dialog/economic-model-delete-event-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-economic-model-create',
  templateUrl: './economic-model-create.component.html',
  styleUrls: ['./economic-model-create.component.scss']
})
export class EconomicModelCreateComponent implements OnInit {

  editMode = false;
  scenario = null; // parent Scenario model (set if editMode === true)

  scenarioEventOptions = [];

  startScenarioEventControl = new UntypedFormControl();
  filteredStartScenarioEventOptions: Observable<string[]>;

  earlyScenarioEventControl = new UntypedFormControl();
  filteredEarlyScenarioEventOptions: Observable<string[]>;

  midScenarioEventControl = new UntypedFormControl();
  filteredMidScenarioEventOptions: Observable<string[]>;

  lateScenarioEventControl = new UntypedFormControl();
  filteredLateScenarioEventOptions: Observable<string[]>;

  error: string;
  uploadResponse: { status: string, message: number, filePath: string } = null;
  uploadStatus = {
      'image': this.uploadResponse
  };

  formGroup = this._formBuilder.group({
    title: ['', Validators.required ],
    description: ['', Validators.required ],
    image: ['', Validators.required ],
    starting_cash: ['', Validators.required ],
    duration: ['', Validators.required ]
  });

  constructor(
      private _formBuilder: UntypedFormBuilder,
      private scenarioService: ScenarioService,
      private uploadService: UploadService,
      private route: ActivatedRoute,
      private router: Router,
      public dialog: MatDialog,
      private readonly translateService: TranslateService
  ) {
  }

  ngOnInit() {
      this.uploadStatus.image = { status: '', message: 0, filePath: '' };
      this.loadData();
  }

  loadData() {
      const scenarioId = this.route.snapshot.params.id;
      if (scenarioId) {
          // Get sponsor from DB via API call
          this.scenarioService.getScenario(scenarioId).subscribe(res => {
              this.editMode = true;
              this.scenario = res;
              this.scenarioService.getScenarioEvents().subscribe(events => {
                  this.prepareAutoCompleteFields(events);
                  this.populateForm(res);
              });
          });
      } else {
          this.scenarioService.getScenarioEvents().subscribe(events => {
            this.prepareAutoCompleteFields(events);
          });
      }
  }

  openDeleteScenarioEventDialog(control) {
      const scenarioEvent = control.value;
      const dialogRef = this.dialog.open(EconomicModelDeleteEventDialogComponent, {
        width: '460px',
        height: '460px',
        data: {
            scenarioEvent: scenarioEvent
        }
      });
      dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.scenarioService.deleteScenarioEvent(scenarioEvent.id).subscribe(response => {
                this.loadData();
                control.setValue('');
            });
          }
      });
  }

  openScenarioEventDialog(timing): void {
      let control, value;
      const data = {
          timing: timing,
          scenario: this.scenario,
          edit: false
      };
      if (timing === 'start') {
          control = this.startScenarioEventControl;
          value = control.value;
      } else if (timing === 'early') {
          control = this.earlyScenarioEventControl;
          value = control.value;
      } else if (timing === 'mid') {
          control = this.midScenarioEventControl;
          value = control.value;
      } else if (timing === 'late') {
          control = this.lateScenarioEventControl;
          value = control.value;
      }
      if (value) {
          // Edit ScenarioEvent
          data['edit'] = true;
          data['scenarioEvent'] = value;
      }
      const dialogRef = this.dialog.open(EconomicModelCreateDialogComponent, {
          width: '460px',
          height: '460px',
          data: data
      });
      dialogRef.afterClosed().subscribe(result => {
          this.loadData();
          if (result) {
            control.setValue(result);
          }
      });
  }

  prepareAutoCompleteFields(events) {
      this.scenarioEventOptions = events;
      this.filteredStartScenarioEventOptions = this.startScenarioEventControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterScenarioEvents(value, 'start'))
      );
      this.filteredEarlyScenarioEventOptions = this.earlyScenarioEventControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterScenarioEvents(value, 'early'))
      );
      this.filteredMidScenarioEventOptions = this.midScenarioEventControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterScenarioEvents(value, 'mid'))
      );
      this.filteredLateScenarioEventOptions = this.lateScenarioEventControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterScenarioEvents(value, 'late'))
      );
  }

  populateForm(response) {
      this.uploadStatus['id'] = response.id;
      // Set text fields
      this.formGroup.patchValue({
          title: response.title,
          description: response.description,
          starting_cash: response.starting_cash,
          duration: response.duration
      });
      if (response.image_url) {
        // Set image fields
        this.uploadStatus.image['status'] = 'progress';
        this.uploadStatus.image['message'] = 100;
        this.uploadStatus.image['filePath'] = response.image_url;
        this.uploadStatus.image['fileId'] = response.image;
        // Remove image requirement
        this.formGroup.patchValue({
            image: ['']
        });
      }
      // set auto-complete field values
      response.events.forEach(scenarioEvent => {
          switch (scenarioEvent.timing.toLowerCase()) {
              case 'start':
                  this.startScenarioEventControl.setValue(scenarioEvent);
                  break;
              case 'early':
                  this.earlyScenarioEventControl.setValue(scenarioEvent);
                  break;
              case 'mid':
                  this.midScenarioEventControl.setValue(scenarioEvent);
                  break;
              case 'late':
                  this.lateScenarioEventControl.setValue(scenarioEvent);
                  break;
          }
      });
      this.uploadStatus.image.filePath = response.image_url;
  }

  _filterScenarioEvents(value, timing) {
    const is_object = value instanceof Object;
    if (is_object && value.hasOwnProperty('title')) {
        value = value.title;
    }
    if (value) {
        const filteredValue = value.toLowerCase();
        const result = this.scenarioEventOptions.filter(
            scenarioEvent => scenarioEvent.title.toLowerCase().indexOf(filteredValue) === 0 &&
            scenarioEvent.timing.toLowerCase() === timing);
        return result;
    }
  }

  displayScenarioEventTitle(scenarioEvent) {
      return scenarioEvent && scenarioEvent.title ? scenarioEvent.title : '';
  }

  checkIfEventsBelongToOtherScenario(events) {
      const scenarioId = this.route.snapshot.params.id;
      const result = events.filter(event => event.scenario_id !== scenarioId);
      return result;
  }

  submitScenario(data) {
    if (this.editMode) {
        if (this.uploadStatus.hasOwnProperty('id')) {
            // Edit existing scenario
            data['id'] = this.uploadStatus['id'];
            this.scenarioService.editScenario(this.uploadStatus['id'], data).subscribe((response) => {
                if (response && response.hasOwnProperty('id')) {
                    this.router.navigate(['/scenarios']);
                }
            });
        }
    } else {
        // Create a new scenario
        this.scenarioService.createScenario(data).subscribe((response) => {
            if (response && response.hasOwnProperty('id')) {
                this.router.navigate(['/scenarios']);
            }
        });
    }
  }

  checkIfSelectionsMade(data) {
      const scenarioId = this.route.snapshot.params.id;
      const events = data.events;
      if (this.checkIfEventsBelongToOtherScenario(events).length > 0) {
          /*
          Prompt user of ScenarioEvent(s) re-assignment(s)
          */
          const dialogRef = this.dialog.open(EconomicModelAssignEventDialogComponent, {
              width: '460px',
              height: '460px',
              data: {
                  scenarioEvents: events,
                  scenario_id: scenarioId
              }
          });
          dialogRef.afterClosed().subscribe(result => {
             if (result) {
                 this.submitScenario(data);
             }
          });
      } else {
          /* No re-assignments necessary, proceed with scenario submission */
          this.submitScenario(data);
      }
  }

  getScenario(nameOrObject, listOfObjects) {
      const isAnObject = nameOrObject && nameOrObject.hasOwnProperty('title');
      let title;
      let found = null;
      if (isAnObject) {
          title = nameOrObject.title;
      } else {
          title = nameOrObject;
      }
      listOfObjects.forEach(scenario => {
          if (title === scenario.title) {
              found = scenario;
          }
      });
      return found;
  }

  areAutoCompleteFieldsInvalid() {
      /* Determines if at least one ScenarioEvent selection has been made */
      const controls = [
          this.startScenarioEventControl, this.earlyScenarioEventControl,
          this.midScenarioEventControl, this.lateScenarioEventControl
      ];
      const results = [];
      for (let i = 0; i < controls.length; i++) {
          const control = controls[i];
          if (control.value === null || this.getScenario(control.value, this.scenarioEventOptions) === null ||
              isObject(this.getScenario(control.value, this.scenarioEventOptions)) === false) {
              // selection not made (invalid)
              results.push(false);
          } else {
              // selection made (valid)
              results.push(true);
          }
      }
      const isFalse = (value) => value === false;
      return results.every(isFalse);
  }

  createScenario() {
      // Build json request object
      const data = {
          title: this.formGroup.value.title,
          description: this.formGroup.value.description,
          image: this.formGroup.value.image,
          starting_cash: this.formGroup.value.starting_cash,
          duration: this.formGroup.value.duration,
          events: []
      };
      // Set relevant file id
      const keys = Object.keys(this.uploadStatus);
      keys.forEach((key) => {
          data[key] = this.uploadStatus[key].fileId;
      });
      if (this.startScenarioEventControl.value && isObject(this.startScenarioEventControl.value)) {
          data.events.push(this.startScenarioEventControl.value);
      }
      if (this.earlyScenarioEventControl.value && isObject(this.earlyScenarioEventControl.value)) {
          data.events.push(this.earlyScenarioEventControl.value);
      }
      if (this.midScenarioEventControl.value && isObject(this.midScenarioEventControl.value)) {
          data.events.push(this.midScenarioEventControl.value);
      }
      if (this.lateScenarioEventControl.value && isObject(this.lateScenarioEventControl.value)) {
          data.events.push(this.lateScenarioEventControl.value);
      }
      this.checkIfSelectionsMade(data);
  }

  onFileChange(event, field: string) {
      let file = event.target.files[0];

      if (event.target.files.length > 0) {
          file = event.target.files[0];
      }

      if (file) {
          const reader = new FileReader();

          reader.onloadstart = (e) => {
              this.uploadStatus[field].status = 'progress';
              this.uploadStatus[field].message = 0;
              this.onUpload(file, field);
          };

          reader.readAsBinaryString(file);
      }
  }

  fileUploadError(error, field) {
      this.error = '(' + error.statusText + ') ' + this.translateService.instant(error.error.detail.code);
      this.uploadStatus[field].status = 'error';
      this.uploadStatus[field].message = this.error;
  }

  fileUploadProgress(event, field) {
      const progress = Math.round(100 * event.loaded / event.total);
      this.uploadStatus[field].status = 'progress';
      this.uploadStatus[field].message = progress;
  }

  fileUploadCompleted(event, field) {
      // File upload task is complete but not neccessarily successful
      console.log('upload complete');
  }

  fileUploadSuccess(event, field) {
      const res = event.body;
      this.uploadResponse = res;
      this.uploadStatus[field].filePath = res.url;
      this.uploadStatus[field].fileId = res.id;
  }

  onUpload(file, field) {
      const formData = new FormData();
      formData.append('file', file);
      this.uploadService.uploadFile(formData).subscribe(
          (event) => {
              switch (event.type) {
                  case HttpEventType.UploadProgress:
                      this.fileUploadProgress(event, field);
                      break;

                  case HttpEventType.Response:
                      this.fileUploadSuccess(event, field);
                      break;

                  default:
                      if (event.hasOwnProperty('type')
                          && event.hasOwnProperty('loaded')
                          && event.hasOwnProperty('total')
                          && event['loaded'] === event['loaded']) {
                          this.fileUploadCompleted(event, field);
                      }
                      break;
              }
          },
          (err) => {
              this.fileUploadError(err, field);
          }
      );
  }
}
