<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1 class="d-inline-block">{{ editMode ? ('editScenario' | translate) : ('newScenario' | translate) }}</h1>
        </div>
    </div>
</header>
<div class="container p-0">
    <form [formGroup]="formGroup">
        <div class="py-4">
            <mat-form-field>
                <input [maxlength]="26" formControlName="title" id="title" matInput placeholder="Scenario Title">
            </mat-form-field>
            <mat-error *ngIf="formGroup.get('title').dirty && formGroup.get('title').errors?.required" class="test">
                {{ 'titleRequired' | translate }}
            </mat-error>
        </div>
        <div class="py-4">
            <mat-form-field>
        <textarea cols="30" formControlName="description" id="description"
                  matInput placeholder="{{ 'scenarioDescription' | translate }}"
                  rows="1"></textarea>
            </mat-form-field>
        </div>
        <div class="container-flex py-4">
            <div class="column">
                <label>{{ 'scenarioImage' | translate }}</label>
                <p>{{ 'uploadLogoType' | translate }}</p>
                <input (change)="onFileChange($event, 'image')" class="btn-upload d-flex align-items-center justify-content-center my-4"
                       formControlName="image" id="image" type="file"/>
                <label for="image"><span>{{ 'uploadImage' | translate }}</span></label>
                <mat-progress-bar *ngIf="uploadStatus.image.status === 'progress'" [value]="uploadStatus.image.message"
                                  mode="determinate"></mat-progress-bar>
                <mat-error *ngIf="error">
                    <div *ngIf="uploadStatus.image.status === 'error'">
                        {{ uploadStatus.image.message }}
                    </div>
                </mat-error>
                <div class="image-preview my-4">
                    <img *ngIf="uploadStatus.image.message >= 100 && uploadStatus.image.filePath !== null"
                         [src]="uploadStatus.image.filePath | safeHtml"/>
                </div>
            </div>
        </div>

        <div class="py-4">
            <mat-form-field>
                <input [maxlength]="26" formControlName="starting_cash" id="starting_cash"
                       matInput placeholder="{{ 'startingCash' | translate }}">
            </mat-form-field>
            <mat-error *ngIf="formGroup.get('starting_cash').dirty && formGroup.get('starting_cash').errors?.required"
                       class="test">
                {{ 'startingCashRequired' | translate }}
            </mat-error>
        </div>

        <div class="py-4">
            <mat-form-field>
                <input [maxlength]="26" formControlName="duration" id="duration" matInput
                       placeholder="{{ 'duration' | translate }}">
            </mat-form-field>
            <mat-error *ngIf="formGroup.get('duration').dirty && formGroup.get('duration').errors?.required"
                       class="test">
                {{ 'durationRequired' | translate }}
            </mat-error>
        </div>

        <div class="scenarioeventcontainer">
            <div class="search-part">
                <mat-form-field appearance="none">
                    <input [formControl]="startScenarioEventControl"
                           [matAutocomplete]="auto1"
                           aria-label=""
                           matInput
                           placeholder="{{ 'startScenarioEvent' | translate }}"
                           type="text">
                    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayScenarioEventTitle"
                                      autoActiveFirstOption>
                        <mat-option *ngFor="let scenarioEvent of filteredStartScenarioEventOptions | async"
                                    [value]="scenarioEvent">
                            {{ scenarioEvent.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button (click)="openScenarioEventDialog('start')" color="secondary" mat-icon-button>
                    <mat-icon>{{ getScenario(startScenarioEventControl.value, scenarioEventOptions) ? ('edit' | translate) : ('add' | translate) }}</mat-icon>
                </button>
                <button (click)="openDeleteScenarioEventDialog(startScenarioEventControl)" *ngIf="getScenario(startScenarioEventControl.value, scenarioEventOptions)"
                        color="warn"
                        mat-icon-button>
                    <mat-icon>{{ 'delete' | translate }}</mat-icon>
                </button>
            </div>
        </div>

        <div class="scenarioeventcontainer">
            <div class="search-part">
                <mat-form-field appearance="none">
                    <input [formControl]="earlyScenarioEventControl"
                           [matAutocomplete]="auto2"
                           aria-label=""
                           matInput
                           placeholder="{{ 'earlyScenarioEvent' | translate }}"
                           type="text">
                    <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayScenarioEventTitle"
                                      autoActiveFirstOption>
                        <mat-option *ngFor="let scenarioEvent of filteredEarlyScenarioEventOptions | async"
                                    [value]="scenarioEvent">
                            {{ scenarioEvent.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button (click)="openScenarioEventDialog('early')" color="secondary" mat-icon-button>
                    <mat-icon>{{ getScenario(earlyScenarioEventControl.value, scenarioEventOptions) ? ('edit' | translate) : ('add' | translate) }}</mat-icon>
                </button>
                <button (click)="openDeleteScenarioEventDialog(earlyScenarioEventControl)" *ngIf="getScenario(earlyScenarioEventControl.value, scenarioEventOptions)"
                        color="warn"
                        mat-icon-button>
                    <mat-icon>{{ 'delete' | translate }}</mat-icon>
                </button>
            </div>
        </div>

        <div class="scenarioeventcontainer">
            <div class="search-part">
                <mat-form-field appearance="none">
                    <input [formControl]="midScenarioEventControl"
                           [matAutocomplete]="auto3"
                           aria-label=""
                           matInput
                           placeholder="{{ 'midScenarioEvent' | translate }}"
                           type="text">
                    <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayScenarioEventTitle"
                                      autoActiveFirstOption>
                        <mat-option *ngFor="let scenarioEvent of filteredMidScenarioEventOptions | async"
                                    [value]="scenarioEvent">
                            {{ scenarioEvent.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button (click)="openScenarioEventDialog('mid')" color="secondary" mat-icon-button>
                    <mat-icon>{{ getScenario(midScenarioEventControl.value, scenarioEventOptions) ? ('edit' | translate) : ('add' | translate) }}</mat-icon>
                </button>
                <button (click)="openDeleteScenarioEventDialog(midScenarioEventControl)" *ngIf="getScenario(midScenarioEventControl.value, scenarioEventOptions)"
                        color="warn"
                        mat-icon-button>
                    <mat-icon>{{ 'delete' | translate }}</mat-icon>
                </button>
            </div>
        </div>

        <div class="scenarioeventcontainer">
            <div class="search-part">
                <mat-form-field appearance="none">
                    <input [formControl]="lateScenarioEventControl"
                           [matAutocomplete]="auto4"
                           aria-label=""
                           matInput
                           placeholder="{{ 'lateScenarioEvent' | translate }}"
                           type="text">
                    <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayScenarioEventTitle"
                                      autoActiveFirstOption>
                        <mat-option *ngFor="let scenarioEvent of filteredLateScenarioEventOptions | async"
                                    [value]="scenarioEvent">
                            {{ scenarioEvent.title }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <button (click)="openScenarioEventDialog('late')" color="secondary" mat-icon-button>
                    <mat-icon>{{ getScenario(lateScenarioEventControl.value, scenarioEventOptions) ? ('edit' | translate) : ('add' | translate) }}</mat-icon>
                </button>
                <button (click)="openDeleteScenarioEventDialog(lateScenarioEventControl)" *ngIf="getScenario(lateScenarioEventControl.value, scenarioEventOptions)"
                        color="warn"
                        mat-icon-button>
                    <mat-icon>{{ 'delete' | translate }}</mat-icon>
                </button>
            </div>
        </div>
        <button (click)="createScenario()" [disabled]="formGroup.invalid || areAutoCompleteFieldsInvalid()" class="button-primary" mat-button
                type="submit">{{ editMode ? ('editScenario' | translate) : ('createScenario' | translate) }}
        </button>
    </form>
</div>  
