import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';

import {User} from './user.interface';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private configUrl: string;

    constructor(
        private http: HttpClient,
        private globals: Globals
    ) {
        this.configUrl = globals.configUrl;
    }

    getUsers() {
        return this.http.get<any>(this.configUrl + 'people?_format=json');
    }

    register(user: User) {
        return this.http.post(this.configUrl + '/users/register', user);
    }

    delete(id: number) {
        return this.http.delete(this.configUrl + '/users/' + id);
    }


}
