<div class="list-box row mb-0">
    <table [dataSource]="dataSource" mat-table matSort>

        <ng-container matColumnDef="index">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{ 'rank' | translate }} </th>
            <td *matCellDef="let element; let i = index;" mat-cell>{{i}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'name' | translate }}</th>
            <td *matCellDef="let company" mat-cell><span class="column-width">{{company.name}}</span></td>
        </ng-container>

        <ng-container matColumnDef="height">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'height' | translate }}</th>
            <td *matCellDef="let company" mat-cell> {{company.height}} </td>
        </ng-container>

        <ng-container matColumnDef="mass">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'mass' | translate }}</th>
            <td *matCellDef="let company" mat-cell> {{company.mass}} </td>
        </ng-container>

        <ng-container matColumnDef="hair_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'hairColor' | translate }}</th>
            <td *matCellDef="let company" mat-cell> {{company.hair_color}} </td>
        </ng-container>

        <ng-container matColumnDef="eye_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'eyeColor' | translate }}</th>
            <td *matCellDef="let company" mat-cell> {{company.eye_color}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>