import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import { TeamsService } from '../../teams/teams.service';

import { GameService, CompanyService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';


@Component({
  selector: 'app-company-detail-quarter',
  templateUrl: './company-detail-quarter.component.html',
  styleUrls: ['./company-detail-quarter.component.scss']
})
export class CompanyDetailQuarterComponent implements OnInit, OnChanges {

  @Input() companyId?: number;
  @Input() gameId?: number;
  @Input() totalQuarters: number;

  quarter: number;

  loading = false;

  model: Company;
  teams: [{model}];

  activeCompanies: Company[];
  quarterResults: CompanyQuarterResult[] = null;

  displayedColumns: string[] = ['quarter', 'revenue', 'expenses', 'profit'];
  dataSource = new MatTableDataSource(this.quarterResults);
  selection = new SelectionModel(true, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @ViewChild('resultTable') resultTable: MatTable<any>;

  constructor(
    private router: Router,
    private gameService: GameService,
    private companyService: CompanyService,
    private teamService: TeamsService
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;

    if (this.companyId !== null) {
      this.getCompanyQuarters(this.companyId);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes');
  }

  getCompanyQuarters(companyId: number) {

    this.quarterResults = [];
    this.loading = true;

    console.log('this.totalQuarters', this.totalQuarters);
      for (let quarter = 0; quarter < this.totalQuarters; quarter++) {
        this.companyService.readCompanyQuarterResultApiCompanyCompanyIdQuarterQuarterResultGet(companyId, quarter)
          .subscribe(
            results => {
              this.quarterResults.push(results);
              this.dataSource.data = this.quarterResults;

              console.log('results ' + quarter, results);
              console.log('this.quarterResults.length', this.quarterResults.length);
          }, () => {
            console.log('no companies found');
          });
      }
      this.loading = false;

  }

}
