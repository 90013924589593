import { Component, OnInit, ViewChild, Input, SimpleChanges, OnChanges } from '@angular/core';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';

import {CompanyWithUsers} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { AuthenticationService } from '../../auth/authentication.service';

import { User } from '../../users/user.interface';
import {GameStatus} from '../game-status.enum';


@Component({
  selector: 'app-game-detail-companies',
  templateUrl: './game-detail-companies.component.html',
  styleUrls: ['./game-detail-companies.component.scss']
})
export class GameDetailCompaniesComponent implements OnInit, OnChanges {

  @Input() status: string;
  @Input() activeCompaniesInput: CompanyWithUsers[];

  loading = false;

  currentUser: User;

  displayedColumns: string[] = ['check', 'company'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable, {static: true}) table: MatTable<any>;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit() {
    this.dataSource.data = this.activeCompaniesInput;
    this.dataSource.sort = this.sort;
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnChanges(changes: SimpleChanges) {
    const activeCompanies = changes['activeCompaniesInput'];
    if (!activeCompanies.currentValue || (Array.isArray(activeCompanies.currentValue) && activeCompanies.currentValue.length === 0)) {
      this.dataSource.data = [];
      this.table.renderRows();
    } else {
      this.dataSource.data = activeCompanies.currentValue;
    }
  }

  getCounterText() {
    let count = 0
    if (this.status === GameStatus.pending) {
      count = this.activeCompaniesInput.filter(c => c.is_claimed).length
    } else if (this.status === GameStatus.inProgress) {
      count = this.activeCompaniesInput.filter(c => c.submit_time).length
    }
    return `(${count} / ${this.activeCompaniesInput.length})`
  }

  isCompanyCompleted(company: CompanyWithUsers): boolean {
    return this.status === GameStatus.pending ? !!company.is_claimed : !!company.submit_time;
  }

  getUserNames(company: CompanyWithUsers) {
    return company.users.map(u => u.full_name ? u.full_name : u.sub).join(', ')
  }

}
