import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    sponsors: null;
}

@Component({
    selector: 'app-sponsorship-model-delete-event-dialog',
    templateUrl: 'sponsorship-delete-dialog.component.html',
    styleUrls: ['./sponsorship-delete-dialog.component.scss']
})
export class SponsorshipDeleteDialogComponent implements OnInit {

    displayedColumns: string[] = ['id', 'title'];

    constructor(
        public dialogRef: MatDialogRef<SponsorshipDeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
