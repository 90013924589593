const quarterDurationOptions = [
    {value: 5, name: '5'},
    {value: 10, name: '10'},
    {value: 15, name: '15 - 20'},
    {value: 30, name: '30 - 35'},
    {value: 45, name: '45 - 50'},
    {value: 60, name: '60 - 65'},
    {value: 1440, name: '1'}
];

const completionOptions = [
    {value: 'Timed', name: 'automatic'},
    {value: 'Manual', name: 'manual'}
];

const cashOptions = [
    {value: '100000', name: '$100,000'},
    {value: '500000', name: '$500,000'},
    {value: '1000000', name: '$1,000,000'},
    {value: '2000000', name: '$2,000,000'},
    {value: '4000000', name: '$4,000,000'}
];

const durationOptions = [
    {value: 4},
    {value: 8},
    {value: 12},
    {value: 16},
    {value: 20}
];

const gameTypeOptions = [
    {value: 'custom', label: 'customGame'},
    {value: 'scenario', label: 'scenarioGame'}
];

const scenarioDifficulties = [
    {starting_cash: 500000, difficulty: 'moderatelyDifficult'},
    {starting_cash: 1000000, difficulty: 'normal'},
    {starting_cash: 2000000, difficulty: 'moderatelyEasy'},
    {starting_cash: 4000000, difficulty: 'easy'},
];

const roundDurationOptions = [
    {value: 15, name: '15'},
    {value: 30, name: '30'},
    {value: 45, name: '45'},
    {value: 60, name: '60'},
    {value: 75, name: '75'},
    {value: 90, name: '90'}
];

export default {
    quarterDurationOptions,
    completionOptions,
    cashOptions,
    durationOptions,
    gameTypeOptions,
    scenarioDifficulties,
    roundDurationOptions
}
