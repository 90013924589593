<h1 mat-dialog-title>{{ 'warning' | translate }}</h1>
<div mat-dialog-content style="padding-bottom: 2em">
    <p>{{ 'removeScenarioAlert' | translate }}</p>
    <table [dataSource]="dataSource" class="mat-elevation-z8" mat-table>
        <ng-container matColumnDef="id">
            <th *matHeaderCellDef mat-header-cell>{{ 'id' | translate }}</th>
            <td *matCellDef="let element" mat-cell> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th *matHeaderCellDef mat-header-cell>{{ 'title' | translate }}</th>
            <td *matCellDef="let element" mat-cell> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="scenario_id">
            <th *matHeaderCellDef mat-header-cell>{{ 'scenarioId' | translate }}</th>
            <td *matCellDef="let element" mat-cell> {{ element.scenario_id || '-' }} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>{{ 'cancel' | translate }}</button>
    <button [mat-dialog-close]="true" cdkFocusInitial mat-button>{{ 'reassign' | translate }}</button>
</div>