<ng-container *ngIf="display_companies?.length > 0">
    <div class="container data-viz-box">
        <app-game-detail-graph-line [category]="'revenue'" [combinedCompanyResults]="combinedCompanyResults" [display_companies]="display_companies"
                                    [game]="game" [scenarioEvents]="scenarioEvents"
                                    [title]="'revenue'"></app-game-detail-graph-line>
    </div>

    <div class="container data-viz-box">
        <app-game-detail-graph-line [category]="'units_produced'" [combinedCompanyResults]="combinedCompanyResults" [display_companies]="display_companies"
                                    [game]="game"
                                    [scenarioEvents]="scenarioEvents"
                                    [title]="'unitsProduced' | translate"></app-game-detail-graph-line>
    </div>

    <div class="container data-viz-box">
        <app-game-detail-graph-bar [combinedCompanyResults]="combinedCompanyResults" [display_companies]="display_companies_total"
                                   [game]="game"
                                   [title]="'marketShare' | translate"></app-game-detail-graph-bar>
    </div>

    <div class="container data-viz-box">
        <app-game-detail-graph-line [category]="'total_completed_projects'" [combinedCompanyResults]="combinedCompanyResults"
                                    [display_companies]="display_companies"
                                    [game]="game"
                                    [scenarioEvents]="scenarioEvents"
                                    [title]="'totalCompletedProjects' | translate"></app-game-detail-graph-line>
    </div>

    <div class="container data-viz-box">
        <app-game-detail-graph-line [category]="'csr_score'" [combinedCompanyResults]="combinedCompanyResults" [display_companies]="display_companies"
                                    [game]="game" [scenarioEvents]="scenarioEvents"
                                    [title]="'CSRScore' | translate"></app-game-detail-graph-line>
    </div>

</ng-container>
