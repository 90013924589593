<h3 class="mb-1">{{ 'companies' | translate }} {{getCounterText()}}</h3>

<div class="list-box row mb-4">

    <div *ngIf="loading" class="d-flex justify-content-center h-100">
        <mat-spinner></mat-spinner>
    </div>

    <table [dataSource]="dataSource" [ngClass]="{'d-none': loading}" mat-table matSort matSortActive="profit"
           matSortDirection="desc">
        <ng-container matColumnDef="check">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let company" mat-cell>{{ isCompanyCompleted(company) ? '✔️' : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="company">
            <th *matHeaderCellDef mat-header-cell>{{ 'name' | translate }}</th>
            <td *matCellDef="let company"
                [title]="getUserNames(company)" class="company-member-list"
                mat-cell>{{ company.is_claimed ? company.name : getUserNames(company) }} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>
