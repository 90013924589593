import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import { Game, ScenarioGameOut, Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {TranslateService} from '@ngx-translate/core';
import * as d3 from 'd3';

export interface QuarterData {
  'company_id': number;
  'revenue': number;
  'quarter':    number;
}

@Component({
  selector: 'app-game-detail-graph-line',
  templateUrl: './game-detail-graph-line.component.html',
  styleUrls: ['./game-detail-graph-line.component.scss']
})
export class GameDetailGraphLineComponent implements OnInit, AfterViewInit {

  @Input() game: Game;
  @Input() category: string;
  @Input() title: string;
  @Input() display_companies: any[];
  @Input() combinedCompanyResults: any[];
  @Input() scenarioQuarter: number;
  @Input() scenarioEvents?: any[];


  companyLegends: any[];

  marker = {
    'type': '!',
  };

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit() {
    // Remove the duplicate elements
    // this.companyLegends = this.display_companies.filter(function (el, index, array) {
    //   return array.indexOf (el) === index;
    // });

    this.companyLegends = Array.from(new Set(this.display_companies.map(a => a.company_id)))
    .map(id => {
      return this.display_companies.find(a => a.company_id === id);
    });

  }

  ngAfterViewInit() {
    // eslint-disable-next-line max-len
    this.graphResults(this.display_companies, this.category, this.game, this.combinedCompanyResults, this.scenarioQuarter, this.scenarioEvents);
  }


  // eslint-disable-next-line max-len
  graphResults(display_companies: any[], category: string, game: Game, combinedCompanyResults: any[], scenarioQuarter: number, scenarioEvents?: any[]) {

    // nest our data
    const dataGroup = d3.nest()
      .key(function(d) {return (d as any).company_id; })
      .entries(display_companies);

    // console.log(JSON.stringify(dataGroup));
    // console.log('dataGroup', dataGroup);

    // set lets: dimensions and margins of the graph
    const yTicks = 6;
      const tickLabels = [];
      for ( let i = 1; i <= game.duration; i++) {
        tickLabels.push(i);
      }
      // console.log(tickLabels);

    const margin = {top: 20, right: 40, bottom: 20, left: 60},
        width = 960 - margin.left - margin.right,
        height = 500 - margin.top - margin.bottom;


    if (this.category) {

      const svgId = '#visualization_' + this.category;

      const last_completed_quarter = game.is_complete === true ? game.duration - 1 : game.current_quarter - 1;

      console.log('last_completed_quarter', last_completed_quarter);

      // console.log('svgId', svgId);

      const vis = d3.select( svgId ),
          WIDTH = width,
          HEIGHT = height,
          MARGINS = margin,
          lSpace = WIDTH / dataGroup.length;

      // console.log('vis', vis);
      // set scales and axes
      const xScale = d3.scaleLinear()
        .domain([0, last_completed_quarter])
        .range([MARGINS.left, WIDTH]);

      // get extents and range
      const yExtent = d3.extent(display_companies, function(d) { return d[category]; });
      const yRange = yExtent[1] - yExtent[0];

      const yScale = d3.scaleLinear()
          .domain([yExtent[0] - (yRange * .05), yExtent[1] + (yRange * .05)])
          .range([HEIGHT - MARGINS.top, MARGINS.bottom]);

      const xAxis = d3.axisBottom(xScale)
        .ticks( last_completed_quarter )
        .tickFormat( (d, i) => tickLabels[i] );

      const yAxis = d3.axisLeft(yScale)
        .ticks(yTicks, 's' );

      // create our axes
      vis.append('svg:g')
          .attr('class', 'x axis')
          .attr('transform', 'translate(' + (MARGINS.left / 1.5 ) + ',' + (HEIGHT - MARGINS.bottom) + ')')
          .call(xAxis);
      vis.append('svg:g')
          .attr('class', 'y axis')
          .attr('transform', 'translate(' + (MARGINS.left + 10) + ',0)')
          .call(yAxis);

      // gridlines in y axis function
      function make_y_gridlines() {
          return d3.axisLeft(yScale)
              .ticks(yTicks);
      }

      // add the Y gridlines
      vis.append('g')
        .attr('class', 'grid')
        .attr('transform', 'translate(' + (MARGINS.left + 10) + ',0)')
        .call(make_y_gridlines()
          .tickSize(-width)
          // .tickFormat('')
        );

      // text label for the x axis
      vis.append('text')
        .attr('transform', 'translate(' + ((width / 2) + margin.left) + ' ,' + (height + margin.bottom + 15) + ')')
        .style('text-anchor', 'middle')
        .style('fill', '#999999')
        .style('font-size', '12px')
        .text(this.translateService.instant('quarter'));

      // text label for the y axis
      const titleTranslated = this.translateService.instant(this.title);

      vis.append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0)
        .attr('x', 0 - (height / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .style('font-size', '12px')
        .style('fill', '#999999')
        .text(this.title !== 'revenue' ? titleTranslated :  titleTranslated + ' ' + this.translateService.instant('inDollars') );

      const lineGen = d3.line()
        .x(function(d) {
            return xScale((d as any).quarter);
        })
        .y(function(d) {
            return yScale((d as any)[category]);
        })
        .curve(d3.curveLinear);

      dataGroup.forEach(function(d, i) {
        // console.log('d.values', d.values);
        const pathData = lineGen(d.values);
        vis.append('path')
          .attr('d', pathData)
          .attr('class', 'line')
          .attr('id', 'line_' + d.key)
          .attr('transform', 'translate(' + (MARGINS.left / 1.5 ) + ',0)')
          .attr('stroke-width', 5)
          .attr('stroke', function() {
              let color = d.values[0].trade_dress.logos[0].color_id;
              // console.log('line color', color);

              if ( color === 'FFFFFF' || color === 'ffffff' || color === 'F7F7F7FF') {
                color = '999999';
              }
              return '#' + color + '';
          })
          // add dashed stroke if is our Average Company (company_id = 0)
          .attr('stroke-dasharray', function() {
            return d.values[0].company_id === 0 ?  '10' : null;
          })
          .attr('fill', 'none');
          });


        const radius = 23;
        const pathHeight = 376;
        const yPosStart = HEIGHT - radius - 3;

        // if (this.scenarioQuarter && this.game.is_complete === true) {
        if (scenarioEvents) {

          // scenarioEvents = Array(
          //   {title: 'Flood', quarter: 0},
          //   {title: 'EARTHQUAKE', quarter: 3},
          //   {title: 'Lightning', quarter: 7}
          // );

          scenarioEvents.forEach((scenario, index) => {
            console.log('scenario ', scenario);

            if ( this.game.current_quarter >= (scenario.quarter + 1) ) {

              const counter = 0;
              const offset = 2; // tooltip offset from marker

              const markerG = vis.append('g')
                .attr('class', 'marker')
                // if scenario.quarter is 0, we assign a special position, otherwise calculate normally
                // eslint-disable-next-line max-len
                .attr('transform', 'translate(' + ((scenario.quarter > 0 ? xScale(scenario.quarter) - radius + 3 : radius ) + MARGINS.left - radius) + ', ' + (HEIGHT - MARGINS.bottom - pathHeight + 1) + ')' );

              markerG.append('circle')
                .attr('class', 'marker-bg')
                .attr('cx', radius)
                .attr('cy', radius)
                .attr('r', radius);
                // .attr('mattooltip', 'test');

              markerG.append('rect')
                .attr( 'class', 'marker-tooltip')
                .attr('width', 20)
                .attr('height', 20)
                .attr('fill', '#ddd')
                .style('transform', `translate(${radius}px,-${(radius + offset + 5)}px) rotate(45deg)`);

              markerG.append('rect')
                .attr( 'id', 'marker-tooltip-box-' + this.category + '-' + index)
                .attr( 'class', 'marker-tooltip')
                .style('transform', `translate(${radius}px,-${(radius + offset )}px)`);

              markerG.append('text')
                .text( this.translateService.instant(scenario.title))
                .attr( 'id', 'marker-tooltip-'  + this.category + '-' + index)
                .attr( 'class', 'marker-tooltip')
                .attr( 'text-anchor', 'middle')
                .style('transform', `translate(${radius}px,-${radius + offset}px)`);

              markerG.append('path')
                  // .attr('d', 'M' + radius + ',' + (0) + 'L' + radius + ',' + (yScale))
                  // .transition()
                  // .duration(1000)
                  .attr('d', 'M' + radius + ',' + (pathHeight) + 'L' + radius + ',' + (radius * 2));

              markerG.append('text')
                .attr('x', radius - ((16 / 2) - 1.5))
                .attr('y', radius * 1.6
                      )
                .text(this.marker.type);

                // get tooltip text so we can place rect behind it
                const tooltip = document.querySelector(`#marker-tooltip-${this.category}-${index}`);
                const tooltipRect = document.querySelector(`#marker-tooltip-box-${this.category}-${index}`);
                console.log('tooltip', tooltip);
                const bounds = (tooltip as SVGGraphicsElement).getBBox();
                // hide tooltip after getting bounds
                console.log('bounds', bounds);
                const padding = 10;
                tooltipRect.setAttribute('x', '' + (bounds.x - padding));
                tooltipRect.setAttribute('y', '' + (bounds.y - padding));
                tooltipRect.setAttribute('width', '' + (bounds.width + (padding * 2)));
                tooltipRect.setAttribute('height', '' + (bounds.height + (padding * 2)));
                tooltipRect.setAttribute('fill', '#DDD');
                tooltipRect.setAttribute('rx', '5px');
            }
          });


        }

    }
  }

}

