import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GamesComponent} from './games/games.component';
import {UsersComponent} from './users/users.component';
import {AreasComponent} from './areas/areas.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {GameCreateComponent} from './games/create/create.component';
import {GamesListComponent} from './games/games-list/games-list.component';
import {LoginComponent} from './login/login.component';
import {JaidLoginComponent} from './login/jaid/jaid-login.component';
import {SponsorshipsComponent} from './sponsorships/sponsorships.component';
import {SponsorshipsListComponent} from './sponsorships/sponsorships-list/sponsorships-list.component';
import {GameDetailComponent} from './games/game-detail/game-detail.component';
import {RegisterComponent} from './register/register.component';

import {AuthGuard} from './auth/auth.guard';
import {StyleguideComponent} from './styleguide/styleguide.component';
import {SponsorshipCreateComponent} from './sponsorships/sponsorships-create/sponsorships-create.component';
import {EconomicModelComponent} from './economic-model/economic-model.component';
import {EconomicModelListComponent} from './economic-model/economic-model-list/economic-model-list.component';

import {Role} from './auth/role.enum';
import {GameDetailGraphLineComponent} from './games/game-detail-graph-line/game-detail-graph-line.component';
import {GameDetailGraphBarComponent} from './games/game-detail-graph-bar/game-detail-graph-bar.component';
import {CompaniesComponent} from './companies/companies.component';
import {CompaniesListComponent} from './companies/companies-list/companies-list.component';
import {CompanyDetailComponent} from './companies/company-detail/company-detail.component';
import {BracketCreateComponent} from './brackets/create/create.component';
import {BracketsComponent} from './brackets/brackets.component';
import {BracketsListComponent} from './brackets/brackets-list/brackets-list.component';
import {BracketDetailComponent} from './brackets/bracket-detail/bracket-detail.component';
import {BracketDetailRoundComponent} from './brackets/bracket-detail-round/bracket-detail-round.component';
import {CompetitionsComponent} from './competitions/competitions.component';
import {CompetitionsListComponent} from './competitions/competitions-list/competitions-list.component';
import {CompetitionCreateComponent} from './competitions/competition-create/competition-create.component';
import {
    CompetitionDetailRoundComponent
} from './competitions/competition-detail-round/competition-detail-round.component';
import {CompetitionDetailComponent} from './competitions/competition-detail/competition-detail.component';
import {CompetitionResultsComponent} from './competitions/competition-results/competition-results.component';

const routes: Routes = [
    {
        path: 'login',
        data: {
            breadcrumb: 'breadcrumb_Login'
        },
        component: JaidLoginComponent
    },
    {
        path: 'altlogin',
        data: {
            breadcrumb: 'breadcrumb_Login'
        },
        component: LoginComponent
    },
    {
        path: 'callback',
        data: {
            breadcrumb: 'breadcrumb_PostLogin'
        },
        component: JaidLoginComponent
    },
    {
        path: 'register',
        data: {
            breadcrumb: 'breadcrumb_Register'
        },
        component: RegisterComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        data: {
            breadcrumb: 'breadcrumb_Dashboard'
        },
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'games',
        data: {
            breadcrumb: 'breadcrumb_games'
        },
        component: GamesComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: GamesListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List'
                },
            },
            {
                path: 'create',
                component: GameCreateComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_GameCreation',
                    roles: [Role.Superuser, Role.Admin_National, Role.Admin_Area, Role.Teacher],
                    mode: 'create-game'
                },
            },
            {
                path: 'create/:id',
                component: GameCreateComponent,
                canActivate: [AuthGuard],
                // TODO restrict to creator?
                data: {
                    breadcrumb: 'breadcrumb_GameCreation',
                    roles: [Role.Superuser, Role.Admin_National, Role.Admin_Area, Role.Teacher],
                    mode: 'create-game'
                },
            },
            {
                path: 'edit/:id',
                component: GameCreateComponent,
                canActivate: [AuthGuard],
                // TODO restrict to creator?
                data: {
                    breadcrumb: 'breadcrumb_EditGame',
                    roles: [Role.Superuser, Role.Admin_National, Role.Admin_Area, Role.Teacher],
                    mode: 'edit-game'
                },
            },
            {
                path: 'detail/:id',
                component: GameDetailComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_GameDetail'
                },
            }
        ]
    },
    {
        path: 'brackets',
        data: {
            breadcrumb: 'breadcrumb_Brackets'
        },
        component: BracketsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: BracketsListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List'
                },
            },
            {
                path: 'create',
                component: BracketCreateComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_BracketsCreation',
                    roles: [Role.Superuser, Role.Admin_National, Role.Admin_Area, Role.Teacher],
                    mode: 'create-bracket'
                },
            },
            {
                path: 'detail/:id',
                component: BracketDetailComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_BracketDetail'
                },
            },
            {
                path: 'detail/round/:id',
                component: BracketDetailRoundComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_EditBracketRound'
                },
            }
        ]
    },
    {
        path: 'competitions',
        data: {
            breadcrumb: 'breadcrumb_Competitions'
        },
        component: CompetitionsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: CompetitionsListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List'
                },
            },
            {
                path: 'create',
                component: CompetitionCreateComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_CompetitionCreation',
                    roles: [Role.Superuser, Role.Admin_National, Role.Admin_Area, Role.Teacher],
                    mode: 'create-competition'
                },
            },
            {
                path: 'detail/:id',
                component: CompetitionDetailComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_CompetitionDetail'
                },
            },
            {
                path: 'detail/round/:id',
                component: CompetitionDetailRoundComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_EditCompetitionRound'
                },
            },
            {
                path: 'results/:id',
                component: CompetitionResultsComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_CompetitionResults'
                },
            }
        ]
    },
    {
        path: 'companies',
        data: {
            breadcrumb: 'breadcrumb_Companies',
            // roles: [Role.Admin_National, Role.Admin_Area]
        },
        component: CompaniesComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'list',
                component: CompaniesListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List'
                },
            }
        ]
    },
    {
        path: 'company/:companyId/:gameId',
        component: CompanyDetailComponent,
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'breadcrumb_CompanyDetail'
        }
    },
    {
        path: 'sponsorships',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'breadcrumb_Sponsorships',
            // roles: [Role.Admin_National]
        },
        component: SponsorshipsComponent,
        children: [
            {
                path: '',
                component: SponsorshipsListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List'
                },
            },
            {
                path: 'create',
                component: SponsorshipCreateComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_CreateSponsorship',
                    // roles: [Role.Admin_National]
                },
            },
            {
                path: 'edit/:id',
                component: SponsorshipCreateComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_EditSponsorship',
                },
            }
        ]
    },
    {
        path: 'users',
        data: {
            breadcrumb: 'breadcrumb_Users',
            // roles: [Role.Admin_National, Role.Admin_Area]
        },
        component: UsersComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'areas',
        data: {
            breadcrumb: 'breadcrumb_Areas',
            roles: [Role.Admin_National, Role.Admin_Area]
        },
        component: AreasComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'scenarios',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: 'breadcrumb_Scenarios'
        },
        component: EconomicModelComponent,
        children: [
            {
                path: '',
                component: EconomicModelListComponent,
                canActivate: [AuthGuard],
                data: {
                    breadcrumb: 'breadcrumb_List',
                    roles: [Role.Superuser]
                },
            }
        ]
    },
    {
        path: 'line-graph',
        data: {
            breadcrumb: 'breadcrumb_LineGraph'
        },
        component: GameDetailGraphLineComponent
    },
    {
        path: 'bar-graph',
        data: {
            breadcrumb: 'breadcrumb_BarGraph'
        },
        component: GameDetailGraphBarComponent
    },
    {
        path: 'styleguide',
        data: {
            breadcrumb: 'breadcrumb_Styleguide',
            roles: [Role.Admin_National]
        },
        component: StyleguideComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
