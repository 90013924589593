<div *ngIf="loading" class="d-flex justify-content-center h-100">
    <mat-spinner></mat-spinner>
</div>

<ng-container *ngIf="!loading">

    <header>
        <div class="container container-flex justify-content-between">
            <div>
                <h1 class="d-inline-block">{{ editMode ? ('editSponsorship' | translate) : ('newSponsorship' | translate) }}
                </h1>
            </div>
        </div>
    </header>
    <div class="container p-0">
        <form [formGroup]="formGroup">
            <!-- <div class="py-4" *ngIf="editMode === false"> -->
            <div *ngIf="editMode === false; else showType" class="py-4">
                <label>{{'sponsorshipType' | translate}}</label>
                <mat-button-toggle-group #rulesGroup="matButtonToggleGroup" [value]="typeGroupValue" class="mb-3">
                    <mat-button-toggle (click)="updateTypeGroupValue('small')" aria-label="{{'bottomBar' | translate}}"
                                       value="small">
                        {{'bottomBar' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle (click)="updateTypeGroupValue('large')" aria-label="{{'betweenQuarters' | translate}}"
                                       value="large">
                        {{'betweenQuarters' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <ng-template #showType>
                <div class="py-4">
                    <label class="mb-2">{{'sponsorshipType' | translate}}</label>
                    <div [ngSwitch]="typeGroupValue">
                        <div *ngSwitchCase="'small'">{{'bottomBar' | translate}}</div>
                        <div *ngSwitchCase="'large'">{{'betweenQuarters' | translate}}</div>
                    </div>
                </div>
            </ng-template>
            <div class="pt-4 pb-3">
                <mat-form-field>
                    <input [maxlength]="26" formControlName="title" id="title" matInput
                           placeholder="{{'sponsorTitle' | translate}}">
                </mat-form-field>
                <mat-error *ngIf="formGroup.get('title').dirty && formGroup.get('title').errors?.required" class="test">
                    {{'sponsorshipNameRequired' | translate}}
                </mat-error>
            </div>
            <div class="container-flex py-4">
                <div class="column">
                    <label>{{'sponsorLogo' | translate}}</label>
                    <div [ngSwitch]="typeGroupValue">
                        <p *ngSwitchCase="'small'" [innerHTML]="('uploadLogo230x70' | translate)"></p>
                        <p *ngSwitchCase="'large'" [innerHTML]="('uploadLogo500x500' | translate)"></p>
                    </div>
                    <input #fileInput (change)="onFileChange($event, 'logo')"
                           class="btn-upload d-flex align-items-center justify-content-center my-4" formControlName="logo" id="logo" type="file"/>
                    <label for="logo"><span>{{'uploadLogo' | translate}}</span></label>
                    <mat-progress-bar *ngIf="uploadStatus.logo.status === 'progress'" [value]="uploadStatus.logo.message"
                                      mode="determinate"></mat-progress-bar>
                    <mat-error *ngIf="error">
                        <div *ngIf="uploadStatus.logo.status === 'error'">
                            {{ uploadStatus.logo.message }}
                        </div>
                    </mat-error>
                    <div class="sponsorship-preview my-4">
                        <img *ngIf="uploadStatus.logo.message >= 100 && uploadStatus.logo.filePath"
                             [src]="uploadStatus.logo.filePath | safeHtml"/>
                    </div>
                    <!-- <div *ngIf="testImage"><img [src]="testImage.src | safeHtml"/></div> -->
                    <!-- <img [src]="'data:image/jpg;base64,'+goal.images[0].base64 | safeHtml" /> -->
                </div>
            </div>
            <div class="container py-4">
                <label>{{'displayLocation' | translate}}</label>
                <mat-button-toggle-group #rulesGroup="matButtonToggleGroup" [value]="rulesGroupValue" class="mb-3">
                    <mat-button-toggle (click)="updateRulesGroupValue('area')" aria-label="{{'showByArea' | translate}}"
                                       value="area">
                        {{'showByArea' | translate}}
                    </mat-button-toggle>
                    <mat-button-toggle (click)="updateRulesGroupValue('game')" aria-label="{{'showDuringGame' | translate}}"
                                       value="game">
                        {{'showByGameEvent' | translate}}
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <!--         <p class="mt-0 mb-4" *ngIf="rulesGroup.value === 'area'">Game-specific sponsorships will override area sponsorships</p>
                        <p class="mt-0 mb-4" *ngIf="rulesGroup.value === 'game'">Game-specific sponsorships will override area sponsorships</p> -->

                <ng-container *ngIf="rulesGroup.value === 'area'">
                    <!-- <select formControlName="rules" id="area_rules" placeholder="search for scenario">
                        <option [ngValue]="null">--</option>
                        <option *ngFor="let areaOption of areaOptions" value="{{ areaOption.id }}">{{ areaOption.name }}</option>
                    </select> -->
                    <label class="mb-0">{{'area' | translate}}</label>
                    <div><small>{{'searchForArea' | translate}}</small></div>
                    <mat-form-field appearance="none">
                        <input [formControl]="areaControl"
                               [matAutocomplete]="auto"
                               aria-label=""
                               matInput
                               placeholder=""
                               type="text">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAreaName" autoActiveFirstOption>
                            <mat-option *ngFor="let option of filteredAreaOptions | async" [value]="option">
                                {{ option.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="rulesGroup.value === 'game'">
                    <label class="mb-0">{{'game' | translate}}</label>
                    <div><small>{{'searchForGame' | translate}}</small></div>
                    <mat-form-field appearance="none">
                        <input [formControl]="gameControl"
                               [matAutocomplete]="auto"
                               aria-label=""
                               matInput
                               placeholder=""
                               type="text">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayGameName.bind(this)"
                                          autoActiveFirstOption>
                            <mat-option *ngIf="isLoadingGames" class="is-loading">
                                <mat-spinner diameter="50"></mat-spinner>
                            </mat-option>
                            <ng-container *ngIf="!isLoadingGames">
                                <mat-option *ngFor="let game of filteredGameOptions" [value]="game">
                                    {{ game.name | gameName }}
                                </mat-option>
                            </ng-container>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
            </div>

            <button (click)="createSponsorship()" [disabled]="formGroup.invalid || ensureDisabledCondition()" class="button-primary" mat-button
                    type="submit">{{ editMode ? ('editSponsor' | translate) : ('uploadSponsor' | translate) }}
            </button>

        </form>
    </div>
</ng-container>
