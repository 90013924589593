import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {Globals} from '../globals';
import {TranslateLoader, TranslateService} from '@ngx-translate/core';
import {registerLocaleData} from '@angular/common';


@Injectable()
export class LocalizationService implements TranslateLoader {

    private readonly localizationUrl: string;
    private readonly localizationApiKey: string;
    private readonly localizationCommonApiKey: string;

    constructor(
        private http: HttpClient,
        private globals: Globals,
        public translate: TranslateService,
    ) {
        this.globals = globals || new Globals();
        this.localizationUrl = this.globals.jalocalization_url;
        this.localizationApiKey = this.globals.jalocalization_api_key;
        this.localizationCommonApiKey = this.globals.jalocalization_common_api_key;
    }

    private static loadLocalePackage(lang) {
        /* This needs to use relative path to load locales */
        return import(`../../../node_modules/@angular/common/locales/${lang}.mjs`);
    }

    getBrowserLanguage() {
        return navigator.language;
    }

    queryTranslations(lang, apikey, project): Observable<any> {
        return this.http.get<any>(
            /* we need to send the project dummy query string parameter to fix an issue
             with browser's cache when using back button after loading the unity game */
            `${this.localizationUrl}/api/translation/?culture_name=${lang}&project=${project}`,
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'API_KEY': apikey,
                })
            }
        );
    }

    public getTranslation(lang: string): Observable<any> {
        this.loadLocaleFallback(lang);
        return new Observable((observer: Observer<any>) => {
            this.queryTranslations(lang, this.localizationApiKey, 'frontend').subscribe({
                next: resp => {
                    let translation = resp;
                    this.queryTranslations(lang, this.localizationCommonApiKey, 'common').subscribe({
                        next: respCommon => {
                            translation = Object.assign(respCommon, translation);
                            observer.next(translation);
                            observer.complete();
                        },
                        error: errorCommon => {
                            console.error(errorCommon);
                            observer.error(errorCommon);
                        }
                    });
                },
                error: error => {
                    console.error(error);
                    observer.error(error);
                }
            });
        });
    }

    private loadLocaleFallback(lang: string) {
        LocalizationService.loadLocalePackage(lang).then(pkg => registerLocaleData(pkg.default)).catch(() => {
            if (lang.includes('-')) {
                LocalizationService.loadLocalePackage(lang.split('-')[0]).then(pkg => registerLocaleData(pkg.default)).catch(() => {
                    console.error(`Locale not found for lang: ${lang}, loading 'en' by default`);
                    LocalizationService.loadLocalePackage('en').then(pkg => registerLocaleData(pkg.default));
                });
            } else {
                console.error(`Locale not found for lang: ${lang}, loading 'en' by default`);
                LocalizationService.loadLocalePackage('en').then(pkg => registerLocaleData(pkg.default));
            }
        });

    }
}

