import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { GameServiceLocal } from '../game.service';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import { GameService, CompanyService, ScenarioService, ScenarioEventService, CompanyUserService, UserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Game } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameStatus } from '../game-status.enum';
import { User } from '../../users/user.interface';
import { AuthenticationService } from '../../auth/authentication.service';
import { AlertService } from '../../alert/alert.service';
import { saveAs } from 'file-saver';
import { Observable, interval } from 'rxjs';

export interface EventObject {
  'title': string;
  'quarter': number;
}

@Component({
  selector: 'app-game-detail',
  templateUrl: './game-detail.component.html',
  styleUrls: ['./game-detail.component.scss']
})
export class GameDetailComponent implements OnInit, OnDestroy {

  @Input() currentGame: Game; // when used as child component
  @Input() controls = true;

  detailPollingSubscription;
  subInit = false;

  loading = false;
  downLoading = false;
  quarterLoading = false;
  chartsLoading = false;

  deadline: Date;
  hours = 0;
  minutes = 0;
  seconds = 0;

  game: Game;
  gameId: number;
  states = GameStatus;
  status = '';
  selectedQuarter: number;
  currentQuarter: number;
  quarterStartTime: Date;
  panelOpenState: boolean;
  scenario: any; // temp ScenarioGameOut;
  scenarioEvents: any[]; // temp ScenarioGameOut;
  currentUser: User;
  currentUserId = 0;
  activeCompanies: Company[];
  quarterCompanies: Company[];
  quarterResults: CompanyQuarterResult[];
  userCompanyId: number;
  singlePlayer: boolean;

  startDisabled = false;
  isBracket = false;
  isCompetition = false;
  poolingActiveCompanies: any;
  poolingQuarterCompanies: any;

  csv: Blob;

  constructor(
    private gameService: GameService,
    private scenarioService: ScenarioService,
    private scenarioEventService: ScenarioEventService,
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public  dialog: MatDialog,
    private alertService: AlertService,
    private gameServiceLocal: GameServiceLocal
    ) {}

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.params.subscribe(params => {
      if (params['id'] && !this.currentGame) {
        // if accessed directly, get route parameter as ID and fetch game
        this.gameId = +this.route.snapshot.paramMap.get('id');
        this.getGame(this.gameId);
        this.getUserCompany(this.gameId);

      } else { // else this is embedded game from parent component
        this.game = this.currentGame;
        this.gameId = this.game.id;

        this.getUserCompany(this.game.id);
        this.setGame();
      }
    });

  }

  getGame(id: number) {
    this.loading = true;

    this.gameService.readGameByIdApiGameGameIdGet(id)
      .subscribe(
        game => {
          this.game = game;
          this.loading = false;

          // Begin CSV download
          if ( !this.game.is_active && this.game.is_complete && this.currentUser.role !== 'student' && !game.is_singleplayer ) {
            this.downloadCsvInBackground();
          }

          if ((game as any).bracket_id !== null && (game as any).bracket_id !== undefined) {
            this.isBracket = true;
          }

          if ((game as any).competition_id !== null && (game as any).competition_id !== undefined) {
            this.isCompetition = true;
          }

          // If this is an Automatic Start/Timed/In Progress game, we'll set a timer to update the results
          if ((this.game.start_time || this.game.turn_advancement === 'Timed') && this.controls === true && game.is_complete === false ) {

            // get number of seconds from NOW until next 5 minute increment
            const startDate = new Date();

            const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
            const roundUpTo30Seconds = roundUpTo(1000 * 30);
            const endDate = new Date(roundUpTo30Seconds(new Date()));

            const seconds = (endDate.getTime() - startDate.getTime()) / 1000;

            this.detailPollingSubscription = timer(seconds, 30000)
              .pipe(
                switchMap(() => this.gameService.readGameByIdApiGameGameIdGet(this.game.id),
                )
              ).subscribe(gameUpdate =>  {
                this.subInit = true;

                // if Game is now complete, update game one last time, then remove subscription
                if ( gameUpdate.is_active === false && gameUpdate.is_complete === true ) {
                    this.game = gameUpdate;
                    this.setGame();
                    this.subInit = false;
                    this.detailPollingSubscription.unsubscribe();
                // else if game quarter has updated or game is no longer pending, update game
                } else if (this.game.current_quarter !== gameUpdate.current_quarter || this.game.is_active !== gameUpdate.is_active) {
                  this.game = gameUpdate;
                  this.setGame();
                }
              });
          }

          this.setGame();
        },
        error => this.alertService.error(error)
      );
  }

  private downloadCsvInBackground(): Observable<Blob> {
    if (this.csv) {
      return new Observable<Blob>(subscriber => {
        subscriber.next(this.csv);
      });
    }

    this.downLoading = true;
    const observer = this.gameServiceLocal.downloadCsv(this.game.id);
    observer.subscribe(
        data => {
          this.downLoading = false;
          this.csv = data;
        },
        error => {
          this.downLoading = false;
          this.alertService.error('The results file failed to download. Please try again.');
        }
      );
    return observer;
  }

  setGame() {
    this.getActiveCompanies();
    this.getQuarterCompanies();
    this.getAllCompanyQuarterResults();

    if ( this.game.scenario_id !== undefined && this.game.scenario_id !== null ) {
      this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(this.game.scenario_id)
        .subscribe(
          scenario => {
            this.scenario = scenario;

            this.scenarioEvents = [];

            this.scenario.events.forEach(event => {

              // add all events to a new array to pass down to
              let eventPointer = event.timing.toLowerCase();
              let eventQuarter = 0;

              if (eventPointer !== 'start') {
              eventPointer =  eventPointer + '_scenario_quarter';
                eventQuarter = this.game[eventPointer];
              }

              const eventObject: EventObject = {
                title: event.title,
                quarter: eventQuarter
              };

              this.scenarioEvents.push(eventObject);
            });
          },
          error => this.alertService.error(error)
        );
    }

    if (this.game.start_time) {
      // append trailing 'Z' (Zulu/UTC) because Create Game submission in backend strips it out
      this.game.start_time = new Date(this.game.start_time + 'Z' );
    }

    // Set Game status
    this.setGameState(this.game);
    this.singlePlayer = this.game.is_singleplayer;

    // initialize turn timer
    // eslint-disable-next-line max-len
    if (this.game.turn_advancement === 'Timed' && this.controls === true && this.game.is_active === true && this.game.is_complete === false ) {
      // clock initialization
      this.gameService.readGameQuarterApiGameGameIdQuarterQuarterGet(this.gameId, this.game.current_quarter)
      .subscribe(
        quarter => {
          // append trailing 'Z' (Zulu/UTC) because Create Game submission in backend strips it out
          this.quarterStartTime = new Date(quarter.start_time + 'Z' );

          this.initializeClock();
        }
      );

    }

    this.panelOpenState = true;
  }

  getUserCompany(gameId: number ) {
    // find user's company
    this.companyUserService.getUsersCompanyUsingGameIdApiCompanyUserGameIdCompanyGet(gameId)
    .subscribe(
      userCompany => {
        this.userCompanyId = userCompany.id;
      },
      error => {
        console.error('user company error', error.statusText );
      }
    );
  }

  startGames() {

    // disabled button to prevent a double click
    this.startDisabled = true;

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to immediately start Game ${this.game.id}?`, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.gameService.startGameByIdApiGameGameIdStartPost(this.game.id)
          .subscribe(
            game => {
              this.game = game;
              this.setGame();
            },
            error => {
              if ( error === 'Conflict' || error.status === 409 ) {
                this.startDisabled = false;

                this.alertService.error('The game cannot be started, no companies have joined.');
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });

              }
              console.error(error);
            }

          );
      } else {
        this.startDisabled = false;
      }
    });
  }

  deleteGames() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to delete Game ${this.game.id}?`, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        this.gameService.deleteByGameIdApiGameGameIdDelete(+this.route.snapshot.paramMap.get('id'))
          .subscribe({
            error: err => {
              console.error(err);
            }
          });

          this.router.navigateByUrl('/games');
        }
    });
  }

  setGameState(game: Game) {
    // If Game not 'Active' or 'Complete', set Status to 'Pending'
    this.status = game.is_active === false && game.is_complete === false ? this.states.pending :
    game.is_complete === true ? this.states.complete : this.states.inProgress;

    if ( this.status === this.states.inProgress ) {
      this.selectedQuarter = this.game.current_quarter - 1;
    } else {
      this.selectedQuarter = this.game.current_quarter;
    }

  }

  updateQuarter(selectedQuarter) {
    this.selectedQuarter = selectedQuarter;

    // eslint-disable-next-line max-len
    if (this.game.turn_advancement === 'Timed' && this.controls === true && this.game.is_active === true && this.game.is_complete === false ) {
      this.initializeClock();
    }
  }

  getActiveCompanies() {
    // pull active companies for Active or Complete Games
    if ( this.gameId !== null && this.gameId !== undefined) {
        if (this.game.is_active === false && this.game.is_complete === false) {
          this.readCompaniesForPendingGame();
          if (!this.poolingActiveCompanies) {
            this.poolingActiveCompanies = interval(4000)
                .subscribe(x => this.readCompaniesForPendingGame());
          }
        } else {
        this.gameService.readActiveCompaniesForGameApiGameGameIdActiveCompaniesGet(this.gameId)
        .subscribe(
          results => {
            this.activeCompanies = results;
        },
        error => {
          console.error('no active companies found');
        });
      }
    }
  }

  readCompaniesForPendingGame() {
    if (this.game.is_active === false && this.game.is_complete === false) {
      this.gameService.readCompaniesForGameApiGameGameIdCompaniesGet(this.gameId)
          .subscribe(
              results => {
                this.activeCompanies = results;
              },
              error => {
                console.error('no companies found');
              });
    }
  }

  getQuarterCompanies() {
    // pull submitted companies for in progress Games
    this.quarterCompanies = [];
    if (!this.poolingQuarterCompanies) {
      this.poolingQuarterCompanies = interval(4000)
          .subscribe(x => this.readCompaniesForCurrentQuarter());
    }
  }

  readCompaniesForCurrentQuarter() {
    if ( this.game && this.game.is_active === true && this.game.is_complete === false ) {
      const quarter = this.game.current_quarter >= 0 ? this.game.current_quarter : 0;
      this.gameService.readCompaniesForQuarterApiGameGameIdQuarterQuarterCompaniesGet(this.game.id, quarter)
          .subscribe(
              results => {
                this.quarterCompanies = results;
              },
              error => {
                console.error('no companies found');
              });
    }
  }

  getAllCompanyQuarterResults() {
    this.chartsLoading = true;

    if ( this.gameId !== null && this.gameId !== undefined ) {
      this.gameService.readGameCompanyQuartersResultsApiGameGameIdQuartersCompanyResultsGet(this.gameId)
          .subscribe(
            results => {
              this.quarterResults = results;
              this.chartsLoading = false;
            }
          );
      }
  }

  endCurrentQuarter() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to end the current Quarter?`, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === true) {
        this.quarterLoading = true;
        this.quarterCompanies = null;

        this.gameService.advanceGameQuarterApiGameGameIdAdvanceQuarterPost(this.game.id)
        .subscribe(
          game => {
            this.game = game;
            this.setGameState(this.game);
            this.quarterLoading = false;
            this.setGame();
          },
          error => {
            console.error('Game quarter was not advanced');
          }
        );
        // TODO refresh game
      }
    });
  }

  revertQuarter() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to revert to Quarter?`, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // TODO refresh game
      }
    });
  }

  openDialog(msg: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: { message: msg, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      return result;
    });
  }

  getTimeRemaining(deadline) {
    if (deadline !== undefined) {
      const total = Date.parse(deadline.toString()) - Date.parse(new Date().toString());
      this.seconds = Math.floor((total / 1000) % 60);
      this.minutes = Math.floor((total / 1000 / 60) % 60);
      this.hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    }
  }

  initializeClock() {
    const endtime = this.game.quarter_duration; // set turn expiration time

    if (this.quarterStartTime !== undefined) {
      const currentTime = Date.parse(this.quarterStartTime.toString());
      const deadline = new Date(currentTime + endtime * 60 * 1000);
      const timeinterval = setInterval(() =>
        this.getTimeRemaining(deadline), 1000);
    }
  }

  downloadFile() {
      this.downloadCsvInBackground().subscribe(() => {
        saveAs(this.csv, `jatitan_results_game_${this.game.id}.csv`);
      });
  }

  ngOnDestroy() {
    if (this.poolingActiveCompanies) {
      this.poolingActiveCompanies.unsubscribe();
    }
    if (this.poolingQuarterCompanies) {
      this.poolingQuarterCompanies.unsubscribe();
    }

    // unsubscribe if the first interval has passed
    if (this.detailPollingSubscription !== undefined && this.subInit === true) {
      this.detailPollingSubscription.unsubscribe();
    } else if ( this.detailPollingSubscription === undefined && this.subInit === true) {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
    }
  }

}
