import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
// eslint-disable-next-line max-len
import {Scenario} from '../economic-model.interface';
import {ScenarioService} from '../economic-model.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'app-economic-model',
    templateUrl: './economic-model-list.component.html',
    styleUrls: ['./economic-model-list.component.scss']
})

export class EconomicModelListComponent implements OnInit {

    model: Scenario;
    scenarios: [{ model }] = null;
    actionControl = new UntypedFormControl();

    @ViewChild('scenariosTable') table: MatTable<any>;

    displayedColumns: string[] = ['index', 'title', 'description', 'image_url', 'starting_cash', 'duration'];
    dataSource = new MatTableDataSource(this.scenarios);

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private router: Router,
        private scenarioService: ScenarioService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getScenarios();

        this.router.events.subscribe(() => {
            // this.getScenarios();

            if (this.table) {
                // Re-render table and deselect all rows
                this.table.renderRows();
            }
        });

        this.dataSource.sort = this.sort;
    }

    getScenarios() {
        this.scenarioService.getScenarios().subscribe((response) => {
            this.dataSource.data = response;
        });
    }

}
