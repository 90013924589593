<div class="list-box row my-4">
    <div *ngIf="loading" class="d-flex justify-content-center h-100">
        <mat-spinner></mat-spinner>
    </div>

    <table [dataSource]="dataSource" [ngClass]="{'d-none': loading}" mat-table matSort matSortActive="profit"
           matSortDirection="desc">

        <ng-container matColumnDef="rank">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'rank' | translate}}</th>
            <td *matCellDef="let company" mat-cell>{{ company.rank + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="company">
            <th *matHeaderCellDef mat-header-cell>{{'name' | translate}}</th>
            <td *matCellDef="let company" mat-cell>{{company.name}}</td>
        </ng-container>

        <ng-container matColumnDef="companyLink">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let company" mat-cell><a *ngIf="company.linkState" [routerLink]="[ '/company', company.id, gameId]"
                                                      class="w-100 text-right d-block px-3"><img
                    alt="View Company Results" class="mt-2" src="../../../assets/images/file-chart-line-solid.svg"
                    width="16px"/></a></td>
        </ng-container>

        <ng-container matColumnDef="revenue">
            <th *matHeaderCellDef mat-header-cell>{{'revenue' | translate}}</th>
            <td *matCellDef="let company"
                mat-cell> {{company.revenue === 0 ? '$0' : company.revenue | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="expenses">
            <th *matHeaderCellDef mat-header-cell>{{'expenses' | translate}}</th>
            <td *matCellDef="let company"
                mat-cell>{{company.expenses + company.cost_of_goods_sold + company.taxes === 0 ? '$0' : company.expenses + company.cost_of_goods_sold + company.taxes | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="profit">
            <th *matHeaderCellDef mat-header-cell>{{'netProfit' | translate}}</th>
            <td *matCellDef="let company"
                mat-cell>{{company.net_profit === 0 ? '$0' : company.net_profit | currency:'USD':'symbol':'4.0-0' }}
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'linkStateActive': row.linkState}"
            mat-row></tr>

    </table>
</div>