export const environment = {
  production: true,
  name: 'JA Titan',
  backendUrl: 'https://backend.jatitan.org',
  gameUrl: 'https://game.jatitan.org',
  apiKey: 'Shg9hmmPCiBWlLQ95cKk9ZsgJcSVvD',
  jalocalizationUrl: 'https://localization.backend.jatitan.org',
  jalocalizationApiKey: '7jzwpLn8VMWpzn83yu1x7XGisUl7C89eBIBMlgUGbm6D7b0tGdc8Yl02NEW4yAMz',
  jalocalizationCommonApiKey: 'TpZHsNuDrbp9uWqGSMERLPtqpAmMel117QuxZJUCHmI4SuPDeNE7RgaFcPjRwpow',
};
