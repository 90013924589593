import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {CompetitionBaseInDB, CompetitionService, UserService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {User} from '../../users/user.interface';
import {GameStatus} from '../../games/game-status.enum';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {AuthenticationService} from '../../auth/authentication.service';
import {interval} from 'rxjs';

@Component({
    selector: 'app-competitions-list',
    templateUrl: './competitions-list.component.html',
    styleUrls: ['./competitions-list.component.scss']
})
export class CompetitionsListComponent implements OnInit, OnDestroy {

    @ViewChild('gameTable') table: MatTable<any>

    loading = false;

    initSubscription;
    pollingSubscription;

    competitions: [CompetitionBaseInDB] = null;
    currentUser: User;
    currentUserId = 0;
    states = GameStatus;

    @Input() controls = true;

    displayedColumns: string[] = ['id', 'name', 'status', 'current_round'];
    dataSource = new MatTableDataSource(this.competitions);
    selection = new SelectionModel(false, []);

    @ViewChild(MatSort, {static: true}) sort: MatSort;

    // MatPaginator
    pageSizeOptions: number[] = [5, 10, 25, 50];
    pageTotal: number;
    pageIndex = 0;
    pageSize = 10;

    constructor(
        private userService: UserService,
        private competitionService: CompetitionService,
        private authenticationService: AuthenticationService
    ) {
    }

    /** Whether there are any number of selected elements. */
    isAnySelected() {
        const numSelected = this.selection.selected.length;
        return numSelected > 0;
    }

    /** ARIA Accessibility: change label for the checkbox on the passed row */
    checkboxLabel(row?: CompetitionBaseInDB, i?: number): string {
        if (!row) {
            return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
    }

    ngOnInit(): void {
        this.currentUser = this.authenticationService.currentUserValue;
        this.dataSource.sort = this.sort;
        this.initSubscription = this.userService.readUserMeApiUserMeGet();

        this.initSubscription.subscribe(
            current_user => {
                this.currentUserId = current_user.id;
                this.getCompetitions();

                if (this.currentUserId !== 0) {
                    this.pollingSubscription = interval(30000)
                        .subscribe(() => {
                            this.getCompetitions()
                        });
                }
            }
        );
    }

    getCompetitions() {
        this.loading = true;

        if (this.currentUserId !== 0) {
            this.competitionService.getAllCompetitionsApiCompetitionGet(this.pageIndex, this.pageSize).subscribe({
                next: results => {
                    results.items.forEach(competition => {
                        (competition as any).status = competition.is_complete ?
                            this.states.complete : competition.is_in_progress ?
                                this.states.inProgress : this.states.pending;
                    });
                    this.setPage(results);
                    this.loading = false;
                },
                error: () => {
                    console.log('no users found');
                }
            });
        }
    }

    updatePage(event) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.getCompetitions();
    }

    setPage(results) {
        this.dataSource.data = results.items;
        this.pageTotal = results.total;
        this.pageSize = results.size;
        this.pageIndex = results.page;
    }

    ngOnDestroy() {
        if (this.pollingSubscription !== undefined) {
            this.pollingSubscription.unsubscribe();
        } else {
            this.authenticationService.logout();
        }
    }
}
