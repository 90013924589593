import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

@Injectable()
export class Globals {
    configUrl = 'https://swapi.co/api/';
    localUrl = 'http://localhost:4200';
    backendUrl = environment.backendUrl;
    gameUrl = environment.gameUrl;
    api_key = environment.apiKey;
    jalocalization_url = environment.jalocalizationUrl;
    jalocalization_api_key = environment.jalocalizationApiKey;
    jalocalization_common_api_key = environment.jalocalizationCommonApiKey;
    authServer = null;
    authClientId = null;
    authResponseType = null;
    authScope = null;
}
