import {AfterContentChecked, ChangeDetectorRef, Component} from '@angular/core';
import {BaseGameCreateComponent} from '../../common/base.game-create.component';
import {UntypedFormBuilder} from '@angular/forms';
import {CompetitionService, ScenarioService, UserService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-competition-create',
    templateUrl: './competition-create.component.html',
    styleUrls: ['./competition-create.component.scss']
})
export class CompetitionCreateComponent extends BaseGameCreateComponent implements AfterContentChecked {

    readonly roundCountOptions = [{value: 2}, {value: 3}, {value: 4}, {value: 5}, {value: 6}, {value: 7}, {value: 8}, {value: 9}, {value: 10}];

    readonly maxCompaniesPerGameOptions = [
        {value: 2}, {value: 3}, {value: 4}, {value: 5}, {value: 6}, {value: 7}, {value: 8}, {value: 9}, {value: 10}, {value: 11}, {value: 12},
        {value: 13}, {value: 14}, {value: 15}, {value: 16}, {value: 17}, {value: 18}, {value: 19}, {value: 20}, {value: 21}, {value: 22},
        {value: 23}, {value: 24}, {value: 25}, {value: 26}, {value: 27}, {value: 28}, {value: 29}, {value: 30}, {value: 31}, {value: 32}
    ];

    constructor(
        protected _formBuilder: UntypedFormBuilder,
        protected userService: UserService,
        protected scenarioService: ScenarioService,
        private competitionService: CompetitionService,
        private router: Router,
        protected route: ActivatedRoute,
        protected datePipe: DatePipe,
        protected dialog: MatDialog,
        protected cdref: ChangeDetectorRef,
        protected readonly translateService: TranslateService
    ) {
        super(_formBuilder, scenarioService, userService, route, dialog, translateService, cdref, datePipe);
    }

    getNumberOfCompanies() {
        let companyCount = this.getAllSelectedUsers()?.length;
        if (this.teamGroup?.value === 'teams') {
            companyCount = this.teamArray.length ?? 0;
        }
        return companyCount;
    }

    getMaxCompaniesPerGame() {
        return this.formArray['controls'][0].get('max_game_company_count').value;
    }

    getNumberOfGames() {
        const companyCount = this.getNumberOfCompanies();
        const maxCompaniesPerGame = this.getMaxCompaniesPerGame();
        return Math.ceil(companyCount / maxCompaniesPerGame);
    }

    getNumberOfFullGames() {
        const gameCount = this.getNumberOfGames();
        if (gameCount === 0) return 0;
        const companyCount = this.getNumberOfCompanies();
        const gameMod = companyCount % gameCount;
        return gameMod === 0 ? this.getNumberOfGames() : gameMod;
    }

    getNumberOfNotFullGames() {
        const gameCount = this.getNumberOfGames();
        if (gameCount === 0) return 0;
        const companyCount = this.getNumberOfCompanies();
        const gameMod = companyCount % gameCount;
        return gameMod === 0 ? 0 : this.getNumberOfGames() - gameMod;
    }

    getCompanyCountForFullGames() {
        const gameCount = this.getNumberOfGames();
        if (gameCount === 0) return 0;
        const companyCount = this.getNumberOfCompanies();
        return Math.ceil(companyCount / gameCount);
    }

    loadEntityFromId(competitionId: number) {
        // no implementation
    }

    createEntity(competitionCreate?: any) {
        const groups = [];

        this.teamArray.forEach(team => {
            const teamObject = {users: []};
            teamObject.users = team;
            groups.push(teamObject);
        });

        competitionCreate.groups = groups;

        competitionCreate.time_between_rounds = competitionCreate.round_duration;

        this.competitionService.createCompetitionApiCompetitionPost(competitionCreate)
            .subscribe(
                async () => {
                    await this.router.navigate(['/competitions/']);
                }
            );
    }

}
