<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1 class="d-inline-block">{{ bracket?.name }}
                - {{ 'round' | translate }} {{ bracket?.current_round }}</h1>
            <h3 class="role-status d-inline-block">({{ status | translate }})</h3>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="gameSettings?.turn_advancement === 'Manual' || !gameSettings?.start_time" class="row">
    <h2>{{ 'manualRoundControls' | translate }}</h2>
    <div class="container-flex align-items-center">
        <button (click)="startRound()" *ngIf="!gameSettings?.start_time && isRoundPending" [disabled]="startDisabled"
                class="button-primary">{{ 'startRound' | translate }}</button>
        <button (click)="endCurrentRound()" *ngIf="gameSettings?.turn_advancement === 'Manual' && isRoundLockedActive && !isRoundLockedComplete && (gameSettings?.current_quarter + 1) <= gameSettings?.duration"
                [disabled]="roundLoading"
                class="button-primary">{{ 'endQuarterNum' | translate:{quarter: gameSettings?.current_quarter + 1} }}
            <mat-spinner *ngIf="roundLoading"></mat-spinner>
        </button>
    </div>
</div>

<ng-container *ngIf="!isRoundLockedActive">

    <form [formGroup]="formGroup">
        <h2 class="has-tooltip">{{ 'roundSettings' | translate}}
            <button #tooltip="matTooltip" attr.aria-label="{{ 'gameSettingsButton' | translate }}"
                    mat-raised-button
                    matTooltip="{{ 'customGameDescription' | translate }} {{ 'scenarioGameDescription' | translate }}">
                ?
            </button>
        </h2>

        <div class="mb-4">
            <mat-button-toggle-group #gameTypeValue [(value)]="gameType" attr.aria-label="{{ 'gameType' | translate }}"
                                     class="mb-2">
                <mat-button-toggle (change)="gameTypeChanged($event)" *ngFor="let gameTypeOption of gameTypeOptions;"
                                   [aria-label]="gameTypeOption.label"
                                   [value]="gameTypeOption.value">{{ gameTypeOption.label | translate }}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <ng-container *ngIf="gameType === 'custom'">
            <label>{{ 'cashOnHand' | translate }}</label>
            <div class="button-group button-group-auto label-difficulty mb-5">
                <div *ngFor="let cashOption of cashOptions;">
                    <input class="radio-custom" formControlName="starting_cash" id="cash-{{ cashOption.name }}"
                           type="radio"
                           value="{{ cashOption.value }}">
                    <label class="radio-custom-label" for="cash-{{ cashOption.name }}">{{ cashOption.name}}</label>
                </div>
            </div>
            <label>{{ 'duration' | translate }}</label>
            <div class="button-group button-group-auto label-difficulty mb-5">
                <div *ngFor="let durationOption of durationOptions;">
                    <input class="radio-custom" formControlName="duration" id="duration-{{ durationOption.value }}"
                           type="radio"
                           value="{{ durationOption.value }}">
                    <label class="radio-custom-label" for="duration-{{ durationOption.value }}">
                        {{ durationOption.value}} {{ 'quarters' | translate }}<br>
                        ({{ durationOption.value / 4 }} {{ (durationOption.value / 4) > 1 ? ('years' | translate) : ('year' | translate) }}
                        )
                    </label>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="gameType === 'scenario'">
            <label class="has-tooltip" for="scenarios">{{ 'scenarioGameSettingsButton' | translate }}
                <button #tooltip="matTooltip" attr.aria-label="{{ 'buttonDisplaysTooltip' | translate }}"
                        mat-raised-button
                        matTooltip="{{ 'scenarioGameSettingsButtonTooltip' | translate }}">
                    ?
                </button>
            </label>
            <select (change)="updateScenario($event.target)" attr.placeholder="{{ 'searchForScenario' | translate }}" formControlName="scenario_id"
                    id="scenarios">
                <option [ngValue]="null">{{ 'selectScenario' | translate }}</option>
                <option *ngFor="let scenarioOption of scenarioOptions" [attr.data-scenario]="scenarioOption | json"
                        [value]="scenarioOption.id">{{ scenarioOption.title | translate}}</option>
            </select>
            <mat-error *ngIf="formGroup.get('scenario_id').dirty && formGroup.get('scenario_id').value === null && gameType === 'scenario'"
                       class="mb-5">
                {{ 'scenarioIsRequired' | translate }}
            </mat-error>
        </ng-container>

        <button (click)="updateRound()" [disabled]="updateDisabled" class="button-primary" mat-button type="submit">
            {{ 'saveChanges' | translate }}
        </button>

    </form>
</ng-container>

<ng-container *ngIf="isRoundLockedActive && loading === false">
    <div class="flex-container empty-container">
        <h2>{{ 'roundSettings' | translate }}</h2>
        <div *ngIf="isRoundLockedActive" class="p-5 text-center">
            <p>{{ 'currentRoundIsInProgressAlert' | translate }}</p>
        </div>
        <div *ngIf="isRoundLockedComplete" class="p-5 text-center">
            <p>{{ 'currentRoundCompleteAlert' | translate }}</p>
        </div>
    </div>


    <div class="container">
        <div class="row">
            <button [routerLink]="['/brackets/detail/', bracket.id ]"
                    class="button-primary">{{'backToBracket' | translate }}</button>
        </div>
    </div>
</ng-container>

