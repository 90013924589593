<div class="list-box row my-4">
    <mat-spinner *ngIf="loading"></mat-spinner>

    <table #resultTable [dataSource]="dataSource" [ngClass]="{'d-none': loading}" mat-table matSort
           matSortActive="id" matSortDirection="asc">

        <!--         <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef > Rank </th>
                    <td mat-cell *matCellDef="let element; let i = index;">{{i}}</td>
                </ng-container> -->

        <ng-container matColumnDef="quarter">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'quarter' | translate }}</th>
            <td *matCellDef="let quarter" mat-cell> {{ quarter.quarter + 1 }} </td>
        </ng-container>

        <ng-container matColumnDef="revenue">
            <th *matHeaderCellDef mat-header-cell>{{ 'revenue' | translate }}</th>
            <td *matCellDef="let quarter" mat-cell> {{quarter.revenue | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="expenses">
            <th *matHeaderCellDef mat-header-cell>{{ 'expenses' | translate }}</th>
            <td *matCellDef="let quarter"
                mat-cell> {{quarter.expenses + quarter.cost_of_goods_sold + quarter.taxes | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="profit">
            <th *matHeaderCellDef mat-header-cell>{{ 'netProfit' | translate }}</th>
            <td *matCellDef="let quarter" mat-cell> {{quarter.net_profit | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>