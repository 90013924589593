<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1>{{'users' | translate}}</h1>
        </div>
    </div>
</header>
<div class="row">
    <table [dataSource]="dataSource" mat-table matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [aria-label]="checkboxLabel()"
                              [checked]="selection.hasValue() && isAnySelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td *matCellDef="let row" mat-cell>
                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                              (click)="$event.stopPropagation()"
                              [aria-label]="checkboxLabel(row)"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="index">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'index' | translate}}</th>
            <td *matCellDef="let element; let i = index;" mat-cell>{{i}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'name' | translate}}</th>
            <td *matCellDef="let user" mat-cell> {{user.name}} </td>
        </ng-container>

        <ng-container matColumnDef="height">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'height' | translate}}</th>
            <td *matCellDef="let user" mat-cell> {{user.height}} </td>
        </ng-container>

        <ng-container matColumnDef="mass">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'mass' | translate}}</th>
            <td *matCellDef="let user" mat-cell> {{user.mass}} </td>
        </ng-container>

        <ng-container matColumnDef="hair_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'hairColor' | translate}}</th>
            <td *matCellDef="let user" mat-cell> {{user.hair_color}} </td>
        </ng-container>

        <ng-container matColumnDef="eye_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'eyeColor' | translate}}</th>
            <td *matCellDef="let user" mat-cell> {{user.eye_color}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>