import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-sponsorships',
    templateUrl: './sponsorships.component.html',
    styleUrls: ['./sponsorships.component.scss']
})
export class SponsorshipsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}

