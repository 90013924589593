<div *ngIf="loading" class="d-flex justify-content-center h-100">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!loading">
    <header>
        <div class="container container-flex flex-wrap justify-content-between p-0">
            <div class="w-100">
                <h1 class="d-inline-block">
                    {{ game?.name | gameName }}</h1>
                <div class="d-flex">
                    <h3 class="role-status">({{ status | translate }})</h3>
                    <!-- CSV download button -->
                    <!-- <ng-container *ngIf="status === states.complete && game.is_singleplayer === false && currentUser.role !== 'student'"> -->
                    <ng-container
                            *ngIf="status !== states.pending && game.is_singleplayer === false && currentUser.role !== 'student'">
                        <div class="csv-loader ml-3 d-flex align-items-center">
                            <ng-container *ngIf="!downLoading">
                                <button (click)="downloadFile()"
                                        class="btn button-link button-icon mr-2 d-flex align-items-center">
                                    <img alt="Download Results as CSV"
                                         class="mr-2" src="../../../assets/images/file-download-solid.svg"
                                         width="16px"/>
                                    <span>{{ 'downloadResultsAsCSV' | translate }}</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="downLoading">
                                <mat-spinner [diameter]="25" style="display: inline-block !important"></mat-spinner>
                                <span class="text-flashing ml-2">{{ 'loadingCSVResultsAlert' | translate }}</span>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="container-flex align-items-center" *ngIf="controls === true && currentUser.role !== 'student'">
                <button class="button-primary button-warning"  *ngIf="status === states.inProgress">Revert One Quarter</button>
            </div> -->
            <div *ngIf="status === states.inProgress" class="quarter-box text-right w-100">
                <span class="quarter-current">{{ 'quarterNum' | translate: {quarter: game?.current_quarter + 1} }} </span>
                <span>{{ 'ofDuration' | translate: {game_duration: game?.duration} }} </span>
                <ng-container *ngIf="game?.turn_advancement === 'Timed' && controls === true">
                    <div *ngIf="hours >= 0 && minutes >= 0 && seconds >= 0; else ended">
                        <strong>
                            <span>{{'endsIn' | translate }}&nbsp;</span>
                            <span *ngIf="hours > 0" class="hours"> {{ hours }} {{'hours' | translate}},&nbsp;</span>
                            <span *ngIf="minutes > 0" class="minutes"> {{ minutes }} {{'minutes' | translate}}
                                ,&nbsp;</span>
                            <span *ngIf="seconds >= 0" class="seconds"> {{ seconds }} {{'seconds' | translate}} </span>
                        </strong>
                    </div>
                    <ng-template #ended>
                        <div>
                            <span class="quarter-ended">{{'ended' | translate}}</span>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

        </div>
    </header>

    <div *ngIf="controls; else gameButton" class="container">
        <div *ngIf="!isBracket && !isCompetition" class="row">
            <button [routerLink]="['/games']" class="button-primary">{{'backToGames' | translate}}</button>
        </div>
        <div *ngIf="game?.bracket_id !== null" class="row">
            <button [routerLink]="['/brackets/detail', game?.bracket_id]"
                    class="button-primary">{{'backToBracket' | translate}}</button>
        </div>
        <div *ngIf="game?.competition_id !== null" class="row">
            <button [routerLink]="['/competitions/detail', game?.competition_id]"
                    class="button-primary">{{'backToCompetition' | translate}}</button>
        </div>
    </div>
    <ng-template #gameButton>
        <div class="container my-3">
            <div class="row">
                <button [routerLink]="['/games/detail', game?.id]"
                        class="button-primary mb-0">{{'viewGameDetail' | translate }}</button>
            </div>
        </div>
    </ng-template>

    <ng-container *ngIf="controls === true">
        <div class="container my-4">
            <mat-expansion-panel [expanded]="true" class="game-settings p-0">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{'startingConditions' | translate}}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="container-flex flex-wrap justify-content-around">
                    <div class="flex-fill px-3">
                        <div class="data-header">{{ game?.starting_cash | currency:'USD':'symbol':'4.0-0' }}</div>
                        <div class="data-content">{{'cashOnHand' | translate}}</div>
                    </div>
                    <div class="flex-fill px-3">
                        <div class="data-header">{{ game?.duration }} {{'quarters' | translate}}</div>
                        <div class="data-content">
                            ({{ game?.duration / 4 }} {{ (game?.duration / 4) > 1 ? ('years' | translate) : ('year' | translate) }}
                            )
                        </div>
                    </div>
                    <div *ngIf="game?.scenario_id !== null" class="flex-fill px-3">
                        <div class="data-header">{{ scenario?.title | translate }}</div>
                        <div class="data-content">{{'scenario' | translate}}</div>
                    </div>
                    <div class="flex-fill px-3">
                        <div class="data-header">{{ game?.turn_advancement.toLowerCase() | translate }}</div>
                        <div class="data-content">{{'turnCompletion' | translate}}</div>
                    </div>
                    <div *ngIf="status === states.pending  && !!game?.start_time" class="flex-fill px-3">
                        <div class="data-header">{{ game?.start_time | date:'shortTime' }}</div>
                        <div class="data-content">{{ game?.start_time | date }}</div>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>

        <div *ngIf="currentUser.role !== 'student' && !isBracket && !isCompetition"
             class="container d-flex justify-content-between flex-wrap py-0 my-0">
            <button (click)="endCurrentQuarter()" *ngIf="status === states.inProgress && game?.turn_advancement === 'Manual' && game?.current_quarter !== game?.duration"
                    [disabled]="quarterLoading"
                    class="button-primary">{{'endQuarterNum' | translate:{quarter: game?.current_quarter + 1} }}
                <mat-spinner *ngIf="quarterLoading"></mat-spinner>
            </button>
            <button (click)="startGames()" *ngIf="status === states.pending && !game?.start_time"
                    class="button-primary">{{'startGame' | translate}}
            </button>
            <button *ngIf="status === states.pending && game.is_singleplayer !== true" [routerLink]="['/games/edit', game?.id]"
                    class="button-primary button-secondary">{{'editGame' | translate}}
            </button>
            <button *ngIf="status === states.pending && game.is_singleplayer !== true" [routerLink]="['/games/create', game?.id]"
                    class="button-primary button-secondary">{{'duplicateGame' | translate}}
            </button>
            <button (click)="deleteGames()" *ngIf="status === states.pending && game.is_singleplayer !== true"
                    class="button-primary button-warning"><span
                    class="button-icon-inside">!</span> {{'deleteGame' | translate}}
            </button>
        </div>

    </ng-container>

    <ng-container *ngIf="status === states.complete && activeCompanies">

        <app-game-detail-results [activeCompaniesInput]="activeCompanies" [gameId]="gameId"
                                 [userCompanyId]="userCompanyId"></app-game-detail-results>

    </ng-container>


    <ng-container *ngIf="
    (currentUser.role === 'student' && status === states.complete) ||
    currentUser.role === 'teacher' ||
    currentUser.role === 'area_admin' ||
    currentUser.role === 'national_admin' ||
    currentUser.role === 'superuser'
    ">

        <div *ngIf="status === states.pending && activeCompanies !== undefined" class="container team-box my-0">
            <app-game-detail-companies [activeCompaniesInput]="activeCompanies"
                                       [status]="status"></app-game-detail-companies>
        </div>

        <div *ngIf="status === states.inProgress && quarterCompanies !== undefined" class="container team-box my-0">
            <app-game-detail-companies [activeCompaniesInput]="quarterCompanies"
                                       [status]="status"></app-game-detail-companies>
        </div>

        <div *ngIf="status !== states.pending && game?.current_quarter > 0" class="container team-box my-0">

            <div (change)="updateQuarter($event.target.value)"
                 *ngIf="controls === true" class="quarter-box quarter-select-box container-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <select [value]="selectedQuarter" class="quarter-current" id="" name="drop-down">
                        <option *ngFor="let dummy of ' '.repeat(game?.current_quarter).split(''), let x = index"
                                value="{{ x }}">
                            {{'quarterNum' | translate: {quarter: x + 1} }}</option>
                        <option *ngIf="status === states.complete" value="{{ game?.current_quarter }}">
                            {{'quarterNum' | translate: {quarter: game?.current_quarter + 1} }}</option>
                    </select>
                    <span>{{ 'ofDuration' | translate: {game_duration: game?.duration} }}&nbsp;</span>
                </div>
            </div>
            <app-game-detail-quarter [activeCompaniesInput]="activeCompanies" [gameId]="game?.id" [quarter]="selectedQuarter"
                                     [singlePlayer]="singlePlayer"
                                     [userCompanyId]="userCompanyId"></app-game-detail-quarter>
        </div>

    </ng-container>

    <ng-container *ngIf="
    (currentUser.role === 'student' && status === states.complete) ||
    currentUser.role === 'teacher' ||
    currentUser.role === 'area_admin' ||
    currentUser.role === 'national_admin' ||
    currentUser.role === 'superuser'
    ">

        <ng-container *ngIf="game?.current_quarter > 0 && controls === true">
            <ng-container *ngIf="!chartsLoading; else loader">
                <app-game-detail-graph [activeCompaniesInput]="activeCompanies" [category]="'revenue'" [game]="game"
                                       [resultsInput]="quarterResults" [scenarioEvents]="scenarioEvents"
                                       [singleView]="false"
                                       [topCompany]="true" [userCompanyId]="userCompanyId"></app-game-detail-graph>
            </ng-container>
            <ng-template #loader>
                <div class="d-flex justify-content-center h-100 my-5">
                    <mat-spinner></mat-spinner>
                </div>
            </ng-template>
        </ng-container>

    </ng-container>

</ng-container>
