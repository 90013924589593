import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from './../../shared/dialog/dialog.component';

// import { GameService } from '../game.service';
// import { Game } from './../game.interface';


import { GameService, CompanyService, ScenarioService, CompanyUserService, CompanyQuarterResult, AuthService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Game, Company, ScenarioGameOut } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';

import { User } from './../../users/user.interface';
import { GameStatus } from '../../games/game-status.enum';
import { AuthenticationService } from '../../auth/authentication.service';


@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {

  loading = false;

  game: Game;
  gameId: number;
  company: Company;
  categories: [];
  currentQuarter: number;
  states = GameStatus;
  status = '';
  scenario: any; // TEMP ScenarioGameOut
  panelOpenState: boolean;
  companyUsers: any[];
  currentUser: User;
  currentUserId = 0;

  chartsLoading = false;
  activeCompanies: Company[];
  quarterResults: CompanyQuarterResult[];


  @Input() controls = true;

  constructor(
    private gameService: GameService,
    private scenarioService: ScenarioService,
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public dialog: MatDialog
    ) { }

  ngOnInit() {
      this.currentUser = this.authenticationService.currentUserValue;

      this.loading = true;

      this.gameId = +this.route.snapshot.paramMap.get('gameId');
      const companyId = +this.route.snapshot.paramMap.get('companyId');

      this.getActiveCompanies();
      this.getAllCompanyQuarterResults();

      // get Game Info
      this.gameService.readGameByIdApiGameGameIdGet(this.gameId)
      .subscribe(
        game => {
          this.game = game;

          if ( this.game.scenario_id !== undefined && this.game.scenario_id !== null ) {
            this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(this.game.scenario_id)
              .subscribe(
                scenario => {
                  this.scenario = scenario;
                  console.log(scenario);
                }
              );
          }

          // append trailing 'Z' (Zulu/UTC) because Create Game submission in backend strips it out
          (this.game as any).start_time = new Date(( this.game as any).start_time + 'Z' );

          // Set Game status
          // If Game not 'Active' or 'Complete', set Status to 'Pending'
          this.status = this.game.is_active === false && this.game.is_complete === false ? this.states.pending :
            this.game.is_complete === true ? this.states.complete : this.states.inProgress;

          console.log(this.game);
        },
        error => {
          console.log(error.error);
        }
      );

      // get Company Users
      this.companyService.readCompanyByIdApiCompanyCompanyIdGet(companyId)
      .subscribe(
        company => {
          this.company = company;
          console.log(this.company);
          this.loading = false;

          if (this.currentUser.role !== 'student') {
          this.companyUserService.getCompanyUsersApiCompanyUserCompanyIdUsersGet(companyId)
            .subscribe(
              companyUsers => {
                console.log('companyUsers', companyUsers);
                this.companyUsers = companyUsers;
              },
              error => {
                console.log(error.error);
              }
            );
          }

        },
        error => {
          console.log(error.error);
        }
      );
  }

  getAllCompanyQuarterResults() {
    this.chartsLoading = true;

    if ( this.gameId !== null && this.gameId !== undefined ) {
      this.gameService.readGameCompanyQuartersResultsApiGameGameIdQuartersCompanyResultsGet(this.gameId)
          .subscribe(
            results => {

              this.quarterResults = results;

              this.chartsLoading = false;

            }
          );
      }
  }

  getActiveCompanies() {

    this.gameService.readActiveCompaniesForGameApiGameGameIdActiveCompaniesGet(this.gameId)
      .subscribe(
        results => {
          console.log('activeCompanies', results);
          this.activeCompanies = results;
      },
      error => {
        console.log('no companies found');
      });
    }

}
