import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../auth/authentication.service';
import {Globals} from '../../globals';
import {AlertService} from '../../alert/alert.service';
import {TranslateService} from '@ngx-translate/core';
import pkceChallenge from "./pkce-challange";

@Component({
    selector: 'app-login',
    templateUrl: './jaid-login.component.html',
    styleUrls: ['./jaid-login.component.scss']
})
export class JaidLoginComponent implements OnInit {

    loading = false;
    submitted = false;
    returnUrl: string;
    error = null;
    // JASA
    expiresIn = null;
    idToken = null;
    accessToken = null;
    scope = null;
    state = null;
    sessionState = null;
    code: string = null;

    currentMessage: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private http: HttpClient,
        private globals: Globals,
        private readonly translateService: TranslateService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }

        router.events.subscribe(() => {
            if (this.router.getCurrentNavigation().extras.state !== undefined) {
                this.currentMessage = this.router.getCurrentNavigation().extras.state.detail;
                this.alertService.error(this.currentMessage, true);
            }

        });
    }

    ngOnInit() {
        this.authenticationService.getJAIDServerInfo().subscribe(() => {
            // Get return url from route parameters or default to '/'
            const authState = localStorage.getItem('authState');
            let url = this.router.url;
            let manualQueryParameters = new Map(url.substring(url.search('#') + 1).split('&').map(value => (value.split('='))).map(value => [value[0], value[1]]));

            this.returnUrl = manualQueryParameters.get('returnUrl') ?? '/';
            this.code = manualQueryParameters.get('code');
            this.state = manualQueryParameters.get('state');

            if (this.code && this.state === authState) {
                this.loading = true;
                this.authenticationService.loginWithJaid(this.code, this.getRedirectUri())
                    .subscribe(
                        (tokenInformation: { expires_in: number, access_token: string, id_token: string }) => {
                            this.expiresIn = tokenInformation.expires_in;
                            this.accessToken = tokenInformation.access_token;
                            this.idToken = tokenInformation.id_token;

                            this.router.navigate([this.returnUrl]);
                        },
                        error => {
                            this.loading = false;
                            console.log('Error: ', error);
                            if (error.hasOwnProperty('error') && error.error.hasOwnProperty('detail')) {
                                error = error.error.detail.code;
                            } else {
                                error = error.statusText;
                            }
                            if (error.status === 404) {
                                error = this.translateService.instant('userDoesNotExist');
                            } else if (error.status === 403) {
                                error = this.translateService.instant('error_002');
                            } else if (error.status === 400) {
                                error = this.translateService.instant('failedIdentityHandShake');
                            }
                            this.alertService.error(this.translateService.instant(error));
                        });
            } else if (this.state && this.state !== authState) {
                this.alertService.error(this.translateService.instant('jasaStateDoesNotMatch'));
            } else {
                let error = manualQueryParameters.get('error');
                let errorDescription = manualQueryParameters.get('error_description');
                if(error || errorDescription) this.alertService.error(`Error: ${error} & Error Description: ${errorDescription}`);
            }
        });
    }

    onSubmit() {
        this.submitted = true;
        this.loading = true;

        if (this.globals.authServer) {
            let state = this.randomKey(16);
            localStorage.setItem('authState', state);
            //Build the url
            let url: URL = new URL(`${this.globals.authServer}/oauth2/default/v1/authorize`);
            url.searchParams.set('client_id', this.globals.authClientId); // Set the client id
            url.searchParams.set('redirect_uri', this.getRedirectUri()); // Set the redirect url
            url.searchParams.set('response_type', this.globals.authResponseType); //Set the response type
            url.searchParams.set('scope', this.globals.authScope); //Set the scope
            url.searchParams.set('state', state); //Set the state
            url.searchParams.set('response_mode', 'fragment'); //Set the response type
            url.searchParams.set('nonce', this.randomKey(16)); //Set the nonce

            pkceChallenge().then(result => {
                url.searchParams.set('code_challenge_method', 'S256'); //PKCE Auth
                url.searchParams.set('code_challenge', result.code_challenge); //PKCE Auth

                localStorage.setItem('authPKCEVerifier', result.code_verifier);

                window.location.href = url.href;
            });
        } else {
            this.alertService.error("Failed to connect to JAID Server");
            this.alertService.error(this.translateService.instant('failConnectionJASA'));
        }
    }

    private base64URLEncode(str: string): string {
        return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    }

    /**
     * @param length The length of the random string
     */
    private randomKey(length: number) {
        let array = new Uint8Array(length);
        array = crypto.getRandomValues(array);
        let randomString = String.fromCharCode.apply(null, Array.from(array));

        return this.base64URLEncode(randomString);
    }

    private getRedirectUri(): string {
        let redirectPort = ''
        if (window.location.port && window.location.port !== '' && window.location.port !== '80' && window.location.port !== '443') redirectPort = `:${window.location.port}`
        return `${window.location.protocol}//${window.location.hostname}${redirectPort}/callback`;
    }

}
