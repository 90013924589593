import {Injectable} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {TranslateService} from "@ngx-translate/core";

const componentName = "MatPaginatorIntlService";

/**
 * Utility service necessary to translate the mat-paginator
 * References:
 * https://material.angular.io/components/paginator/overview
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
 */
@Injectable()
export class MatPaginationIntlService extends MatPaginatorIntl {
    constructor(private readonly translateService: TranslateService) {
        super();


        // React whenever the language is changed
        this.translateService.onLangChange.subscribe((_event: Event) => {
            this.translateLabels();
        });

        // Initialize the translations once at construction time
        this.translateLabels();
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const of = this.translateService ? this.translateService.instant("of") : "of";
        if (length === 0 || pageSize === 0) {
            return "0 " + of + " " + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

        const endIndex = Math.min(startIndex + pageSize, length);
        return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
    };

    injectTranslateService(translate: TranslateService): void {
        var localTranslation = this.translateService
        localTranslation = translate;

        this.translateService.onLangChange.subscribe(() => {
            this.translateLabels();
        });

        this.translateLabels();
    }

    translateLabels(): void {
        this.firstPageLabel = this.translateService.instant("firstPage"); //firstPage
        this.itemsPerPageLabel = this.translateService.instant("itemsPerPage"); //itemsPerPage
        this.lastPageLabel = this.translateService.instant("lastPage"); //lastPage
        this.nextPageLabel = this.translateService.instant("nextPage"); //nextPage
        this.previousPageLabel = this.translateService.instant("previousPage"); //previousPage
        this.changes.next(); // Fire a change event to make sure that the labels are refreshed
    }
}