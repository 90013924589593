import {UntypedFormControl} from '@angular/forms';

export class CustomValidators {
    constructor() {

    }

    public static noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {'whitespace': true};
    }
}
