import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../globals';

import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SponsorshipService {

    private configUrl: string;

    constructor(
        private http: HttpClient,
        private globals: Globals
    ) {
        this.configUrl = globals.configUrl;
    }

    getUsers() {
        return this.http.get<any>(this.configUrl + 'people?_format=json');
    }

    editSponsor(data) {
        const sponsor_id = data.id;
        const site = `${this.globals.backendUrl}/api/sponsor/${sponsor_id}`;
        return this.http.put<any>(site, data).pipe(map(response => {
            return response;
        }));
    }

    getSponsors() {
        let params = new HttpParams();
        params = params.append('get_file_urls_and_location_names', 'true');
        const site = `${this.globals.backendUrl}/api/sponsor/list/all`;
        return this.http.get<any>(site, {
            params: params
        }).pipe(map(response => {
            return response;
        }));
    }

    getSponsor(sponsor_id) {
        let params = new HttpParams();
        params = params.append('get_file_urls_and_location_names', 'true');
        const serviceUrl = `${this.globals.backendUrl}/api/sponsor/${sponsor_id}`;
        return this.http.get<any>(serviceUrl, {
            params: params
        }).pipe(map((response) => {
                return response;
            })
        );
    }

    getAreas() {
        const site = `${this.globals.backendUrl}/api/area/list/all`;
        return this.http.get<any>(site, {}).pipe(map(response => {
                return response;
            })
        );
    }

    getGameById(gameId) {
        const serviceUrl = `${this.globals.backendUrl}/api/game/${gameId}`;
        return this.http.get<any>(serviceUrl, {}).pipe(map(response => {
                return response;
            })
        );
    }

    getGamesByName(name, limit) {
        const serviceUrl = `${this.globals.backendUrl}/api/game/list/byname?name=${name}&limit=${limit}`;
        return this.http.get<any>(serviceUrl, {}).pipe(map(response => {
                return response;
            })
        );
    }

    getGames() {
        const site = `${this.globals.backendUrl}/api/game/list/all`;
        return this.http.get<any>(site, {}).pipe(map(response => {
                return response;
            })
        );
    }

    deleteSponsor(sponsor) {
        return this.http.delete<any>(this.globals.backendUrl + '/api/sponsor/' + sponsor.id, {})
            .pipe(map((deletedSponsor) => {
                return deletedSponsor;
            }));
    }

}
