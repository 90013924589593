<app-breadcrumb></app-breadcrumb>
<nav>
    <ul>
        <li class="nav-dashboard">
            <a [routerLink]="['/dashboard']" routerLinkActive="router-link-active">{{'dashboard' | translate}}</a>
        </li>
        <li class="nav-games">
            <a [routerLink]="['/games']" routerLinkActive="router-link-active">{{'games' | translate}}</a>
            <!-- <a [routerLink]="['/games']" routerLinkActive="router-link-active" class="has-children">Games</a> -->
            <!-- <ul>
                <li *ngIf="currentUser?.role !== 'student'"><a [routerLink]="['/games/create']" routerLinkActive="router-child-link-active" >Create New Game</a></li>
            </ul> -->
        </li>
        <li *ngIf="currentUser?.role === 'national_admin' || currentUser?.role === 'area_admin' || currentUser?.role === 'superuser'"
            class="nav-sponsors">
            <a [routerLink]="['/sponsorships']" routerLinkActive="router-link-active">{{'sponsorships' | translate}}</a>
        </li>
        <li *ngIf="currentUser?.role === 'superuser'" class="nav-areas">
            <a [routerLink]="['/scenarios']" routerLinkActive="router-link-active">{{'scenarios' | translate}}</a>
        </li>
        <li *ngIf="currentUser?.role === 'teacher' || currentUser?.role === 'national_admin' || currentUser?.role === 'area_admin' || currentUser?.role === 'superuser'"
            class="nav-brackets">
            <a [routerLink]="['/brackets']" routerLinkActive="router-link-active">{{'brackets' | translate}}</a>
        </li>
        <li *ngIf="currentUser?.role === 'teacher' || currentUser?.role === 'national_admin' || currentUser?.role === 'area_admin' || currentUser?.role === 'superuser'"
            class="nav-competitions">
            <a [routerLink]="['/competitions']" routerLinkActive="router-link-active">{{'competitions' | translate}}</a>
        </li>
    </ul>
</nav>
<nav aria-label="log out" class="link-list">
    <ol>
        <li>
            <button (click)="logout()" class="button-link">{{'logOut' | translate}}</button>
        </li>
    </ol>
</nav>
