import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {UploadService} from 'src/app/shared/upload.service';
import {ScenarioService} from './../economic-model.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpEventType} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

export interface DialogData {
    timing: string;
    scenario: string;
}

@Component({
    selector: 'app-economic-model-create-dialog',
    templateUrl: 'economic-model-create-dialog.component.html',
    styleUrls: ['economic-model-create-dialog.component.scss']
})
export class EconomicModelCreateDialogComponent implements OnInit {

    editMode = false;
    dataToReturn = null;
    error: string;
    uploadResponse: { status: string, message: number, filePath: string } = null;
    imageUploadStatus = {
        'scenarioEventImage': this.uploadResponse
    };

    formGroup = this._formBuilder.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        scenarioEventImage: ['', Validators.required],
        timing: ['', Validators.required],
        tax_rate: [null],
        production_construction_cost: [null],
        marketing_construction_cost: [null],
        research_construction_cost: [null],
        production_operations_cost: [null],
        marketing_operations_cost: [null],
        research_operations_cost: [null],
        project_length_gen_0: [null],
        project_length_gen_1: [null],
        project_length_gen_2: [null],
        project_length_gen_3: [null],
        project_length_gen_4: [null],
        project_length_gen_5: [null],
        inventory_decrease: [null],
        production_cost_increase: [null],
        market_appetite_adjustment: [null]
    });

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private scenarioService: ScenarioService,
        private uploadService: UploadService,
        private route: ActivatedRoute,
        private router: Router,
        public dialogRef: MatDialogRef<EconomicModelCreateDialogComponent>,
        private readonly translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        dialogRef.beforeClosed().subscribe(() => dialogRef.close(this.dataToReturn));
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    capitalize(s) {
        if (typeof s !== 'string') {
            return '';
        }
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    ngOnInit() {
        this.imageUploadStatus.scenarioEventImage = {status: '', message: 0, filePath: ''};
        const scenarioId = this.route.snapshot.params.id;
        if (this.data['edit']) {
            this.editMode = true;
            this.populateForm();
        }
        this.formGroup.patchValue({
            timing: this.capitalize(this.data.timing)
        });
    }

    fileUploadError(error, field) {
        this.error = '(' + error.statusText + ') ' + this.translateService.instant(error.error.detail.code);
        this.imageUploadStatus[field].status = 'error';
        this.imageUploadStatus[field].message = this.error;
    }

    fileUploadProgress(event, field) {
        const progress = Math.round(100 * event.loaded / event.total);
        this.imageUploadStatus[field].status = 'progress';
        this.imageUploadStatus[field].message = progress;
    }

    fileUploadCompleted(event, field) {
        // File upload task is complete but not neccessarily successful
        console.log('upload complete');
    }

    fileUploadSuccess(event, field) {
        const res = event.body;
        this.uploadResponse = res;
        this.imageUploadStatus[field].filePath = res.url;
        this.imageUploadStatus[field].fileId = res.id;
    }

    onScenarioEventImageFileChange(event, field: string) {
        let file = event.target.files[0];

        if (event.target.files.length > 0) {
            file = event.target.files[0];
        }

        if (file) {
            const reader = new FileReader();

            reader.onloadstart = (e) => {
                this.imageUploadStatus[field].status = 'progress';
                this.imageUploadStatus[field].message = 0;
                this.onUpload(file, field);
            };

            reader.readAsBinaryString(file);
        }
    }

    onUpload(file, field) {
        const formData = new FormData();
        formData.append('file', file);
        this.uploadService.uploadFile(formData).subscribe(
            (event) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        this.fileUploadProgress(event, field);
                        break;

                    case HttpEventType.Response:
                        this.fileUploadSuccess(event, field);
                        break;

                    default:
                        if (event.hasOwnProperty('type')
                            && event.hasOwnProperty('loaded')
                            && event.hasOwnProperty('total')
                            && event['loaded'] === event['loaded']) {
                            this.fileUploadCompleted(event, field);
                        }
                        break;
                }
            },
            (err) => {
                this.fileUploadError(err, field);
            }
        );
    }

    populateForm() {
        const event = this.data['scenarioEvent'];
        this.imageUploadStatus['id'] = event.id;
        // Set text fields
        this.formGroup.patchValue({
            title: event['title'],
            description: event['description'],
            timing: event['timing'],
            tax_rate: event['tax_rate']
        });
        if (event.image_url) {
            // Set image fields
            this.imageUploadStatus.scenarioEventImage['status'] = 'progress';
            this.imageUploadStatus.scenarioEventImage['message'] = 100;
            this.imageUploadStatus.scenarioEventImage['filePath'] = event.image_url;
            this.imageUploadStatus.scenarioEventImage['fileId'] = event.image;
            // Remove image requirement
            this.formGroup.patchValue({
                scenarioEventImage: ['']
            });
        }
    }

    createOrEditScenarioEvent() {
        const data = {
            title: this.formGroup.value.title,
            description: this.formGroup.value.description,
            image: null,
            timing: this.formGroup.value.timing,
            tax_rate: this.formGroup.value.tax_rate,
            production_construction_cost: this.formGroup.value.production_construction_cost,
            marketing_construction_cost: this.formGroup.value.marketing_construction_cost,
            research_construction_cost: this.formGroup.value.research_construction_cost,
            production_operations_cost: this.formGroup.value.production_construction_cost,
            marketing_operations_cost: this.formGroup.value.marketing_operations_cost,
            research_operations_cost: this.formGroup.value.research_operations_cost,
            project_length_gen_0: this.formGroup.value.project_length_gen_0,
            project_length_gen_1: this.formGroup.value.project_length_gen_1,
            project_length_gen_2: this.formGroup.value.project_length_gen_2,
            project_length_gen_3: this.formGroup.value.project_length_gen_3,
            project_length_gen_4: this.formGroup.value.project_length_gen_4,
            project_length_gen_5: this.formGroup.value.project_length_gen_5,
            inventory_decrease: this.formGroup.value.inventory_decrease,
            production_cost_increase: this.formGroup.value.production_cost_increase,
            market_appetite_adjustment: this.formGroup.value.market_appetite_adjustment
        };
        if (this.editMode) {
            data['id'] = this.data['scenarioEvent']['id'];
            if (this.data.scenario) {
                data['scenario_id'] = this.data['scenario']['id'];
            }
        }
        // Set relevant file id
        data.image = this.imageUploadStatus.scenarioEventImage['fileId'];
        if (this.editMode) {
            this.scenarioService.editScenarioEvent(data)
                .subscribe((scenarioEvent) => {
                    this.dataToReturn = scenarioEvent;
                    this.dialogRef.close();
                });
        } else {
            this.scenarioService.createScenarioEvent(data)
                .subscribe((scenarioEvent) => {
                    this.dataToReturn = scenarioEvent;
                    this.dialogRef.close();
                });
        }
    }

}
