import { Component, OnInit, ViewChild, Input, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { filter, switchMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { timer, fromEvent } from 'rxjs';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource, MatTable} from '@angular/material/table';
import {PageEvent} from '@angular/material/paginator';

import {SelectionModel} from '@angular/cdk/collections';

import { GameServiceLocal } from '../game.service';
import { Game, ScenarioService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameStatus } from '../game-status.enum';

import { UserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { AuthenticationService } from '../../auth/authentication.service';

import { User } from '../../users/user.interface';


@Component({
  selector: 'app-games-list',
  templateUrl: './games-list.component.html',
  styleUrls: ['./games-list.component.scss']
})
export class GamesListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('gameTable') table: MatTable<any>;

  loading = false;

  initSubscription;
  pollingSubscription;

  games: [Game];
  currentUser: User;
  currentUserId = 0;
  states = GameStatus;
  scenario: any; // temp ScenarioGameOut;

  dialogResult: string;

  @ViewChild('searchInput') searchInput: ElementRef;
  nameSearch: string;

  // MatPaginator Inputs
  pageSizeOptions: number[] = [5, 10, 25, 50];

  // MatPaginator Output
  pageEvent: PageEvent;
  pageTotal: number;
  pageIndex = 0;
  pageSize = 10;

  @Input() controls = true;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['name', 'id', 'status', 'game_start', 'turn_advancement', 'quarter_duration', 'scenario', 'quarters', 'starting_cash', 'is_singleplayer', 'role'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel(false, []);

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private gameService: GameServiceLocal,
    private userService: UserService,
    private scenarioService: ScenarioService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource(this.games);
  }

  /** Whether there are any number of selected elements. */
  isAnySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  /** ARIA Accessibility: change label for the checkbox on the passed row */
  checkboxLabel(row?: Game, i?: number): string {
    if (!row) {
      return `${this.isAnySelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.name}`;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.dataSource.sort = this.sort;

    // this.authenticationService.currentUser.subscribe(user => {
    //   console.log('user', user);
    // });

      this.currentUser = this.authenticationService.currentUserValue;

      this.currentUserId = this.currentUser.id;
      this.getGames(this.currentUserId, this.pageIndex, this.pageSize);

      // get number of seconds from NOW until next 5 minute increment
      const startDate = new Date();
      console.log('startDate', startDate);

      const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
      const roundUpTo5Minutes = roundUpTo(1000 * 60 * 5);
      const endDate = new Date(roundUpTo5Minutes(new Date()));

      const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
      console.log('seconds', Math.trunc(seconds) );


      if ( this.currentUserId !== 0 ) {

        this.pollingSubscription = timer(seconds, 30000)
          .pipe(
            switchMap(() => this.userService.readUserAllGamesApiUserUserIdAllGamesGet(
              this.currentUserId, this.nameSearch, this.pageIndex, this.pageSize
            ) )
          ).subscribe(results =>  {
            console.log('this.pollingSubscription init', this.pollingSubscription);
            console.log('interval');
            let itemsProcessed = 0;

            results.items.forEach((game, index, array) => {
              if ( game.scenario_id !== undefined && game.scenario_id !== null ) {
                this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(game.scenario_id)
                  .subscribe(
                    scenario => {
                      (game as any).scenario_name = scenario.title;
                      console.log(scenario);
                    });
              }
              itemsProcessed++;
              if ( itemsProcessed === array.length) {
                this.setPage(results);

              }
            });
          });

      }


  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.searchInput.nativeElement,'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.nameSearch = this.searchInput.nativeElement.value;
          this.search();
        })
      )
      .subscribe();
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'status':
          // Custom sort for Game Status
          // eslint-disable-next-line max-len
          return item.is_active === false && item.is_complete === false ? this.states.pending : item.is_complete === true ? this.states.complete : this.states.inProgress;
        case 'role':
          // Custom sort for Game Status
          // eslint-disable-next-line max-len
          return this.currentUserId === item.uid ? 'Creator' : 'Participant';
        default: return item[property];
      }
    };
  }

  search() {
    this.pageIndex = 0;
    this.getGames(this.currentUserId, this.pageIndex, this.pageSize);
  }

  getGames(userId: number, pageIndex, pageSize) {
    this.loading = true;

    if ( userId !== 0 ) {
      this.userService.readUserAllGamesApiUserUserIdAllGamesGet(userId, this.nameSearch, pageIndex, pageSize)
      .subscribe(
        results => {
          let itemsProcessed = 0;

          if (results.items.length == 0) {
            this.setPage(results);
          }

          results.items.forEach((game, index, array) => {
            if ( game.scenario_id !== undefined && game.scenario_id !== null ) {
              this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(game.scenario_id)
                .subscribe(
                  scenario => {
                    (game as any).scenario_name = scenario.title;
                    console.log(scenario);
                  });
            }
            itemsProcessed++;

            if ( itemsProcessed === array.length) {
              this.setPage(results);
            }
          });

          this.loading = false;
        }, () => {
          console.error('no games found');
        });
    }
  }

  updatePage(event) {
    console.log(event);
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    console.log('getGames args', `${this.currentUserId}, ${this.pageIndex}, ${this.pageSize}`);

    this.getGames(this.currentUserId, this.pageIndex, this.pageSize);
  }

  setPage(results) {
    console.log('results', results);

    this.dataSource.data = results.items;
    this.pageTotal = results.total;
    this.pageSize = results.size;
    this.pageIndex = results.page;
  }

  ngOnDestroy() {
    // this.initSubscription.unsubscribe();
    if (this.pollingSubscription !== undefined) {
      this.pollingSubscription.unsubscribe();
    } else {
      this.authenticationService.logout();
      // this.router.navigate(['/login']);
    }
  }


}
