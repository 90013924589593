<header>
    <div class="container container-flex justify-content-between p-0">
        <div>
            <h1 class="d-inline-block">{{ 'scenario' | translate }}</h1>
        </div>
    </div>
</header>

<div *ngIf="dataSource.data.length !== 0" class="row">
    <table #scenariosTable [dataSource]="dataSource" mat-table matSort>

        <ng-container matColumnDef="index">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let scenario; let i = index" mat-cell style="padding: 0.5em">{{i + 1}}</td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'scenario' | translate }}</th>
            <td *matCellDef="let scenario" mat-cell>{{scenario.title | translate}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'description' | translate }}</th>
            <td *matCellDef="let scenario" mat-cell>{{scenario.description | translate}}</td>
        </ng-container>

        <ng-container matColumnDef="image_url">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'image' | translate }}</th>
            <td *matCellDef="let scenario" mat-cell>
                <img *ngIf="scenario.image_url" [src]="scenario.image_url" alt="" class="sponsorships-list-image"/>
            </td>
        </ng-container>

        <ng-container matColumnDef="starting_cash">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'startingCash' | translate }}</th>
            <td *matCellDef="let scenario" mat-cell> {{scenario.starting_cash}} </td>
        </ng-container>

        <ng-container matColumnDef="duration">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'duration' | translate }}</th>
            <td *matCellDef="let scenario" mat-cell> {{scenario.duration}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>

<div *ngIf="dataSource.data.length === 0" class="row">
    <div class="d-flex justify-content-center p-5">
        <p>{{ 'noScenariosFound' | translate }}</p>
    </div>
</div>
