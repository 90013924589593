import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-sponsorships',
    templateUrl: './economic-model.component.html',
    styleUrls: ['./economic-model.component.scss']
})
export class EconomicModelComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
