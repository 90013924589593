<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1 class="d-inline-block">{{ competition?.name }}</h1>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="dataSource.data.length > 0" [ngClass]="{'d-none': loading}" class="row">
    <table [dataSource]="dataSource" class="mat-selectable" mat-table>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell> {{ 'titanCompany' | translate }}</th>
            <td *matCellDef="let row" mat-cell><span class="column-width">{{ row.name }}</span></td>
        </ng-container>

        <ng-container *ngFor="let round of roundNumbers;" [matColumnDef]="getRoundProperty(round)">
            <th *matHeaderCellDef mat-header-cell> {{ 'roundTotal' | translate:{round: round} }}</th>
            <td *matCellDef="let row" mat-cell><span class="column-width">{{ row[getRoundProperty(round)] }}</span></td>
        </ng-container>

        <ng-container matColumnDef="grand_total">
            <th *matHeaderCellDef mat-header-cell> {{ 'grandTotal' | translate }}</th>
            <td *matCellDef="let row" mat-cell><span class="column-width">{{ row.grand_total }}</span></td>
        </ng-container>

        <ng-container matColumnDef="rank">
            <th *matHeaderCellDef mat-header-cell> {{ 'totalRank' | translate }}</th>
            <td *matCellDef="let row" mat-cell><span class="column-width">{{ row.rank }}</span></td>
        </ng-container>

        <ng-container matColumnDef="last_round_budget">
            <th *matHeaderCellDef
                mat-header-cell> {{ 'lastRoundProfit' | translate:{round: competition.round_count} }}</th>
            <td *matCellDef="let row" mat-cell><span class="column-width">{{ row.last_round_budget }}</span></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>

<ng-container *ngIf="dataSource.data.length === 0 && loading === false">
    <div class="flex-container empty-container">
        <div class="p-5 text-center"><p>{{ 'competitionHasNoResults' | translate }}</p></div>
    </div>
</ng-container>

