import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentChecked,
  ChangeDetectorRef
} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {TranslateService} from '@ngx-translate/core';
import Utils from '../../utils';
import {
  GameService,
  CompanyService,
  CompanyUserService,
  UserService,
  ScenarioService
} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {CompanyUserCreate} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {GameUpdate} from '@jaworldwideorg/staging-jaworldwide-titan-sdk/model/gameUpdate';
import {BaseGameCreateComponent} from '../../common/base.game-create.component';


@Component({
  selector: 'app-game-create',
  templateUrl: './create.component.html',
  styleUrls: [
    './create.component.scss'
  ]
})
export class GameCreateComponent extends BaseGameCreateComponent implements OnInit, AfterViewInit, AfterContentChecked {

  constructor(
    protected _formBuilder: UntypedFormBuilder,
    protected userService: UserService,
    private gameService: GameService,
    private companyService: CompanyService,
    private companyUserService: CompanyUserService,
    protected scenarioService: ScenarioService,
    private router: Router,
    protected route: ActivatedRoute,
    protected datePipe: DatePipe,
    protected dialog: MatDialog,
    protected cdref: ChangeDetectorRef,
    protected readonly translateService: TranslateService
  ) {
    super(_formBuilder, scenarioService, userService, route, dialog, translateService, cdref, datePipe);
  }

  loadEntityFromId(gameId: number) {
    const firstStepControls = this.formArray['controls'][0];
    this.gameService.readGameByIdApiGameGameIdGet(gameId)
      .subscribe(
        game => {
          this.courseId = game.course_id;

          let startDate;
          let startTime;

          if (!game.start_time) {
            firstStepControls.get('automatic_start').patchValue(false);
            // reset Start Time
            this.resetTime();
            // reset Start Date
            startDate = this.today.toISOString();
            // Patch form value with reset/rounded time
            startTime = this.hours + ':' + this.minutes;
          } else {
            firstStepControls.get('automatic_start').patchValue(true);
            // append trailing 'Z' (Zulu/UTC) because Create Game submission in backend strips it out
            game.start_time = new Date(game.start_time + 'Z');
            startDate = game.start_time;
            startTime = `${Utils.formatNumber2Digits(startDate.getHours())}:${Utils.formatNumber2Digits(startDate.getMinutes())}`;
          }

          // Append `(copy)` to Game Name
          const patchName = this.mode !== 'edit-game' ? ' - (copy)' : '';

          firstStepControls.patchValue({
            'name': game.name + patchName,
            'turn_advancement': game.turn_advancement,
            'quarter_duration': game.quarter_duration !== null ? game.quarter_duration : 0,
            'starting_cash': game.starting_cash !== null ? game.starting_cash.toString() : null,
            'duration': game.duration !== null ? game.duration.toString() : null,
            'scenario_id': game.scenario_id !== null ? +game.scenario_id : null,
            'start_date': startDate,
            'start_time': startTime
          });

          if (game.turn_advancement === 'Manual') {
            // Reset Quarter Duration
            firstStepControls.get('quarter_duration').patchValue(0);
          }

          // Reset Difficulty values if
          if (game.scenario_id !== null) {
            this.gameType = 'scenario';

            firstStepControls.patchValue({
              'starting_cash': this.cashOptions[0].value,
              'duration': this.durationOptions[0].value.toString()
            });
          }
        }
      );
  }

  createEntity(gameCreate?: any) {
    this.gameService.createGameApiGamePost(gameCreate)
      .subscribe(
        async response => {
          // For each team of users, create a Company
          const companiesToCreate = [];
          let i = 1;

          this.teamArray.forEach(element => {
            const tempGame =
              [
                {
                  'name': 'Game' + response.id + '_Company' + i,
                  'game_id': response.id
                },
                {
                  element
                }
              ];
            companiesToCreate.push(tempGame);
            i++;
          });

          companiesToCreate.forEach(teamRequest => {
            this.companyService.createCompanyApiCompanyPost(teamRequest[0])
              .subscribe(
                companyResponse => {
                  const companyUser: CompanyUserCreate = {};

                  // loop through users on each team and attach to company
                  teamRequest[1].element.forEach(teamUser => {
                    companyUser.user_id = teamUser.id;
                    companyUser.company_id = companyResponse.id;
                    this.companyUserService.createCompanyUserApiCompanyUserPost(companyUser)
                      .subscribe();
                  });
                }
              );
          });

          await this.router.navigate(['/games/detail/' + response.id]);
        }
      );
  }

  updateGame() {
    const firstStepControls = this.formArray['controls'][0];

    // we store as string and parse because LocalStorage transforms that data a bit, and we want to be consistent with createGame()
    localStorage.setItem('create_game_step_0', JSON.stringify(this.formArray.get([0]).value));
    const formUpdate = JSON.parse(localStorage.getItem('create_game_step_0'));

    formUpdate.course_id = this.courseId;

    formUpdate.duration = +formUpdate.duration;
    formUpdate.starting_cash = +formUpdate.starting_cash;

    if (
      firstStepControls.get('quarter_duration').value === 0 &&
      firstStepControls.get('turn_advancement').value === 'Timed'
    ) {
      firstStepControls.get('quarter_duration').markAsDirty();
    }

    // Validation toggle for Manual Start Games
    if (!firstStepControls.get('automatic_start').value) {
      formUpdate.start_date = null;
      formUpdate.start_time = null;
      firstStepControls.get('start_date').setValidators(null);
      firstStepControls.get('start_time').setValidators(null);
    } else {
      firstStepControls.get('start_date').setValidators([Validators.required]);
      firstStepControls.get('start_time').setValidators([Validators.required]);
    }

    // Validation toggle for Manual Turn Games
    if (firstStepControls.get('turn_advancement').value === 'Manual') {
      firstStepControls.get('quarter_duration').setValidators(null);
    } else {
      firstStepControls.get('quarter_duration').setValidators([Validators.required]);
    }

    if (this.gameType === 'custom') {
      formUpdate.scenario_id = null;
    }

    /*  Game Data Transforms */
    // concat and convert date
    // TODO instead of substring change the Parse settings on Material DatePicker Module
    if (formUpdate.start_date !== null && formUpdate.start_time !== null) {

      // convert date to local time using DatePipe
      const localDate = this.datePipe.transform(formUpdate.start_date, 'yyyy-MM-dd');

      let timeString = this.getDatetime(localDate, formUpdate.start_time);

      // If start time is already past, set to current time
      if (this.getDatetime(formUpdate.start_date.substring(0, 10), formUpdate.start_time) < new Date()) {

        // disabled button to prevent a double click
        this.updateDisabled = true;

        // round time string
        timeString = new Date();
        const coeff = 1000 * 60 * 5;
        timeString = new Date(Math.round(timeString.getTime() / coeff) * coeff);

        const dialogRef = this.dialog.open(DialogComponent, {
          width: '750px',
          data: {
            message: this.translateService.instant('StartTimeAlreadyPast', {'start_time': timeString}),
            confirm: 'OK'
          },
          disableClose: true
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            formUpdate.start_time = timeString;
          }
        });
      }
      formUpdate.start_time = timeString;
    }

    // Remove start_date as it is not part of our game model
    delete formUpdate.start_date;

    // update game settings
    if (firstStepControls.get('turn_advancement').value === 'Timed' &&
      (firstStepControls.get('quarter_duration').value === 0 || firstStepControls.get('quarter_duration').value === undefined)) {
      window.scrollTo({
        top: 600,
        behavior: 'smooth'
      });
    } else if (this.gameType === 'scenario' && firstStepControls.get('scenario_id').value === null) {
      firstStepControls.get('scenario_id').markAsDirty();
      window.scrollTo({
        top: 600,
        behavior: 'smooth'
      });
    } else {
      this.updateDisabled = true;
      this.gameService.updateGameByIdApiGameGameIdPut(+this.route.snapshot.paramMap.get('id'), formUpdate as GameUpdate)
        .subscribe({
          next: async () => {
            await this.router.navigate(['/games/detail', +this.route.snapshot.paramMap.get('id')]);
          },
          error: err => {
            console.error(err);
          }
        });
    }
  }
}
