<ng-container *ngIf="dataExists === true">
    <div class="container team-box my-0">

        <h3>{{'victoryCurrency' | translate}}</h3>

        <div class="list-box row my-4">
            <div *ngIf="loading" class="d-flex justify-content-center h-100">
                <mat-spinner></mat-spinner>
            </div>

            <table [dataSource]="dataSource" [ngClass]="{'d-none': loading}" mat-table matSort matSortActive="total"
                   matSortDirection="desc">

                <ng-container matColumnDef="rank">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>{{'rank' | translate}}</th>
                    <td *matCellDef="let company" mat-cell>{{ company.rank + 1 }}</td>
                </ng-container>

                <ng-container matColumnDef="companyLink">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let company" mat-cell><a *ngIf="company.linkState"
                                                              [routerLink]="[ '/company', company.id, gameId]"
                                                              class="w-100 text-right d-block px-3"><img
                            alt="View Company Results" class="mt-2"
                            src="../../../assets/images/file-chart-line-solid.svg"
                            width="16px"/></a></td>
                </ng-container>

                <ng-container matColumnDef="company">
                    <th *matHeaderCellDef mat-header-cell>{{'name' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.name}} </td>
                </ng-container>

                <ng-container matColumnDef="budget">
                    <th *matHeaderCellDef mat-header-cell>{{'profit' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.budget}} </td>
                </ng-container>

                <ng-container matColumnDef="research">
                    <th *matHeaderCellDef mat-header-cell>{{'RD' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.research}} </td>
                </ng-container>

                <ng-container matColumnDef="production">
                    <th *matHeaderCellDef mat-header-cell>{{'production' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.production}} </td>
                </ng-container>

                <ng-container matColumnDef="marketing">
                    <th *matHeaderCellDef mat-header-cell>{{'marketingAndSales' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.marketing}} </td>
                </ng-container>

                <ng-container matColumnDef="csr">
                    <th *matHeaderCellDef mat-header-cell>{{'CSR' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.csr}} </td>
                </ng-container>

                <ng-container matColumnDef="total">
                    <th *matHeaderCellDef mat-header-cell>{{'total' | translate}}</th>
                    <td *matCellDef="let company" mat-cell> {{company.total}} </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
                <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'linkStateActive': row.linkState}"
                    mat-row></tr>

            </table>
        </div>
    </div>
</ng-container>
