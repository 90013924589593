import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timeformat'
})

export class TimeFormatPipe implements PipeTransform {
    // convert 24 hour to 12 hour times
    transform(time: any): any {
        let hour = (time.split(':'))[0];
        console.log(hour);
        let min = (time.split(':'))[1];
        const part = hour >= 12 ? 'pm' : 'am';
        min = (min + '').length === 1 ? `0${min}` : min;

        // convert hour to 12hr format, if 12am, display 12 not 0
        hour = hour > 12 ? hour - 12 : (hour > 0 ? hour : 12);
        hour = (hour + '').length === 1 ? `${hour}` : hour;
        return `${hour}:${min} ${part}`;
    }
}
