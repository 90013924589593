<mat-expansion-panel [expanded]="true" class="container game-settings" collapsedHeight>
    <mat-expansion-panel-header>
        <mat-panel-title>
            <h1> {{ 'announcements' | translate }}</h1>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="container-list">
        <div *ngFor="let announcement of announcements; let i=index" class="annoucement">
            <hr>
            <p>{{ announcement.body }}</p>
            <p class="accouncement-date text-uppercase">{{ 'postedBy' | translate:{ author: '<span
                    class="announcement-author text-highlight">'+announcement.author+'</span>' ,date: announcement.date
                } }}</p>
        </div>
    </div>

</mat-expansion-panel>
    