<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1>{{ 'areas' | translate }}</h1>
        </div>
    </div>
</header>
<div class="row">
    <ul *ngIf="areas" class="courses nav flex-column w-100">
        <li *ngFor="
        let area of areas;
        let first = first;
        let last = last;
        let even = even;
        let odd = odd" class="nav-item">
            {{area.name}}
        </li>
    </ul>
</div>
