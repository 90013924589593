<div class="list-box row">
    <table [dataSource]="dataSource" mat-table matSort>

        <ng-container matColumnDef="index">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'rank' | translate}} </th>
            <td *matCellDef="let element; let i = index;" mat-cell>{{i}}</td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'name' | translate}} </th>
            <td *matCellDef="let team" mat-cell> {{team.name}} </td>
        </ng-container>

        <ng-container matColumnDef="height">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'height' | translate}} </th>
            <td *matCellDef="let team" mat-cell> {{team.height}} </td>
        </ng-container>

        <ng-container matColumnDef="mass">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'mass' | translate}} </th>
            <td *matCellDef="let team" mat-cell> {{team.mass}} </td>
        </ng-container>

        <ng-container matColumnDef="hair_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'hairColor' | translate}} </th>
            <td *matCellDef="let team" mat-cell> {{team.hair_color}} </td>
        </ng-container>

        <ng-container matColumnDef="eye_color">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'eyeColor' | translate}} </th>
            <td *matCellDef="let team" mat-cell> {{team.eye_color}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>