import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {Globals} from '../globals';


@Injectable({
    providedIn: 'root'
})
export class UploadService {

    SERVER_URL: string;

    constructor(
        private httpClient: HttpClient,
        private globals: Globals
    ) {
        this.SERVER_URL = globals.localUrl;
    }

    public uploadFile(data) {
        const serviceUrl = `${this.globals.backendUrl}/api/file/`;

        return this.httpClient.post<any>(serviceUrl, data, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {
                return event;
            })
        );
    }

    public uploadSponsor(data) {
        const serviceUrl = `${this.globals.backendUrl}/api/sponsor/`;

        return this.httpClient.post<any>(serviceUrl, data, {}).pipe(map((response) => {
                return response;
            })
        );
    }

    public upload(data, userId?) {
        const serviceUrl = `/auth/${userId}/avatar`;

        return this.httpClient.post<any>(serviceUrl, data, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map((event) => {

                switch (event.type) {

                    case HttpEventType.UploadProgress:
                        const progress = Math.round(100 * event.loaded / event.total);
                        return {status: 'progress', message: progress};

                    case HttpEventType.Response:
                        return event.body;
                    default:
                        return `Unhandled event: ${event.type}`;
                }
            })
        );
    }

}
