import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    scenarioEvents: [];
    scenario_id: null;
}

@Component({
    selector: 'app-economic-model-assign-dialog',
    templateUrl: 'economic-model-assign-dialog.component.html',
    styleUrls: ['./economic-model-assign-dialog.component.scss']
})
export class EconomicModelAssignEventDialogComponent implements OnInit {

    displayedColumns: string[] = ['id', 'title', 'scenario_id'];
    dataSource = [];

    constructor(
        public dialogRef: MatDialogRef<EconomicModelAssignEventDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit() {
        const filtered_events = [];
        this.data.scenarioEvents.forEach(event => {
            if (event && event['scenario_id'] !== this.data.scenario_id) {
                filtered_events.push(event);
            }
        });
        this.dataSource = filtered_events;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
