import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {CompetitionBaseInDBWithGames, CompetitionService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {User} from '../../users/user.interface';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'app-competition-results',
    templateUrl: './competition-results.component.html',
    styleUrls: ['./competition-results.component.scss']
})
export class CompetitionResultsComponent implements OnInit {

    loading = false;
    currentUser: User;
    competitionId: number;
    competition: CompetitionBaseInDBWithGames;

    displayedColumns: string[] = [];
    dataSource: MatTableDataSource<any>;
    roundNumbers: number[] = [];

    constructor(
        private competitionService: CompetitionService,
        private route: ActivatedRoute
    ) {
        this.dataSource = new MatTableDataSource;
    }

    ngOnInit(): void {
        this.route.params.subscribe(() => {
            this.competitionId = +this.route.snapshot.paramMap.get('id');
            this.getCompetitionResults(this.competitionId);
        });
    }

    async getCompetitionResults(id: number) {
        this.loading = true;

        try {
            const competition = await firstValueFrom(this.competitionService.getCompetitionApiCompetitionCompetitionIdGet(id));
            this.competition = competition;
            const results = [];
            if (competition.is_complete) {
                this.roundNumbers = Array.from({length: competition.round_count}, (_, i) => i + 1);
                const competitionResults = await firstValueFrom(this.competitionService.getCompetitionResultsApiCompetitionCompetitionIdResultsGet(id));
                if (competitionResults) {
                    this.displayedColumns = ['name'];
                    this.roundNumbers.forEach(round => {
                        this.displayedColumns.push(this.getRoundProperty(round));
                    });
                    this.displayedColumns = this.displayedColumns.concat(['grand_total', 'rank', 'last_round_budget']);
                    competitionResults.forEach(result => {
                        const companyRow = {};
                        results.push(companyRow);
                        companyRow['name'] = result.name;
                        companyRow['grand_total'] = result.grand_total;
                        companyRow['rank'] = result.rank;
                        companyRow['last_round_budget'] = result.last_round_budget;
                        if (result.round_totals) {
                            result.round_totals.forEach(roundTotal => {
                                companyRow[this.getRoundProperty(roundTotal.round_number)] = roundTotal.round_total;
                            });
                        }
                    });
                }
            }

            this.dataSource.data = results;
            this.loading = false;
        } catch (e) {
            console.error(e);
        }
    }

    getRoundProperty(num: number): string {
        return `round_${num}`;
    }

}
