<h2>Register</h2>
<form (ngSubmit)="onSubmit()" [formGroup]="registerForm">
    <div class="form-group">
        <label for="firstName">{{'firstName' | translate}}</label>
        <input [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" class="form-control" formControlName="firstName"
               type="text"/>
        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">{{'firstNameRequired' | translate}}</div>
        </div>
    </div>
    <div class="form-group">
        <label for="lastName">{{'lastName' | translate}}</label>
        <input [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" class="form-control" formControlName="lastName"
               type="text"/>
        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">{{'lastNamerequired' | translate}}</div>
        </div>
    </div>
    <div class="form-group">
        <label for="username">{{'username' | translate}}</label>
        <input [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control" formControlName="username"
               type="text"/>
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">{{'usernameRequired' | translate}}</div>
        </div>
    </div>
    <div class="form-group">
        <label for="password">{{'password' | translate}}</label>
        <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control" formControlName="password"
               type="password"/>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">{{'passwordRequired' | translate}}</div>
            <div *ngIf="f.password.errors.minlength">{{'passwordLengthError' | translate}}</div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="button-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{'register' | translate}}
        </button>
        <button class="btn button-link" routerLink="/login">{{'login' | translate}}</button>
    </div>
</form>