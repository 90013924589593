<header>
    <div class="container container-flex justify-content-between p-0">
        <div>
            <h1 class="d-inline-block">{{'sponsorships' | translate}}</h1>
        </div>
        <div class="container-flex align-items-center">
            <button [routerLink]="['/sponsorships/create']"
                    class="button-primary">{{'createNewSponsorship' | translate}}</button>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div class="row">
    <div class="d-flex justify-content-end">
        <form [formGroup]="formGroup">
            <mat-form-field appearance="fill" floatLabel="always">
                <mat-label>{{'setVisibilityOfSelected' | translate}}:</mat-label>
                <select (change)="bulkAction($event.target.value)" formControlName="statusValue" matNativeControl>
                    <option value=""></option>
                    <option *ngFor="let state of visibilityStates" [value]="state">{{state | translate}}</option>
                    <option value="delete">{{'delete' | translate}}</option>
                </select>
            </mat-form-field>
        </form>
    </div>
</div>

<div [hidden]="dataSource.data.length === 0" class="row">
    <table #sponsorshipsTable [dataSource]="dataSource" class="sponsorships-table mat-selectable" mat-table matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th *matHeaderCellDef mat-header-cell>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                              [aria-label]="checkboxLabel()"
                              [checked]="selection.hasValue() && isAnySelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td *matCellDef="let row" mat-cell>
                <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                              (click)="$event.stopPropagation()"
                              [aria-label]="checkboxLabel(row)"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <ng-container matColumnDef="id">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'id' | translate}} </th>
            <td *matCellDef="let sponsor" mat-cell> {{sponsor.id}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'sponsor' | translate}} </th>
            <td *matCellDef="let sponsor" mat-cell> {{sponsor.title}} </td>
        </ng-container>

        <ng-container matColumnDef="active">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'status' | translate}} </th>
            <td *matCellDef="let sponsor" mat-cell><span
                    class="text-uppercase"> {{sponsor.active === true ? ('active' | translate) : ('inactive' | translate) }} </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="area_name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'displayLocation' | translate}} </th>
            `
            <td *matCellDef="let sponsor"
                mat-cell> {{sponsor.area_name !== null ? sponsor.area_name : sponsor.game_name !== null ? sponsor.game_name : '' }} </td>
        </ng-container>

        <ng-container matColumnDef="sponsor_type">
            <th *matHeaderCellDef mat-header-cell mat-sort-header> {{'sponsorshipType' | translate}} </th>
            <td *matCellDef="let sponsor"
                mat-cell> {{ sponsor.sponsor_type === 'small' ? ('bottomBar' | translate) : ('betweenQuarters' | translate) }}</td>
        </ng-container>

        <ng-container matColumnDef="logo_url">
            <th *matHeaderCellDef mat-header-cell> {{'logo' | translate}} </th>
            <td *matCellDef="let sponsor" mat-cell><img [src]="sponsor.logo_url" class="sponsorships-list-image"/></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="editSponsor(row)" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    </table>
</div>

<div *ngIf="dataSource.data.length === 0 && !loading" class="row">
    <div class="d-flex justify-content-center p-5">
        <p>{{'noSponsorshipsFound' | translate}}</p>
    </div>
</div>
