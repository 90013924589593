import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface DialogData {
    scenarioEvent: null;
    scenarios: null;
}

@Component({
    selector: 'app-economic-model-delete-event-dialog',
    templateUrl: 'economic-model-delete-event-dialog.component.html',
    styleUrls: ['./economic-model-delete-event-dialog.component.scss']
})
export class EconomicModelDeleteEventDialogComponent implements OnInit {

    displayedColumns: string[] = ['id', 'title'];

    constructor(
        public dialogRef: MatDialogRef<EconomicModelDeleteEventDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    ngOnInit() {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
