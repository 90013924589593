import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { AlertService } from '../../alert/alert.service';

import { ScenarioService, ScenarioUpdate } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameService, BracketService, BracketBaseInDBWithGames } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameUpdate } from '@jaworldwideorg/staging-jaworldwide-titan-sdk/model/gameUpdate';

import { User } from '../../users/user.interface';
import { AuthenticationService } from '../../auth/authentication.service';

import { GameWithCompanies } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameStatus } from '../../games/game-status.enum';

import {MatTableDataSource, MatTable} from '@angular/material/table';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';

import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import GameOptions from '../../common/game-options';

@Component({
  selector: 'app-bracket-detail-round',
  templateUrl: './bracket-detail-round.component.html',
  styleUrls: ['./bracket-detail-round.component.scss']
})
export class BracketDetailRoundComponent implements OnInit, OnDestroy {

  @ViewChild('bracketTable') table: MatTable<any>;

  pollingSubscription;

  loading = false;
  currentUser: User;
  bracketId: number;
  bracket: BracketBaseInDBWithGames;
  games: GameWithCompanies[];
  states = GameStatus;
  status: string;
  isRoundPending = true;
  isRoundLockedActive = true;
  isRoundLockedComplete = false;

  gameUpdate: GameUpdate;
  updateDisabled = false;
  startDisabled = false;
  roundLoading = false;
  gamesInRound: [GameWithCompanies];

  displayedColumns: string[] = ['name', 'status', 'companies'];
  dataSource: MatTableDataSource<any>;

  gameSettings: GameWithCompanies;

  gameTypeOptions = GameOptions.gameTypeOptions;
  cashOptions = GameOptions.cashOptions;
  durationOptions = GameOptions.durationOptions;

  gameType = 'custom';

  selectedScenario: ScenarioUpdate;
  scenarioOptions = [];

  @ViewChild('gameTypeValue', {static: true}) gameTypeValue: MatButtonToggleGroup;

  formGroup = this._formBuilder.group({
    starting_cash: this.cashOptions[0].value,
    duration: this.durationOptions[0].value.toString(),
    scenario_id: null
  });

  constructor(
    private bracketService: BracketService,
    private scenarioService: ScenarioService,
    private authenticationService: AuthenticationService,
    private gameService: GameService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertService,
    private _formBuilder: UntypedFormBuilder
  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.params.subscribe(() => {
        this.bracketId = +this.route.snapshot.paramMap.get('id');
        this.getBracket(this.bracketId);
    });

    this.getAllScenarios();

    if ( this.currentUser.id !== 0 ) {

      this.pollingSubscription = timer(0, 5000)
        .pipe(
          switchMap(() => this.bracketService.getBracketApiBracketBracketIdGet(this.bracketId ) )
        ).subscribe(bracket =>  {
          // console.log('this.pollingSubscription init', this.pollingSubscription);
          this.getGames(bracket);
          // console.log('interval');
        });
    }

  }

  gameTypeChanged(event) {
    this.gameType = event.value;
    // console.log(this.gameType);
  }

  getBracket(id: number) {
    this.loading = true;

    if ( id !== undefined ) {
      this.bracketService.getBracketApiBracketBracketIdGet(id)
        .subscribe(
          bracket => {
            console.log('bracket', bracket);
            this.bracket = bracket;
            this.dataSource.data = bracket.games;

            // Just going to grab the first game that matches the current_round
            this.gameSettings = bracket.games.find(obj => {
              return obj.bracket_round_number === bracket.current_round;
            });

            this.getGames(bracket);

            if ( this.gameSettings.scenario_id !== null ) {
              this.gameType = 'scenario';
            }

            this.formGroup.patchValue({
              'starting_cash': this.gameSettings.starting_cash !== null ? this.gameSettings.starting_cash.toString() : null,
              'duration': this.gameSettings.duration !== null ? this.gameSettings.duration.toString() : null,
              'scenario_id': this.gameSettings.scenario_id !== null ? +this.gameSettings.scenario_id : null,
            });

            // console.log(getAllIndexes(totalStatus, this.states.pending));

            this.loading = false;

          },
          error => {
            console.log(error);
          }
        );
    }
  }

  getGames( bracket ) {
    // filter Games down to those that match current round, these are the games to update
    this.games = bracket.games.filter(obj => {
      return obj.bracket_round_number === bracket.current_round;
    });

    this.dataSource.data = this.games;

    console.log('this.games', this.games );


    /* Is Current Round of Games pending */
      this.isRoundPending = this.games.every(game => !game.is_active && !game.is_complete);

    /* Locks for Round Editing */

      // Lock editing if ANY game in current round is in progress or complete
      this.isRoundLockedActive = this.games.some(game => game.is_complete || game.is_active );
      console.log('isRoundLockedActive', this.isRoundLockedActive);

      // Lock editing if current round is complete
      this.isRoundLockedComplete = this.games.every(game => !game.is_active && game.is_complete);
      console.log('isRoundLockedComplete', this.isRoundLockedComplete);

    /* Set Status of Round: Pending, In Progress, Complete */
      if ( this.isRoundPending ) {
        this.status = this.states.pending;
      } else if ( this.isRoundLockedComplete ) {
        this.status = this.states.complete;
        this.router.navigate(['/brackets/detail', this.bracketId]);
      } else {
        this.status = this.states.inProgress;
      }

  }

  getAllScenarios() {
    this.scenarioService.readAllScenariosApiScenarioGet()
      .subscribe(
        scenarios => {
          this.scenarioOptions = scenarios;
          console.log('this.scenarioOptions', this.scenarioOptions);

        }
      );
  }

  updateScenario(target) {
    const scenario = target.options[target.selectedIndex].dataset.scenario;
    console.log( scenario );
    this.selectedScenario = JSON.parse(scenario);
  }

  updateRound() {

    // disabled button to prevent a double click
    this.updateDisabled = true;

    this.games.forEach( game => {

      this.gameUpdate = {};

      this.gameUpdate.course_id = game.course_id;

      // Clean up unused data for Scenario Games
      if ( this.gameType === 'custom' ) {
        this.selectedScenario = null;
        this.gameUpdate.scenario_id = null;
        this.gameUpdate.duration = this.formGroup.controls['duration'].value;
        this.gameUpdate.starting_cash = this.formGroup.controls['starting_cash'].value;

      } else {
        this.gameUpdate.duration = null;
        this.gameUpdate.starting_cash = null;
        this.gameUpdate.scenario_id = +this.formGroup.controls['scenario_id'].value;

      }

      console.log('this.gameUpdate', this.gameUpdate);

      // update game settings
      this.gameService.updateGameByIdApiGameGameIdPut(+game.id, this.gameUpdate)
        .subscribe(
          update => {
            console.log(update);
            this.router.navigate(['/brackets/detail', +this.route.snapshot.paramMap.get('id')]);
          },
          error => {
            console.log(error);
          }
        );

    });

  }

  startRound() {

    // disabled button to prevent a double click
    this.startDisabled = true;

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to immediately start Bracket ${this.bracket.id}?`, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        const errorStatus = [];
        let itemsProcessed = 0;
        const errorAlert = 'alert';
        let errorMsg = 'Please create a new Bracket. No companies have joined Games:';

        this.games.forEach( (game, index, array) => {

          // start games
          this.gameService.startGameByIdApiGameGameIdStartPost(+game.id)
            .subscribe(
              gameStart => {
                console.log('gameStart', gameStart);

                itemsProcessed++;
                if ( itemsProcessed === array.length) {
                  this.roundCallback(errorAlert, errorStatus, errorMsg);
                }
              },
              error => {
                if ( error === 'Conflict' || error.status === 409 ) {
                  this.startDisabled = false;

                  errorStatus.push(game.id);

                  itemsProcessed++;
                  if ( itemsProcessed === array.length) {
                    if ( errorStatus.length === array.length) {
                      errorMsg = 'The round cannot be started, no companies have joined Games:';
                    }
                    this.roundCallback(errorAlert, errorStatus, errorMsg);
                  }
                }
                console.log(error);
              }
            );
        });

        console.log('errorStatus', errorStatus);

/*         if ( errorStatus.length > 0) {
          const gamesList = errorStatus.join(', ');
          this.alertService.error(`The round cannot be started, no companies have joined games ${gamesList}`);
        } else {
          this.router.navigate(['/brackets/detail/', this.bracket.id]);
        } */

      } else {
        this.startDisabled = false;
        console.log('The dialog was closed');
      }
    });
  }

  endCurrentRound() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '750px',
      data: { message: `Are you sure you want to end the current Quarter?`, reject: `Cancel` },
      disableClose: true
    });

      dialogRef.afterClosed()
        .subscribe(result => {

          if (result === true) {
            this.roundLoading = true;
            const errorStatus = [];
            let itemsProcessed = 0;
            const gamesComplete = [];
            let errorAlert = 'console';
            const errorMsg = 'The following games were not advanced: ';

            this.games.forEach( (game, index, array) => {
              this.gameService.advanceGameQuarterApiGameGameIdAdvanceQuarterPost(+game.id)
              .subscribe(
                advancedGame => {
                  itemsProcessed++;

                  if ( advancedGame.is_complete === true) {
                    gamesComplete.push(advancedGame.id);
                  }
                  if ( itemsProcessed === array.length) {
                    // if all games are complete redirect to Bracket Detail
                    if ( gamesComplete.length === array.length ) {
                      this.router.navigate(['/brackets/detail/', this.bracket.id]);
                    } else {
                      this.roundCallback(errorAlert, errorStatus, errorMsg);
                    }
                  }
                }, () => {
                  errorStatus.push(game.id);
                  console.log(`Game ${game.id} quarter was not advanced`);

                  itemsProcessed++;
                  if ( itemsProcessed === array.length) {
                    // no games advanced
                    if ( errorStatus.length === array.length) {
                      errorAlert = 'alert';
                    }
                    this.roundCallback(errorAlert, errorStatus, errorMsg);

                  }
                }
              );
            });

            if (this.roundLoading ) {
              this.roundLoading = false;
            }

/*             if ( errorStatus.length > 0) {
              this.alertService.error('Round was not advanced');
            } else {
              this.router.navigate(['/brackets/detail/', this.bracket.id]);
            } */

            // TODO refresh game
          } else {
            this.roundLoading = false;
            console.log('The dialog was closed');
          }
      });
  }

  roundCallback(errorAlert, errorStatus, errorMsg, ids? ) {

    if ( errorStatus.length > 0 && errorAlert === 'alert') {
      const gamesList = errorStatus.join(', ');
      this.alertService.error(errorMsg + ' ' + gamesList, false);

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

    } else if ( errorStatus.length > 0 && errorAlert === 'console') {
      const gamesList = errorStatus.join(', ');
      console.log(errorMsg + ' ' + gamesList);
      this.startDisabled = false;
      this.alertService.clear();
      this.getBracket(this.bracket.id);

    } else {
      this.startDisabled = false;
      this.alertService.clear();
      this.getBracket(this.bracket.id);
    }

  }

  openDialog(msg: string): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: { message: msg, reject: `Cancel` },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      return result;
    });
  }

  ngOnDestroy() {
    if (this.pollingSubscription !== undefined) {
      this.pollingSubscription.unsubscribe();
    } else {
      this.authenticationService.logout();
      // this.router.navigate(['/login']);
    }
  }

}
