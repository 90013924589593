import {Pipe, PipeTransform} from '@angular/core';
import {StringFormatPipe} from './stringformat.pipe';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'gameName',
    pure: false
})
export class GameNamePipe extends StringFormatPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
        super();
    }

    transform(value: string, ...args: any[]): string {
        return super.transform(value, {
            round: this.translateService.instant('round'),
            game: this.translateService.instant('game')
        });
    }
}
