<div style="margin-left: 2em">
    <header>
        <div class="container container-flex justify-content-between">
            <div>
                <h2 class="d-inline-block"
                    style="margin-top: 0em">{{ editMode ? ('editScenarioEvent' | translate) : ('newScenarioEvent' | translate) }}</h2>
            </div>
        </div>
    </header>
    <div class="container p-0">
        <form [formGroup]="formGroup">
            <div class="py-4">
                <mat-form-field>
                    <input [maxlength]="26" formControlName="title" id="title" matInput
                           placeholder="{{ 'title' | translate }}">
                </mat-form-field>
                <mat-error *ngIf="formGroup.get('title').dirty && formGroup.get('title').errors?.required" class="test">
                    {{ 'titleRequired' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
          <textarea cols="30" formControlName="description" id="description"
                    matInput placeholder="{{ 'description' | translate }}"
                    rows="1"></textarea>
                </mat-form-field>
            </div>
            <div class="container-flex py-4">
                <div class="column">
                    <label>{{ 'image' | translate }}</label>
                    <p>{{ 'uploadLogoType' | translate }}</p>
                    <input (change)="onScenarioEventImageFileChange($event, 'scenarioEventImage')" class="btn-upload d-flex align-items-center justify-content-center my-4"
                           formControlName="scenarioEventImage" id="scenarioEventImage"
                           type="file"/>
                    <label for="scenarioEventImage"><span>{{ 'uploadImage' | translate }}</span></label>
                    <mat-progress-bar *ngIf="imageUploadStatus.scenarioEventImage.status === 'progress'"
                                      [value]="imageUploadStatus.scenarioEventImage.message"
                                      mode="determinate"></mat-progress-bar>
                    <mat-error *ngIf="error">
                        <div *ngIf="imageUploadStatus.scenarioEventImage.status === 'error'">
                            {{ imageUploadStatus.scenarioEventImage.message }}
                        </div>
                    </mat-error>
                    <div class="small-image-preview my-4">
                        <img *ngIf="imageUploadStatus.scenarioEventImage.message >= 100 && imageUploadStatus.scenarioEventImage.filePath !== null"
                             [src]="imageUploadStatus.scenarioEventImage.filePath | safeHtml"/>
                    </div>
                </div>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <input [maxlength]="26" formControlName="timing" id="timing" matInput
                           placeholder="{{ 'timing' | translate }}">
                </mat-form-field>
                <mat-error *ngIf="formGroup.get('timing').dirty && formGroup.get('timing').errors?.required"
                           class="test">
                    {{ 'timingRequired' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="tax_rate" id="tax_rate" matInput
                           placeholder="{{ 'taxRate' | translate }}">
                </mat-form-field>
                <mat-error *ngIf="formGroup.get('tax_rate').dirty && formGroup.get('tax_rate').errors?.required"
                           class="test">
                    {{ 'taxRate' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="production_construction_cost" id="production_construction_cost"
                           matInput placeholder="{{ 'productionConstructionCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('production_construction_cost').dirty && formGroup.get('production_construction_cost').errors?.required"
                        class="test">
                    {{ 'productionConstructionCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="marketing_construction_cost" id="marketing_construction_cost"
                           matInput placeholder="{{ 'marketingConstructionCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('marketing_construction_cost').dirty && formGroup.get('marketing_construction_cost').errors?.required"
                        class="test">
                    {{ 'marketingConstructionCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="research_construction_cost" id="research_construction_cost"
                           matInput placeholder="{{ 'researchConstructionCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('research_construction_cost').dirty && formGroup.get('research_construction_cost').errors?.required"
                        class="test">
                    {{ 'researchConstructionCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="production_operations_cost" id="production_operations_cost"
                           matInput placeholder="{{ 'productionOperationsCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('production_operations_cost').dirty && formGroup.get('production_operations_cost').errors?.required"
                        class="test">
                    {{ 'productionOperationsCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="marketing_operations_cost" id="marketing_operations_cost"
                           matInput placeholder="{{ 'marketingOperationsCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('marketing_operations_cost').dirty && formGroup.get('marketing_operations_cost').errors?.required"
                        class="test">
                    {{ 'marketingOperationsCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="research_operations_cost" id="research_operations_cost"
                           matInput placeholder="{{ 'researchOperationsCost' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('research_operations_cost').dirty && formGroup.get('research_operations_cost').errors?.required"
                        class="test">
                    {{ 'researchOperationsCost' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_0" id="project_length_gen_0"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 0">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_0').dirty && formGroup.get('project_length_gen_0').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 0
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_1" id="project_length_gen_1"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 1">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_1').dirty && formGroup.get('project_length_gen_1').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 1
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_2" id="project_length_gen_2"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 2">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_2').dirty && formGroup.get('project_length_gen_2').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 2
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_3" id="project_length_gen_3"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 3">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_3').dirty && formGroup.get('project_length_gen_3').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 3
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_4" id="project_length_gen_4"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 4">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_4').dirty && formGroup.get('project_length_gen_4').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 4
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="project_length_gen_5" id="project_length_gen_5"
                           matInput placeholder="{{ 'projectLengthGen' | translate }} 5">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('project_length_gen_5').dirty && formGroup.get('project_length_gen_5').errors?.required"
                        class="test">
                    {{ 'projectLengthGen' | translate }} 5
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="inventory_decrease" id="inventory_decrease"
                           matInput
                           placeholder="{{ 'inventoryDecrease' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('inventory_decrease').dirty && formGroup.get('inventory_decrease').errors?.required"
                        class="test">
                    {{ 'inventoryDecrease' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="production_cost_increase" id="production_cost_increase"
                           matInput placeholder="{{ 'productionCostIncrease' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('production_cost_increase').dirty && formGroup.get('production_cost_increase').errors?.required"
                        class="test">
                    {{ 'productionCostIncrease' | translate }}
                </mat-error>
            </div>
            <div class="py-4">
                <mat-form-field>
                    <label style="font-weight: normal; font-size: 18px; color: lightgray">({{ 'optional' | translate }}
                        )</label>
                    <input [maxlength]="26" formControlName="market_appetite_adjustment" id="market_appetite_adjustment"
                           matInput placeholder="{{ 'marketAppetiteAdjustment' | translate }}">
                </mat-form-field>
                <mat-error
                        *ngIf="formGroup.get('market_appetite_adjustment').dirty && formGroup.get('market_appetite_adjustment').errors?.required"
                        class="test">
                    {{ 'marketAppetiteAdjustment' | translate }}
                </mat-error>
            </div>
            <button (click)="createOrEditScenarioEvent()" [disabled]="formGroup.invalid" class="button-primary" mat-button
                    type="submit">{{ editMode ? 'Edit' : 'Create' }}</button>
        </form>
    </div>
</div>