import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiModule } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { BASE_PATH } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { Globals } from './globals';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { A11yModule } from '@angular/cdk/a11y';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';


import { AlertComponent } from './alert/alert.component';
import { DialogComponent } from './shared/dialog/dialog.component';
import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from './shared/loader/loader.component';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { NavigationComponent } from './navigation/navigation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';

import { AreasComponent } from './areas/areas.component';
import { AreaStatsComponent } from './areas/stats/stats.component';
import { UsersComponent } from './users/users.component';

import { AnnouncementsComponent } from './announcements/announcements.component';
import { AnnouncementCreateComponent } from './announcements/create/create.component';

import { CompaniesComponent } from './companies/companies.component';
import { CompaniesListComponent } from './companies/companies-list/companies-list.component';
import { CompanyDetailComponent } from './companies/company-detail/company-detail.component';
import { CompanyDetailQuarterComponent } from './companies/company-detail-quarter/company-detail-quarter.component';

import { EconomicModelComponent } from './economic-model/economic-model.component';
import { EconomicModelCreateComponent } from './economic-model/economic-model-create/economic-model-create.component';
import { EconomicModelListComponent } from './economic-model/economic-model-list/economic-model-list.component';
import { EconomicModelCreateDialogComponent } from './economic-model/economic-model-create-dialog/economic-model-create-dialog.component';
// eslint-disable-next-line max-len
import { EconomicModelAssignEventDialogComponent } from './economic-model/economic-model-assign-dialog/economic-model-assign-dialog.component';
// eslint-disable-next-line max-len
import { EconomicModelDeleteEventDialogComponent } from './economic-model/economic-model-delete-event-dialog/economic-model-delete-event-dialog.component';

import { GamesComponent } from './games/games.component';
import { GameDetailComponent } from './games/game-detail/game-detail.component';
import { GamesListComponent } from './games/games-list/games-list.component';
import { GameCreateComponent } from './games/create/create.component';
import { GameDetailQuarterComponent } from './games/game-detail-quarter/game-detail-quarter.component';
import { GameDetailResultsComponent } from './games/game-detail-results/game-detail-results.component';
import { GameDetailGraphComponent } from './games/game-detail-graph/game-detail-graph.component';
import { GameDetailGraphLineComponent } from './games/game-detail-graph-line/game-detail-graph-line.component';
import { GameDetailGraphBarComponent } from './games/game-detail-graph-bar/game-detail-graph-bar.component';
import { GameDetailCompaniesComponent } from './games/game-detail-companies/game-detail-companies.component';

import { SponsorshipsComponent } from './sponsorships/sponsorships.component';
import { SponsorshipsListComponent } from './sponsorships/sponsorships-list/sponsorships-list.component';
import { SponsorshipCreateComponent } from './sponsorships/sponsorships-create/sponsorships-create.component';

import { TeamsComponent } from './teams/teams.component';
import { TeamListComponent } from './teams/team-list/team-list.component';
import { TeamDetailComponent } from './teams/team-detail/team-detail.component';

import { JwtInterceptor } from './auth/jwt.interceptor';
import { ErrorInterceptor } from './error/error.interceptor';

import { StyleguideComponent } from './styleguide/styleguide.component';
import { fakeBackendProvider } from './auth/fake-backend';

import { TimeFormatPipe } from './shared/pipes/timeformat.pipe';
import { LocalizedDatePipe } from './shared/pipes/localizeddate.pipe';
import { StringFormatPipe } from './shared/pipes/stringformat.pipe';
import { GameNamePipe } from './shared/pipes/gamename.pipe';
import { SafeHtml } from './shared/pipes/safehtml.pipe';
import { DatePipe } from '@angular/common';

import { BracketsComponent } from './brackets/brackets.component';
import { BracketsListComponent } from './brackets/brackets-list/brackets-list.component';
import { BracketDetailComponent } from './brackets/bracket-detail/bracket-detail.component';
import { BracketDetailRoundComponent } from './brackets/bracket-detail-round/bracket-detail-round.component';

import { ResizableModule } from 'angular-resizable-element';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {LocalizationService} from './localization/localization.service';
import {BracketCreateComponent} from './brackets/create/create.component';
import {MatPaginationIntlService} from './shared/paginator-translate/custom-mat-paginator-intl';

// eslint-disable-next-line max-len
import {SponsorshipDeleteDialogComponent} from './sponsorships/sponsorship-delete-dialog/sponsorship-delete-dialog.component';
import {BaseGameCreateComponent} from './common/base.game-create.component';
import { CompetitionsComponent } from './competitions/competitions.component';
import { CompetitionCreateComponent } from './competitions/competition-create/competition-create.component';
import { CompetitionsListComponent } from './competitions/competitions-list/competitions-list.component';
import { CompetitionDetailComponent } from './competitions/competition-detail/competition-detail.component';
import { CompetitionDetailRoundComponent } from './competitions/competition-detail-round/competition-detail-round.component';
import { CompetitionResultsComponent } from './competitions/competition-results/competition-results.component';
import {JaidLoginComponent} from "./login/jaid/jaid-login.component";


const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0
  }
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient, globals: Globals, translate: TranslateService) {
  return new LocalizationService(http, globals, translate);
}

@NgModule({
  declarations: [
    AppComponent,
    AreasComponent,
    UsersComponent,
    GamesComponent,
    BaseGameCreateComponent,
    GameDetailComponent,
    GamesListComponent,
    GameCreateComponent,
    NavigationComponent,
    DashboardComponent,
    DialogComponent,
    BreadcrumbsComponent,
    LoaderComponent,
    LoginComponent,
    JaidLoginComponent,
    SponsorshipsComponent,
    SponsorshipsListComponent,
    SponsorshipCreateComponent,
    SponsorshipDeleteDialogComponent,
    AlertComponent,
    TeamsComponent,
    TeamListComponent,
    TeamDetailComponent,
    RegisterComponent,
    StyleguideComponent,
    AnnouncementsComponent,
    AnnouncementCreateComponent,
    AreaStatsComponent,
    TimeFormatPipe,
    LocalizedDatePipe,
    StringFormatPipe,
    GameNamePipe,
    SafeHtml,
    CompaniesComponent,
    CompaniesListComponent,
    CompanyDetailComponent,
    CompanyDetailQuarterComponent,
    EconomicModelComponent,
    EconomicModelListComponent,
    EconomicModelCreateComponent,
    EconomicModelCreateDialogComponent,
    EconomicModelAssignEventDialogComponent,
    EconomicModelDeleteEventDialogComponent,
    GameDetailQuarterComponent,
    GameDetailResultsComponent,
    GameDetailGraphComponent,
    GameDetailGraphLineComponent,
    GameDetailGraphBarComponent,
    GameDetailCompaniesComponent,
    BracketsComponent,
    BracketCreateComponent,
    BracketDetailComponent,
    BracketDetailRoundComponent,
    BracketsListComponent,
    CompetitionsComponent,
    CompetitionCreateComponent,
    CompetitionsListComponent,
    CompetitionDetailComponent,
    CompetitionDetailRoundComponent,
    CompetitionResultsComponent
  ],
  imports: [
    A11yModule,
    ApiModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CdkTableModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    CdkStepperModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatIconModule,
    MatGridListModule,
    ResizableModule,
    MatPaginatorModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    Globals,
    Title,
    [DatePipe],
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    { provide: BASE_PATH, useValue: environment.backendUrl },
    // provider for paginator translation
    { provide: MatPaginatorIntl, useClass: MatPaginationIntlService, },
    // provider used to create fake backend
    environment.production ? {provide: 'fakeBackendProvider', useValue: null} : fakeBackendProvider,
    LocalizationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
