import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'stringFormat',
    pure: false
})
export class StringFormatPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string, ...args: any[]): string {
        if (!args || !args.length) {
            return value;
        }
        const values = args[0];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                value = value.replace('{{ ' + key + ' }}', values[key]);
            }

        }
        return value;
    }
}
