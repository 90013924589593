<div class="login-container h-100">
    <div class="container-flex flex-column align-items-center justify-content-center h-100">
        <img alt="{{'juniorAchievementLogo' | translate}}" class="ja-logo mt-4 mb-1"
             src="../../../assets/images/JA_White_Horizontal.svg"
             width="225"/>
        <img alt="{{'JATitanLogo' | translate}}" class="titan-logo" src="../../../assets/images/Titan-Logo.png"
             width="375"/>
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm" class="shadow-box py-3 px-5">
            <h2 class="mt-0 mb-3 text-center">{{'login' | translate}}</h2>
            <div class="form-group mb-4">
                <label class="mb-3" for="username">{{'username' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control mb-0 w-100" formControlName="username"
                       type="text"/>
                <div *ngIf="submitted && loginForm.controls['username'].errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls['username'].errors.required">{{'usernameRequired' | translate}}</div>
                </div>
            </div>
            <div class="form-group mb-4">
                <label class="mb-3" for="password">{{'password' | translate}}</label>
                <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control mb-0 w-100" formControlName="password"
                       type="password"/>
                <div *ngIf="submitted && loginForm.controls['password'].errors" class="invalid-feedback">
                    <div *ngIf="loginForm.controls['password'].errors.required">{{'passwordRequired' | translate}}</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="button-primary mb-3 mr-0">{{'login' | translate}}</button>
                <img *ngIf="loading" class="pl-3"
                     src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            </div>
        </form>
    </div>
</div>
