<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1>{{ 'brackets' | translate }}</h1>
        </div>
        <div *ngIf="currentUser.role !== 'student'" class="container-flex align-items-center">
            <button [routerLink]="['/brackets/create']"
                    class="button-primary">{{ 'createNewBracket' | translate }}</button>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="dataSource?.data?.length > 0" [ngClass]="{'d-none': loading}" class="row">
    <table #gameTable [dataSource]="dataSource" class="mat-selectable" mat-table>
        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>{{ 'name' | translate }}</th>
            <td *matCellDef="let game" mat-cell><span class="column-width">{{game.name}}</span></td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>{{ 'status' | translate }}</th>
            <td *matCellDef="let game" mat-cell>{{ game.status | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="current_round">
            <th *matHeaderCellDef mat-header-cell>{{ 'round' | translate }}</th>
            <td *matCellDef="let game" mat-cell><span class="column-width">{{game.current_round}}</span></td>
        </ng-container>

        <!--             <ng-container matColumnDef="date">
                      <th mat-header-cell *matHeaderCellDef > Date </th>
                      <td mat-cell *matCellDef="let game">{{ game?.created | date:'shortTime' }}</td>
                    </ng-container> -->

        <!--             <ng-container matColumnDef="companies">
                      <th mat-header-cell *matHeaderCellDef > # of Companies </th>
                      <td mat-cell *matCellDef="let game"> {{game.companies.length}} </td>
                    </ng-container> -->

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" [routerLink]="[ '/brackets/detail', row.id ]"
            mat-row></tr>
    </table>
</div>

<ng-container *ngIf="dataSource?.data?.length === 0 && loading === false">
    <div [ngSwitch]="currentUser.role" class="flex-container empty-container">
        <div *ngSwitchCase="'student'" class="p-5 text-center">
            <p>{{ 'noBracketsToDisplay' | translate }}</p>
        </div>
        <div *ngSwitchDefault class="p-5 text-center">
            <p>{{ 'noBracketsToDisplay' | translate }}</p>
            <p>{{ 'clickToCreateNewBracket' | translate }}</p></div>
    </div>
</ng-container>

