import { Component, OnInit, Input } from '@angular/core';

import { Game, ScenarioGameOut, Company, CompanyQuarterResult } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {TranslateService} from '@ngx-translate/core';
import * as d3 from 'd3';

export interface QuarterData {
  'company_id': number;
  'units_sold': number;
  'quarter':    number;
}

export interface ResponseObj {
  response: number;
  y0: number;
  yp0: number;
  y1: number;
  yp1: number;
  isFirst: boolean;
  isLast: boolean;
}

@Component({
  selector: 'app-game-detail-graph-bar',
  templateUrl: './game-detail-graph-bar.component.html',
  styleUrls: ['./game-detail-graph-bar.component.scss']
})
export class GameDetailGraphBarComponent implements OnInit {

  @Input() game: Game;
  @Input() title: string;
  @Input() display_companies: any[];
  @Input() combinedCompanyResults: any[];
  @Input() scenarioQuarter: number;

  companyLegends: any[];

  constructor(private readonly translateService: TranslateService) { }

  ngOnInit() {

    this.companyLegends = Array.from(new Set(this.display_companies.map(a => a.company_id)))
    .map(id => {
      return this.display_companies.find(a => a.company_id === id);
    });

    // console.log('this.companyLegends', this.companyLegends);

    // const data = this.data;
    const data = this.display_companies;
    // console.log('const data', data);

    // set width/height/margins
    const margin = {top: 60, right: 40, bottom: 60, left: 60};
    const width = 960 - margin.left - margin.right;
    const height = 520 - margin.top - margin.bottom;

    // get map of all company ids
    const unique = [...new Set(data.map(item => item.company_id))];
    const colors = d3.scaleOrdinal().range(unique);

    // console.log('data.length', data.length);
    // set scales - make bars fixed size if under certain number of quarters
    let rangeTop = width;
    let rangeDivisor = 2;

    if (this.game.current_quarter <= 8) {
      rangeTop = data.length * 50;
      rangeDivisor = 4;
    }

    const xscale = d3.scaleBand().rangeRound([0, rangeTop]).padding(0.1);


    const yscale = d3.scaleLinear()
      .rangeRound([height, 0]);

    // set axes
    const xaxis = d3.axisBottom(xscale)
      .scale(xscale);

    const yaxis = d3.axisLeft(yscale)
      .scale(yscale)
      .ticks(6)
      .tickFormat(d3.format('.1p')); // **

    // setup #chart element
    const svg = d3.select('#chart').append('svg')
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      // .attr('width', width + margin.left + margin.right)
      // .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left  + ',' + margin.top + ')');

    // nest the data by quarter before transforming it
    const dataGroup =
      d3.nest()
      .key(function(d) {return (d as any).quarter; })
      .entries(data);
      // .object(data);
      // .map(data);

      // console.log('dataGroup', dataGroup);

      const dataGrouped = [];

      // transform server data into something we can use for a percentage bar chart
      dataGroup.forEach(function(d, index) {
        // console.log('d', d);
        const newObj = {};
        const newArr = [];

        // store quarter in its own array within the new temp array
        newArr.push(['quarter', index + 1]);

        d.values.forEach(function(p) {

          // console.log('p', p);
          // remove quarter from object
          // delete p.quarter;

          // new custom object with only the fields we want
          const obj: any = {};
          obj.company_id = p.company_id;
          obj.units_sold_by_demographic = p.units_sold_by_demographic.reduce(function(a, b) {
            return a + b;
          }, 0);
          obj.trade_dress = p.trade_dress;


          // return object's values, push to temp array
          newArr.push(Object.values(obj));
          // console.log('newArr', newArr);

          // convert arrays into a new object representing all company sales for the quarter
          // newObj = Object.fromEntries(newArr); // only es2019

          // turn multidimensional array back into an object
          for ( const arr of newArr) {
            // console.log(arr);
          }

          for (const [company, units_sold_by_demographic] of newArr) {
            // let sold = 0;
            // console.log('units_sold_by_demographic', p.quarter + ' ' + p.company_id + ' ' + units_sold_by_demographic);
            // units_sold_by_demographic.forEach(element => {
            //   console.log('element', element);
            //   sold  = sold + element;
            //   console.log('sold', sold);
            // });
          //   const sold = units_sold_by_demographic.reduce(function(a, b) {
          //   return a + b;
          // }, 0);
            newObj[company] = units_sold_by_demographic;
          }
        });
        dataGrouped.push(newObj);

        console.log('dataGrouped', dataGrouped);

      });

      // map column headers to colors (except for 'quarter')
      colors.domain(d3.keys(dataGrouped[0]).filter(function(key) { return key !== 'quarter'; }));

      // add a 'responses' parameter to each row that has the height percentage values for each rect
      dataGrouped.forEach(function(pd) {
        // console.log('pd', pd);

        let y0 = 0;

        // colors.domain() is an array of the column headers (text)
        // pd.responses will be an array of objects with the column header
        // and the range of values it represents
        pd.responses = colors.domain().map(function(response) {
          // console.log('response', response);
          const responseobj: ResponseObj = {response: +response, y0: y0, yp0: y0, y1: 0, yp1: 0, isFirst: false, isLast: false};
          y0 += +pd[response];

          responseobj.y1 = y0;
          responseobj.yp1 = y0;

          return responseobj;
        });

        // y0 is now the sum of all the values in the row for this category
        // convert the range values to percentages
        pd.responses.forEach(function(d, index, array) {
          d.yp0 /= y0;
          d.yp1 /= y0;


          // console.log('pd index', index);
          // if () { d.isFirst = true; }
          if (!array[index + 1]) { d.isLast = true; }

        });
        // save the total
        pd.totalresponses = pd.responses[pd.responses.length - 1].y1;

        // console.log('pd', pd);
      });

      // sort by the value in 'Right Direction'
      // parsedata.sort(function(a, b) { return b.responses[0].yp1 - a.responses[0].yp1; });

      // ordinal-ly map categories to x positions
      xscale.domain(dataGrouped.map(function(d) {
        return d.quarter; }));

      // add the x axis and position its labels
      svg.append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + (height + 10) + ')')
        .call(xaxis)
        .selectAll('text')
        .attr('y', 5)
        .attr('x', (xscale.bandwidth() / rangeDivisor))
        .attr('dy', '.5em')
        .style('text-anchor', 'start');

      // add the y axis
      svg.append('g')
        .attr('class', 'y axis')
        .attr('transform', 'translate(' + (margin.left / 2) + ',0)')
        .call(yaxis);

        // gridlines in y axis function
      function make_y_gridlines() {
          return d3.axisLeft(yscale)
            .ticks(6);
      }

      // add the Y gridlines
      svg.append('g')
          .attr('class', 'grid')
          .attr('transform', 'translate(' + (margin.left) / 2 + ',0)')
          .call(make_y_gridlines()
            .tickSize(-width)
            // .tickFormat('')
          );

      // create svg groups ("g") and place them
      const category = svg.selectAll('.category')
        .data(dataGrouped)
        .enter().append('g')
        .attr('class', 'category')
        .attr('transform', function(d) {
          // console.log('xscale(d.quarter)', xscale(d.quarter));
          return 'translate(' + (xscale(d.quarter) + margin.left / 2) + ',0)';
        });

      const rectPadding = 10;

      // const barWidth = Math.max(1, 0.9 * xscale.bandwidth());
      // console.log('xscale.bandwidth()', xscale.bandwidth());

      // draw the rects within the groups
      category.selectAll('rect')
        .data(function(d) {
          // console.log('d', d);
          return d.responses;
        })
        .enter().append('rect')
        .attr('class', function(d) { return (d as any).isLast; })
        .attr('rx', 5) // border radius
        .attr('ry', 5) // border radius
        .attr('width', xscale.bandwidth())
        // .attr('width', barWidth)
        .attr('y', function(d: ResponseObj) {
          let returnCoordinate = 0;
          const rectHeight = yscale(d.yp0) - yscale(d.yp1);

          if ( d.isLast === true && rectHeight < rectPadding ) {
            returnCoordinate = Math.abs(((yscale(d.yp0) + rectPadding) - yscale(0)));
          } else {
            returnCoordinate = Math.abs((yscale(d.yp0) - yscale(0)));
          }

          return returnCoordinate;
        })
        .attr('height', function(d: ResponseObj)   {
          let rectHeight = yscale(d.yp0) - yscale(d.yp1);

          // scale
          if ( (d.isLast === true && rectHeight < rectPadding) || ( d.isLast === false && rectHeight < (height - (rectPadding)) ) ) {
            rectHeight = (rectHeight + rectPadding);
          }
          return rectHeight;
        })
        .style('fill', (d: ResponseObj) => {
          // return String(colors(d.response));
          const result = data.find(obj => {
            return obj.company_id === d.response;
          });
          let color = result.trade_dress.logos[0].color_id;
          // console.log('bar color', color);
          if ( color === 'FFFFFF' || color === 'ffffff' || color === 'F7F7F7FF') {
            color = '999999';
          }
          return '#' + color + '';
        });

        // text label for the x axis
        svg.append('text')
          .attr('transform', 'translate(' + ((width / 2) + margin.left) + ' ,' + (height + margin.bottom - 5) + ')')
          .style('text-anchor', 'middle')
          .style('fill', '#999999')
          .style('font-size', '12px')
          .text(this.translateService.instant('quarter'));

        // text label for the y axis
        svg.append('text')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0 - margin.left)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .style('text-anchor', 'middle')
          .style('font-size', '12px')
          .style('fill', '#999999')
          .text('% ' + this.translateService.instant('marketShare'));

      // position the legend elements
      // const legend = svg.selectAll('.legend')
      //   .data(colors.domain())
      //   .enter().append('g')
      //   .attr('class', 'legend')
      //   .attr('transform', function(d, i) {
      //     return 'translate(-' + (width + (i * -120)) + ', -' + margin.top + ')';
      //   });

    // 	legend.append("rect")
    // 		.attr("x", width - 18)
    // 		.attr("width", 18)
    // 		.attr("height", 18)
    // 		.style("fill", colors);

      // legend.append('text')
      //   .attr('x', width + 10)
      //   .attr('y', 9)
      //   .attr('dy', '.5em')
      //   .attr('font-weight', 'bold')
      //   .style('fill', function(d: any) {
      //     return String(colors(d));
      //   })
      //   .style('text-anchor', 'start')
      //   .text(function(d) { return d; });

    d3.select(self.frameElement).style('height', (height + margin.top + margin.bottom) + 'px');

  }
}
