import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-announcement-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})
export class AnnouncementCreateComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
