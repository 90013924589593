import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-competitions',
    templateUrl: './competitions.component.html',
    styleUrls: ['./competitions.component.scss']
})
export class CompetitionsComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
