import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BracketService, BracketBaseInDBWithGames, ScenarioService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';

import { User } from '../../users/user.interface';
import { AuthenticationService } from '../../auth/authentication.service';

import { GameWithCompanies } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { GameStatus } from '../../games/game-status.enum';

import {MatTableDataSource, MatTable} from '@angular/material/table';


@Component({
  selector: 'app-bracket-detail',
  templateUrl: './bracket-detail.component.html',
  styleUrls: ['./bracket-detail.component.scss']
})
export class BracketDetailComponent implements OnInit {

  @ViewChild('bracketTable') table: MatTable<any>;

  loading = false;
  currentUser: User;
  bracketId: number;
  bracket: BracketBaseInDBWithGames;
  games: [GameWithCompanies];
  states = GameStatus;
  status: string;

  // eslint-disable-next-line max-len
  displayedColumns: string[] = ['name', 'bracket_round_number', 'status', 'game_start', 'turn_advancement', 'quarter_duration', 'scenario', 'quarters', 'starting_cash', 'companies'];
  // dataSource = { data: [] };
  dataSource: MatTableDataSource<any>;

  constructor(
    private bracketService: BracketService,
    private scenarioService: ScenarioService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource;
  }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.route.params.subscribe(() => {
        this.bracketId = +this.route.snapshot.paramMap.get('id');
        this.getBracket(this.bracketId);
    });
  }

  getBracket(id: number) {
    this.loading = true;

    this.bracketService.getBracketApiBracketBracketIdGet(id)
      .subscribe(
        bracket => {
          console.log('bracket', bracket);
          this.bracket = bracket;

          // Get "Status" of Bracket by checking status of all Games within a Bracket
          let tempStatus = '';
          const totalStatus = [];
          let itemsProcessed = 0;

          bracket.games.forEach((game, index, array) => {
            tempStatus = game.is_active === false && game.is_complete === false ? this.states.pending :
            game.is_complete === true ? this.states.complete : this.states.inProgress;

            totalStatus.push(tempStatus);

            if ( game.scenario_id !== undefined && game.scenario_id !== null ) {
              this.scenarioService.readScenarioByIdApiScenarioScenarioIdGet(game.scenario_id)
                .subscribe(
                  scenario => {
                    (game as any).scenario_name = scenario.title;
                    console.log(scenario);
                  });
            }

            itemsProcessed++;
              if ( itemsProcessed === array.length) {
                this.setPage(bracket.games);

              }
          });

          function getAllIndexes(arr, val) {
            const indexes = [];
            let i;
            for ( i = 0; i < arr.length; i++ ) {
                if (arr[i] === val) {
                    indexes.push(i);
                }
            }
            return indexes;
          }

          if (getAllIndexes(totalStatus, this.states.pending).length === totalStatus.length) {
            this.status = this.states.pending;
          } else if ( getAllIndexes(totalStatus, this.states.complete).length < totalStatus.length ) {
            this.status = this.states.inProgress;
          } else {
            this.status = this.states.complete;
          }

          // console.log(getAllIndexes(totalStatus, this.states.pending));

          this.loading = false;

        },
        error => {
          console.log(error);
        }
      );
  }

  setPage(results) {
    this.dataSource.data = results;
  }
}
