import {Component, OnInit} from '@angular/core';

import {Announcement} from './announcement.interface';

const tempAnnoucenements = [
    // eslint-disable-next-line max-len
    {
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas integer eget aliquet nibh.',
        date: 'October 29, 2019', author: 'Joanna Doe'
    },
    // eslint-disable-next-line max-len
    {
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas integer eget aliquet nibh.',
        date: 'October 29, 2019', author: 'Jon Doe'
    }
];

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
    styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

    announcements: Announcement[];

    constructor() {
    }

    ngOnInit() {
        this.announcements = tempAnnoucenements;
    }

}
