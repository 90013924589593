import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(value: any): string {
        return (value === '' || value == null) ? '' : this.getFormattedDate(value);
    }

    private getFormattedDate(value: string): string {
        let formattedDate;
        const lang: string = this.translateService.getBrowserCultureLang();
        try {
            formattedDate = formatDate(value, 'mediumDate', lang);
        } catch (ex) {
            try {
                if (lang.includes('-')) {
                    formattedDate = formatDate(value, 'mediumDate', lang.split('-')[0]);
                }
            } finally {
            }
        } finally {
            if (!formattedDate) {
                formattedDate = formatDate(value, 'mediumDate', 'en');
            }
        }
        return formattedDate;
    }
}
