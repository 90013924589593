<h1 mat-dialog-title>Warning</h1>
<div mat-dialog-content style="padding-bottom: 2em">
    <div *ngIf="data.scenarioEvent">
        <p>{{ 'deleteScenarioAlert' | translate:{scenario_event_title: data.scenarioEvent.title} }}</p>
    </div>
    <div *ngIf="data.scenarios">
        <p>{{ 'deleteScenariosAlert' | translate }}</p>
        <table [dataSource]="data.scenarios" class="mat-elevation-z8" mat-table>
            <ng-container matColumnDef="id">
                <th *matHeaderCellDef mat-header-cell>{{ 'id' | translate }}</th>
                <td *matCellDef="let element" mat-cell> {{element.id}} </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th *matHeaderCellDef mat-header-cell>{{ 'title' | translate }}</th>
                <td *matCellDef="let element" mat-cell> {{element.title}} </td>
            </ng-container>
            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>{{ 'cancel' | translate }}</button>
    <button [mat-dialog-close]="true" cdkFocusInitial mat-button>{{ 'delete' | translate }}</button>
</div>