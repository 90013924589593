<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1 class="d-inline-block">{{ bracket?.name }}</h1>
            <h3 class="role-status d-inline-block">({{ status | translate }})</h3>
        </div>
        <div *ngIf="bracket?.id && status !== states.complete" class="container-flex align-items-center">
            <button [routerLink]="['/brackets/detail/round/', bracket.id ]"
                    class="button-primary">{{ 'bracketSettings' | translate }}</button>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="dataSource.data.length > 0" [ngClass]="{'d-none': loading}" class="row">
    <table #bracketTable [dataSource]="dataSource" class="mat-selectable" mat-table>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell> {{ 'nameRoundGame' | translate }}</th>
            <td *matCellDef="let game" mat-cell><span class="column-width">{{ game.name | gameName }}</span></td>
        </ng-container>

        <ng-container matColumnDef="bracket_round_number">
            <th *matHeaderCellDef mat-header-cell> {{ 'round' | translate }}</th>
            <td *matCellDef="let game" mat-cell> {{game.bracket_round_number}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell> {{ 'status' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell>{{ game.is_active === false && game.is_complete === false ? (states.pending | translate) : game.is_complete === true ? (states.complete | translate) : (states.inProgress | translate) }}</td>
        </ng-container>

        <ng-container matColumnDef="game_start">
            <th *matHeaderCellDef mat-header-cell> {{ 'gameStart' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell> {{ !game.start_time ? ('manual' | translate) : 'automatic' | translate }} </td>
        </ng-container>

        <ng-container matColumnDef="turn_advancement">
            <th *matHeaderCellDef mat-header-cell> {{ 'turnCompletion' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell> {{ game.turn_advancement === 'Timed' ? ('automatic' | translate) : game.turn_advancement.toLowerCase() | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="quarter_duration">
            <th *matHeaderCellDef mat-header-cell> {{ 'quarterDuration' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell> {{game.quarter_duration ? game.quarter_duration === 1440 ? ('24Hours' | translate) : game.quarter_duration : 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="scenario">
            <th *matHeaderCellDef mat-header-cell>  {{ 'scenario' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell> {{game.scenario_id === null ? ('none' | translate) : (game.scenario_name | translate) }} </td>
        </ng-container>

        <ng-container matColumnDef="quarters">
            <th *matHeaderCellDef mat-header-cell> {{ 'quarters' | translate }}</th>
            <td *matCellDef="let game"
                mat-cell> {{ 'currentGameQuarterOfGameDuration' | translate:{
                current_quarter: game.current_quarter + (game.is_active === false && game.is_complete === false ? 0 : 1),
                game_duration: game.duration
            } }}</td>
        </ng-container>

        <ng-container matColumnDef="starting_cash">
            <th *matHeaderCellDef mat-header-cell> {{ 'difficulty' | translate }}</th>
            <td *matCellDef="let game" mat-cell> {{game.starting_cash  | currency:'USD':'symbol':'4.0-0' }} </td>
        </ng-container>

        <ng-container matColumnDef="companies">
            <th *matHeaderCellDef mat-header-cell> {{ 'numOfCompanies' | translate }}</th>
            <td *matCellDef="let game" mat-cell> {{game.companies.length}} </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" [routerLink]="[ '/games/detail', row.id ]" mat-row></tr>

    </table>
</div>

<ng-container *ngIf="dataSource.data.length === 0 && loading === false">
    <div class="flex-container empty-container">
        <div class="p-5 text-center"><p>{{ 'bracketContainsNoGames' | translate }}</p></div>
    </div>
</ng-container>

