import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {LocalizationService} from './localization/localization.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    route: string;
    loading: boolean;

    constructor(
        public router: Router,
        private titleService: Title,
        public translate: TranslateService,
        public localization: LocalizationService) {

        this.loading = false;
        translate.setDefaultLang(localization.getBrowserLanguage());
        translate.use(localization.getBrowserLanguage()).subscribe(() => {
            this.loading = true;
        });
    }

    ngOnInit() {
        this.titleService.setTitle(`JA Titan | ${environment.name}`);

        this.router.events.subscribe(event => {
            // console.log('router', this.router);
        });
    }
}
