<header>
    <div class="container container-flex justify-content-between">
        <div>
            <!--<h1 class="d-inline-block">Dashboard</h1>-->
            <h1 class="d-inline-block">{{ 'dashboard' | translate }}</h1>
            <h3 *ngIf="currentUser.role" class="role-status d-inline-block">
                ({{ currentUser.role.toLowerCase() | translate }})</h3>
        </div>
        <div class="container-flex align-items-center">
            <button (click)="onPlayGame()" class="button-primary">{{ 'playTheGame' | translate  }}</button>
        </div>
    </div>
</header>

<div *ngIf="showEmptyMessage === true" class="flex-container empty-container">
    <div class="p-5 text-center"><p>{{ 'nothingHereYet' | translate  }}</p></div>
</div>

<!-- Game Detail - Teachers-->
<ng-container *ngFor="let game of games">
    <div *ngIf="game.is_active === true" class="container shadow-box">
        <app-game-detail [controls]="false" [currentGame]="game"></app-game-detail>
    </div>
</ng-container>

<div *ngIf="loading" class="d-flex justify-content-center h-100">
    <mat-spinner></mat-spinner>
</div>

<!-- Area Stats - National and Area Admins -->
<div *ngIf="currentUser.role === ('admin_national' || 'admin_area')" class="container shadow-box">
    <app-area-stats></app-area-stats>
</div>  