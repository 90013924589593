import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../globals';

@Injectable({
    providedIn: 'root'
})
export class AreaService {

    private configUrl: string;

    constructor(
        private http: HttpClient,
        private globals: Globals
    ) {
        this.configUrl = globals.configUrl;
    }

    getUsers() {
        return this.http.get<any>(this.configUrl + 'planets?_format=json');
    }


}
