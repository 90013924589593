<header>
    <div [ngSwitch]="mode" class="container container-flex flex-wrap justify-content-around p-0">
        <div *ngSwitchCase="'create-competition'">
            <h1 class="d-inline-block">{{ 'createNewCompetition' | translate }}</h1>
        </div>
        <div *ngSwitchDefault>
            <h1 class="d-inline-block">{{ 'competitionCreation' | translate }}</h1>
        </div>
    </div>
</header>
<div class="container p-0 game-form-{{mode}}">
    <form [formGroup]="formGroup">
        <mat-horizontal-stepper #stepper (selectionChange)="stepChange($event)" [@.disabled]="true"
                                formArrayName="formArray" linear>
            <mat-step [stepControl]="formArray.get([0])" formGroupName="0">
                <div class="my-4 pt-5">
                    <ng-template matStepLabel>{{ 'competitionSettings' | translate }}</ng-template>
                    <div class="example-container">
                        <h2 class="has-tooltip">{{ 'competitionName' | translate }}</h2>
                        <mat-form-field [style.width.px]=250 floatLabel="auto">
                            <mat-label></mat-label>
                            <input #game_name [maxlength]="nameMaxLength" formControlName="name" id="game_name" matInput
                                   placeholder="{{ 'competitionNamePlaceHolder' | translate }}">
                        </mat-form-field>
                        <mat-error *ngIf="formArray['controls'][0].get('name').errors?.maxlength">
                            {{ 'nameMaxLengthAlert' | translate:{max_length: nameMaxLength} }}
                        </mat-error>
                    </div>
                    <mat-error
                            *ngIf="formArray['controls'][0].get('name').dirty && (
                                formArray['controls'][0].get('name').errors?.required ||
                                formArray['controls'][0].get('name').errors?.whitespace
                                )"
                            class="test">
                        {{ 'competitionNameRequired' | translate }}
                    </mat-error>
                    <ng-container>
                        <div class="container-flex flex-wrap">
                            <div class="column pl-0">
                                <label for="round_count">{{ 'competitionRoundCount' | translate }}</label>
                                <select #round_count [style.width.px]=320 formControlName="round_count"
                                        id="round_count">
                                    <option *ngFor="let option of roundCountOptions"
                                            value="{{ option.value }}">{{ option.value }}</option>
                                </select>
                            </div>
                            <div class="column pl-0">
                                <label for="max_game_company_count">{{ 'maxCompaniesPerGame' | translate }}</label>
                                <select #max_game_company_count [style.width.px]=310
                                        formControlName="max_game_company_count" id="max_game_company_count">
                                    <option *ngFor="let option of maxCompaniesPerGameOptions"
                                            value="{{ option.value }}">{{ option.value }}</option>
                                </select>
                            </div>
                        </div>
                    </ng-container>
                    <h2>{{ 'startSettings' | translate }}</h2>
                    <div>
                        <label class="mr-2">{{ 'gameStart' | translate }}</label>
                        <div class="button-group mb-4">
                            <div>
                                <input [value]="true" class="radio-custom"
                                       formControlName="automatic_start" id="auto-start-enabled" type="radio">
                                <label class="radio-custom-label"
                                       for="auto-start-enabled">{{ ('automatic' | translate) }}</label>
                            </div>
                            <div>
                                <input [value]="false" class="radio-custom"
                                       formControlName="automatic_start" id="auto-start-disabled" type="radio">
                                <label class="radio-custom-label"
                                       for="auto-start-disabled">{{ ('manual' | translate) }}</label>
                            </div>
                        </div>
                        <ng-container *ngIf="formGroup.value.formArray[0]['automatic_start']">
                            <div class="container-flex flex-wrap">
                                <div class="column pl-0">
                                    <label for="date">{{ 'startDate' | translate }}</label>
                                    <mat-form-field>
                                        <input [matDatepicker]="picker1" [min]="minDate" formControlName="start_date"
                                               id="date" matInput>
                                        <mat-datepicker-toggle [for]="picker1" matSuffix></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error *ngIf="formArray['controls'][0].get('start_date').errors?.required">
                                        {{ 'startDateRequired' | translate }}
                                    </mat-error>
                                </div>
                                <div class="column pl-0">
                                    <label class="has-tooltip" for="time">{{ 'startTime' | translate }}
                                        <button #start_timeTooltip="matTooltip" mat-raised-button
                                                matTooltip="{{ 'startGameTooltip' | translate }}">
                                            ?
                                        </button>
                                    </label>
                                    <input formControlName="start_time" id="time" max="24:00:00" min="00:00:00"
                                           step="300" type="time"
                                           value="{{ hours }}:{{ minutes }}:00">
                                    <mat-error *ngIf="formArray['controls'][0].controls['start_time'].errors">
                                        {{ 'startTimeRequired' | translate }}
                                    </mat-error>
                                </div>
                                <div class="column pl-0">
                                    <label for="round_duration">{{ 'timeBetweenRounds' | translate }}</label>
                                    <select #round_duration [style.width.px]=310 formControlName="round_duration"
                                            id="round_duration">
                                        <option [ngValue]="0">{{ 'selectTimeBetweenRounds' | translate }}</option>
                                        <option *ngFor="let roundDurationOption of roundDurationOptions"
                                                value="{{ roundDurationOption.value }}">{{ roundDurationOption.name }} {{ 'minutes' | translate }}</option>
                                    </select>
                                    <mat-error
                                            *ngIf="formArray['controls'][0].get('round_duration').value === 0 && formArray['controls'][0].get('round_duration').dirty">
                                        {{ 'timeBetweenRoundsRequired' | translate }}
                                    </mat-error>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <h2 class="has-tooltip">{{ 'turnSettings' | translate }}
                        <button #turnsTooltip="matTooltip" mat-raised-button
                                matTooltip="{{ 'automaticQuartersDescription' | translate }} {{ 'manualQuartersDescription' | translate }}">
                            ?
                        </button>
                    </h2>
                    <div>
                        <label>{{ 'turnCompletion' | translate }}</label>
                        <div class="button-group mb-4">
                            <div *ngFor="let completionOption of completionOptions;">
                                <input class="radio-custom" formControlName="turn_advancement"
                                       id="completion-{{ completionOption.name }}" type="radio" value="{{ completionOption.value }}">
                                <label class="radio-custom-label"
                                       for="completion-{{ completionOption.name }}">{{ completionOption.name | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="formGroup.value.formArray[0]['turn_advancement'] === 'Timed'">
                        <div class="container-flex flex-wrap">
                            <div class="column pl-0">
                                <label for="quarter_duration">{{ 'quarterTime' | translate }}</label>
                                <select #quarter_duration [style.width.px]=320 formControlName="quarter_duration"
                                        id="quarter_duration">
                                    <option [ngValue]="0">{{ 'selectQuarterDuration' | translate }}</option>
                                    <option *ngFor="let quarterDurationOption of quarterDurationOptions"
                                            value="{{ quarterDurationOption.value }}">{{ quarterDurationOption.name }} {{ quarterDurationOption.value === 1440 ? ('day' | translate) : ('minutes' | translate) }}</option>
                                </select>
                                <mat-error
                                        *ngIf="formArray['controls'][0].get('quarter_duration').value === 0 && formArray['controls'][0].get('quarter_duration').dirty">
                                    {{ 'quarterDurationRequired' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div>
                    <h2 class="has-tooltip">{{ 'gameSettings' | translate }}
                        <button #tooltip="matTooltip" attr.aria-label="{{ 'gameSettingsButton' | translate }}"
                                mat-raised-button
                                matTooltip="{{ 'customGameDescription' | translate }} {{ 'scenarioGameDescription' | translate }}">
                            ?
                        </button>
                    </h2>
                    <div class="mb-4">
                        <mat-button-toggle-group #gameTypeValue [(value)]="gameType" aria-label="Game Type"
                                                 class="mb-2">
                            <mat-button-toggle (change)="gameTypeChanged($event)"
                                               *ngFor="let gameTypeOption of gameTypeOptions;"
                                               [aria-label]="gameTypeOption.label"
                                               [value]="gameTypeOption.value">{{ gameTypeOption.label | translate }}</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <ng-container *ngIf="gameType === 'custom'">
                        <label>{{ 'cashOnHand' | translate }}</label>
                        <div class="button-group button-group-auto label-difficulty mb-5">
                            <div *ngFor="let cashOption of cashOptions;">
                                <input class="radio-custom" formControlName="starting_cash"
                                       id="cash-{{ cashOption.name }}" type="radio"
                                       value="{{ cashOption.value }}">
                                <label class="radio-custom-label"
                                       for="cash-{{ cashOption.name }}">{{ cashOption.name}}</label>
                            </div>
                        </div>
                        <label>{{ 'duration' | translate }}</label>
                        <div class="button-group button-group-auto label-difficulty mb-5">
                            <div *ngFor="let durationOption of durationOptions;">
                                <input class="radio-custom" formControlName="duration"
                                       id="duration-{{ durationOption.value }}"
                                       type="radio" value="{{ durationOption.value }}">
                                <label class="radio-custom-label" for="duration-{{ durationOption.value }}">
                                    {{ durationOption.value}} {{ 'quarters' | translate }}<br>
                                    ({{ durationOption.value / 4 }} {{ (durationOption.value / 4) > 1 ? ('years' | translate) : ('year' | translate) }}
                                    )
                                </label>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="gameType === 'scenario'">
                        <div class="container-flex">
                            <div class="column" style="min-width: 400px;">
                                <label class="has-tooltip" for="scenarios">
                                    {{ 'scenarioGameSettingsButton' | translate }}
                                    <button #tooltip="matTooltip" attr.aria-label="{{ 'gameSettingsButton' | translate }}"
                                            mat-raised-button
                                            matTooltip="{{ 'selectScenarioList' | translate }}">?
                                    </button>
                                </label>
                                <select (change)="updateScenario($event.target)" [style.width.px]=290
                                        formControlName="scenario_id" id="scenarios">
                                    <option [ngValue]="null">{{ 'selectScenario' | translate }}</option>
                                    <option *ngFor="let scenarioOption of scenarioOptions" [attr.data-scenario]="scenarioOption | json"
                                            [value]="scenarioOption.id">{{ scenarioOption?.title | translate }}</option>
                                </select>
                                <mat-error *ngIf="formArray['controls'][0].get('scenario_id').dirty && formArray['controls'][0].get('scenario_id').value === null && gameType === 'scenario'"
                                           class="mb-5">
                                    {{ 'scenarioIsRequired' | translate }}
                                </mat-error>
                            </div>
                            <div *ngIf="selectedScenario !== undefined" class="column">
                                <div><h3 class="mb-0">{{ selectedScenario?.title | translate}}
                                    ({{ scenarioDifficulty?.difficulty | translate}})</h3></div>
                                <div><p class="my-0">{{ selectedScenario?.duration }}
                                    {{ 'quarters' | translate }}
                                    , {{ selectedScenario.starting_cash | currency:'USD':'symbol':'4.0-0' }}</p></div>
                                <div><p class="my-0">{{ selectedScenario?.description | translate }}</p></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div>
                    <button (click)="formStepper(1)" class="button-primary" mat-button>{{ 'next' | translate }}</button>
                </div>
            </mat-step>

            <!-- Select Users -->
            <mat-step [stepControl]="formArray.get([1])" formGroupName="1">
                <ng-template matStepLabel>{{ 'addPlayers' | translate }}</ng-template>
                <div class="row mt-5">
                    <h2>{{ 'selectPlayersForCompetition' | translate }}</h2>
                </div>
                <div>
                    <select (change)="updateClass($event.target.value)" id="classes">
                        <option [ngValue]="null">{{ 'selectClass' | translate }}</option>
                        <option *ngFor="let cl of classes" value="{{ cl.id }}">{{ cl.name }}</option>
                    </select>
                </div>

                <div (resizeEnd)="onUserResizeEnd($event)"
                     [enableGhostResize]="true"
                     [ngStyle]="{'height' : userBoxHeight}"
                     class="row container-flex mwlResizable userResizable"
                     mwlResizable
                >
                    <div class="column">
                        <div class="team-selection-table row h-100">
                            <table [dataSource]="userDataSource" class="create-table" mat-table>
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                    <th *matHeaderCellDef mat-header-cell>
                                        <mat-checkbox (change)="$event ? masterUserToggle() : null"
                                                      [aria-label]="checkboxUserLabel()"
                                                      [checked]="getUserSelection()?.hasValue() && isAnyUserSelected()"
                                                      [indeterminate]="getUserSelection()?.hasValue() && !isAllUserSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td *matCellDef="let row" [ngClass]="{'cell-selected': getUserSelection()?.isSelected(row)}"
                                        mat-cell>
                                        <mat-checkbox (change)="$event ? getUserSelection()?.toggle(row) : null"
                                                      (click)="$event.stopPropagation()"
                                                      [aria-label]="checkboxUserLabel(row)"
                                                      [checked]="getUserSelection()?.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="sub">
                                    <th *matHeaderCellDef mat-header-cell>{{ 'players' | translate }}</th>
                                    <td *matCellDef="let user" [ngClass]="{'cell-selected': getUserSelection()?.isSelected(user)}"
                                        mat-cell
                                    > {{user.full_name ? user.full_name : user.sub}} </td>
                                </ng-container>

                                <ng-container matColumnDef="full_name">
                                    <th *matHeaderCellDef mat-header-cell>{{ 'name' | translate }}</th>
                                    <td *matCellDef="let user" [ngClass]="{'cell-selected': getUserSelection()?.isSelected(user)}"
                                        mat-cell> {{user.full_name}} </td>
                                </ng-container>

                                <tr *matHeaderRowDef="userDisplayedColumns; sticky: true;" mat-header-row></tr>
                                <tr (click)="getUserSelection()?.toggle(row)" *matRowDef="let row; columns: userDisplayedColumns;"
                                    mat-row></tr>

                            </table>
                        </div>
                    </div>
                    <div class="column">
                        <div class="team-selection-table row h-100">
                            <ng-container *ngFor="let entry of userSelectionMap | keyvalue">
                                <ng-container *ngIf="entry.value.selected.length > 0">
                                    <h3 class="mb-2">{{ getClassroomNameFromId(entry.key) }}</h3>
                                    <ul class="team-member-list">
                                        <ng-container *ngFor="let user of entry.value.selected">
                                            <li>{{ user.full_name ? user.full_name : user.sub }}</li>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    <div [resizeEdges]="{ bottom: true }" class="resize-handle-bottom" mwlResizeHandle>
                        <svg aria-hidden="true" class="svg-inline--fa fa-sort fa-w-10" data-icon="sort" data-prefix="fas"
                             focusable="false" role="img" viewBox="0 0 320 512"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
                                  fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
                <mat-error
                        *ngIf="getAllSelectedUsers()?.length < formArray['controls'][0].get('max_game_company_count').value * 2"
                        class="mt-3">
                    {{ 'minimumPlayersForCompetition' | translate:{min_players: formArray['controls'][0].get('max_game_company_count').value * 2} }}
                </mat-error>
                <div class="row mt-5">
                    <button class="button-primary button-secondary" mat-button
                            matStepperPrevious>{{ 'back' | translate }}</button>
                    <button (click)="formStepper(2)" [disabled]="getAllSelectedUsers()?.length < formArray['controls'][0].get('max_game_company_count').value * 2" class="button-primary"
                            mat-button>
                        {{ 'next' | translate }}
                    </button>
                </div>
            </mat-step>

            <!-- Create Team from User list -->
            <mat-step [stepControl]="formArray.get([2])" formGroupName="2">
                <ng-template matStepLabel>{{ 'createCompanies' | translate }}</ng-template>
                <div class="row mb-3">
                    <h2 class="has-tooltip">{{ 'companySetup' | translate }}
                        <button #teamsTooltip="matTooltip" attr.aria-label="{{ 'gameSettingsButton' | translate }}"
                                mat-raised-button
                                matTooltip="{{ 'playersAsCompaniesDescription' | translate }}">?
                        </button>
                    </h2>
                    <mat-button-toggle-group #teamGroup="matButtonToggleGroup"
                                             (change)="onUsersAsCompaniesChange($event)"
                                             [ngClass]="{'d-none': teamArray?.length !== 0 && teamGroup.value === 'teams'}"
                                             [value]="'individuals'"
                                             class="mb-3">
                        <mat-button-toggle aria-label="Text align center" value="individuals">
                            {{ 'playersAsCompanies' | translate }}
                        </mat-button-toggle>
                        <mat-button-toggle aria-label="Text align left" value="teams">
                            {{ 'createCompanies' | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
                <ng-container *ngIf="teamGroup.value === 'teams'">
                    <div (resizeEnd)="onCompanyResizeEnd($event)"
                         [enableGhostResize]="true"
                         [ngStyle]="{'height' : companyBoxHeight}"
                         class="row container-flex mwlResizable companyResizable"
                         mwlResizable
                    >
                        <div class="column flex-shrink-0">
                            <div *ngIf="teamDataSource.data.length > 0" class="row company-subheader">
                                <h2 class="mt-1 mb-0">{{ 'company' | translate }} {{ teamArray.length + 1 }}</h2>
                                <p [innerHTML]="('playerSelectionAmount' | translate)" class="mt-0"></p>
                            </div>
                            <div *ngIf="teamDataSource.data.length === 0" class="row company-subheader">
                                <h2 class="mb-0">{{ 'companiesComplete' | translate }}</h2>
                                <p class="mt-0">&nbsp;<br>&nbsp;</p>
                            </div>
                            <div class="team-selection-table h-100">
                                <table #teamTable [dataSource]="teamDataSource" class="create-table" mat-table>

                                    <ng-container matColumnDef="select">
                                        <td *matCellDef="let row" [ngClass]="{'cell-selected': teamSelection.isSelected(row)}"
                                            mat-cell>
                                            <mat-checkbox (change)="$event ? teamSelection.toggle(row) : null"
                                                          (click)="$event.stopPropagation()"
                                                          [aria-label]="checkboxTeamLabel(row)"
                                                          [checked]="teamSelection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sub">
                                        <td *matCellDef="let team" [ngClass]="{'cell-selected': teamSelection.isSelected(team)}"
                                            mat-cell
                                        > {{team.full_name ? team.full_name : team.sub}} </td>
                                    </ng-container>

                                    <tr (click)="teamSelection.toggle(row)" *matRowDef="let row; columns: teamDisplayedColumns;"
                                        mat-row></tr>

                                </table>
                            </div>
                            <mat-error
                                    *ngIf="teamArray.length < formArray['controls'][0].get('max_game_company_count').value * 2"
                                    class="mt-3">
                                {{ 'minimumCompaniesForCompetition' | translate:{min_companies: formArray['controls'][0].get('max_game_company_count').value * 2} }}
                            </mat-error>
                        </div>
                        <div class="column">
                            <ng-container *ngIf="teamArray.length !== 0">
                                <div class="row company-subheader mb-0">
                                    <h2 class="mb-0">{{ 'companies' | translate }}</h2>
                                    <p class="mt-0">&nbsp;<br>&nbsp;</p>
                                </div>
                                <div class="company-review-list h-100 px-3">
                                    <ng-container *ngFor="let team of teamArray.slice().reverse(); let i = index">
                                        <h3 class="mb-2">{{ 'company' | translate }} {{ teamArray.length - i }}</h3>
                                        <ul class="team-member-list">
                                            <ng-container *ngFor="let member of team">
                                                <li>{{ member.full_name ? member.full_name : member.sub }}</li>
                                            </ng-container>
                                        </ul>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div [resizeEdges]="{ bottom: true }" class="resize-handle-bottom" mwlResizeHandle>
                            <svg aria-hidden="true" class="svg-inline--fa fa-sort fa-w-10" data-icon="sort" data-prefix="fas"
                                 focusable="false" role="img" viewBox="0 0 320 512"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
                                      fill="currentColor"></path>
                            </svg>
                        </div>
                    </div>
                </ng-container>
                <div class="mt-4 container game-settings">
                    <div class="container-flex">
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfCompanies() }}</div>
                            <div class="data-content">{{ 'companies' | translate }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfGames() }}</div>
                            <div class="data-content">{{ 'firstRoundGames' | translate }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfFullGames() }}</div>
                            <div class="data-content">{{ 'gamesWithCompanies' | translate: {number: this.getCompanyCountForFullGames()} }}</div>
                        </div>
                        <div *ngIf="this.getNumberOfNotFullGames() > 0" class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfNotFullGames() }}</div>
                            <div class="data-content">{{ 'gamesWithCompanies' | translate: {number: this.getCompanyCountForFullGames() - 1} }}</div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <!-- Individuals as Teams buttons-->
                    <button *ngIf="teamGroup.value === 'individuals'" class="button-primary button-secondary" mat-button
                            matStepperPrevious>{{ 'back' | translate }}</button>
                    <button (click)="formStepper(3)" *ngIf="teamGroup.value === 'individuals'" class="button-primary"
                            mat-button>
                        {{ 'create' | translate }}
                    </button>

                    <!-- Create Teams Buttons -->
                    <button *ngIf="teamGroup.value === 'teams' && teamArray.length === 0" class="button-primary button-secondary" mat-button
                            matStepperPrevious>{{ 'back' | translate }}</button>
                    <button (click)="removeFromTeam()" *ngIf="teamGroup.value === 'teams' && teamArray.length > 0"
                            class="button-primary button-secondary"
                            mat-button>{{ 'backToCompany' | translate }} {{ teamArray.length }}</button>
                    <button (click)="addToTeam()" *ngIf="teamGroup.value === 'teams' && teamDataSource.data.length !== 0"
                            [disabled]="teamSelection.selected.length === 0 || teamArray.length === 32"
                            class="button-primary"
                            mat-button>
                        {{ 'createCompany' | translate }} {{ teamArray.length + 1 }}
                    </button>
                    <button *ngIf="teamGroup.value === 'teams' && teamDataSource.data.length === 0" [disabled]="teamArray.length < formArray['controls'][0].get('max_game_company_count').value * 2" class="button-primary"
                            mat-button
                            matStepperNext>{{ 'next' | translate }}
                    </button>
                    <mat-error *ngIf="teamArray.length === 32">
                        {{ 'maximumCompaniesGame' | translate }}
                    </mat-error>
                </div>
            </mat-step>
            <mat-step [stepControl]="formArray.get([3])">
                <ng-template matStepLabel>{{ 'review' | translate }}</ng-template>
                <div class="container game-settings">
                    <h2>{{ 'competitionSettings' | translate }}</h2>
                    <div class="container-flex">
                        <div *ngIf="formArray['controls'][0].get('scenario_id').value === null"
                             class="flex-fill text-center">
                            <div class="data-header">{{ formArray['controls'][0].get('starting_cash').value | currency:'USD':'symbol':'4.0-0' }}</div>
                            <div class="data-content">{{ 'cashOnHand' | translate }}</div>
                        </div>
                        <div *ngIf="formArray['controls'][0].get('scenario_id').value === null"
                             class="flex-fill text-center">
                            <div class="data-header">{{ formArray['controls'][0].get('duration').value }}  {{ 'quarters' | translate }}</div>
                            <div class="data-content">({{ formArray['controls'][0].get('duration').value / 4 }}
                                {{ (formArray['controls'][0].get('duration').value / 4) > 1 ? ('years' | translate) : ('year' | translate) }}
                                )
                            </div>
                        </div>
                        <div *ngIf="formArray['controls'][0].get('scenario_id').value !== null"
                             class="flex-fill text-center">
                            <div class="data-header">{{ selectedScenario?.starting_cash | currency:'USD':'symbol':'4.0-0' }}</div>
                            <div class="data-content">{{ 'cashOnHand' | translate }}</div>
                        </div>
                        <div *ngIf="formArray['controls'][0].get('scenario_id').value !== null"
                             class="flex-fill text-center">
                            <div class="data-header">{{ selectedScenario?.duration }}  {{ 'quarters' | translate }}</div>
                            <div class="data-content">({{ selectedScenario?.duration / 4 }}
                                {{ (selectedScenario?.duration / 4) > 1 ? ('years' | translate) : ('year' | translate) }}
                                )
                            </div>
                        </div>
                        <div *ngIf="formArray['controls'][0].get('scenario_id').value !== null" class="flex-fill">
                            <div class="data-header">{{ selectedScenario?.title | translate }}</div>
                            <div class="data-content">{{ 'scenario' | translate }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ formArray['controls'][0].get('turn_advancement').value === 'Timed' ? ('automatic' | translate) : ('manual' | translate) }}</div>
                            <div class="data-content">{{ 'turnCompletion' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div class="container game-settings">
                    <h2 class="mt-2 mb-3">{{ 'games' | translate }}</h2>
                    <div class="container-flex">
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfCompanies() }}</div>
                            <div class="data-content">{{ 'companies' | translate }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfGames() }}</div>
                            <div class="data-content">{{ 'firstRoundGames' | translate }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfFullGames() }}</div>
                            <div class="data-content">{{ 'gamesWithCompanies' | translate: {number: this.getMaxCompaniesPerGame()} }}</div>
                        </div>
                        <div class="flex-fill text-center">
                            <div class="data-header">{{ this.getNumberOfNotFullGames() }}</div>
                            <div class="data-content">{{ 'gamesWithCompanies' | translate: {number: this.getMaxCompaniesPerGame() - 1} }}</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-0">
                    <h2 class="mb-3">{{ 'companies' | translate }}</h2>
                </div>
                <div class="team-selection-table px-4 mb-4">
                    <ng-container *ngFor="let team of teamArray; let i = index">
                        <h3 class="mb-2">{{ 'company' | translate }} {{ i + 1 }}</h3>
                        <ul class="team-member-list">
                            <ng-container *ngFor="let member of team">
                                <li>{{ member.full_name ? member.full_name : member.sub }}</li>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
                <ng-container *ngIf="formArray['controls'][0].get('automatic_start').value">
                    <div class="row">
                        <h2 class="has-tooltip mb-0">{{ 'competitionStartTime' | translate  }}
                            <button #start_timeTooltip="matTooltip" [matTooltip]="('startGameTooltip' | translate )"
                                    mat-raised-button>?
                            </button>
                        </h2>
                    </div>
                    <div class="review-start-time mb-5">
                        <p class="m-0">
                            <span>{{ startTime | timeformat }}</span> {{ 'onDate' | translate:{startDate: startDate | localizedDate} }}
                        </p>
                    </div>
                </ng-container>
                <div>
                    <div class="d-flex">
                        <button class="button-primary button-secondary" mat-button
                                matStepperPrevious>{{ 'back' | translate }}</button>
                        <button (click)="checkCreateTime()" [disabled]="createDisabled" class="button-primary" mat-button
                                type="submit">{{ 'createCompetition' | translate }}</button>
                        <mat-spinner *ngIf="createLoading"></mat-spinner>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </form>
</div>
