import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from './../users/user.interface';
import { UserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  currentUser: User;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  open = false;

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    console.log('nav currentUser', this.currentUser);
  }

  toggleMenu() {
    console.log('toggled');
    this.open = !this.open;
  }

  logout() {
    // save users from localStorage before clearing the rest
    const users = localStorage.getItem('users');
    localStorage.clear();
    localStorage.setItem('users', users);

    this.authenticationService.logout();
    // this.router.navigate(['/login']);
  }

}
