import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';

import {User} from '../users/user.interface';
import {Globals} from '../globals';
import {UserService} from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import {Router} from '@angular/router';
import {IUserTokenResponse} from "./model/IUserTokenResponse";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    public currentUser: Observable<User>;
    private currentUserSubject: BehaviorSubject<User>;
    private localUrl: string;

    constructor(
        private http: HttpClient,
        private globals: Globals,
        private userService: UserService,
        private router: Router
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.localUrl = globals.localUrl;

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public getJAIDServerInfo() {
        return this.http.get<any>(`${this.globals.backendUrl}/api/auth/get-jaid-info`,
            {
                headers: new HttpHeaders()
                    .set('API_KEY', this.globals.api_key)
            })
            .pipe(map(res => {
                this.globals.authServer = res['iss'];
                this.globals.authClientId = res['client_id'];
                this.globals.authResponseType = res['response_type'];
                this.globals.authScope = res['scope'];
                return res;
            }));
    }

    public getDowngradedJwt() {
        const endpointUrl = `${this.globals.backendUrl}/api/auth/jwt/downgrade`;
        return this.http.post<any>(
            endpointUrl,
            {},
            {
                headers: new HttpHeaders()
                    .set('API_KEY', this.globals.api_key)
            }
        )
            .pipe(map(user => {
                if (user && user.access_token) {
                    // Decode access token
                    const decoded_access_token = JSON.parse(atob(user.access_token.split('.')[1]));
                    // Pull user info from api/user/me endpoint
                    return this.userService.readUserMeApiUserMeGet()
                        .subscribe(
                            current_user => {
                                if (current_user.id === decoded_access_token.user.id) {
                                    user = Object.assign(user, current_user);
                                    user['role'] = decoded_access_token.user.role;
                                    localStorage.setItem('downgradedToken', JSON.stringify(user));
                                    return user;
                                }
                            },
                            error => {
                                console.log('no user found');
                            }
                        );
                }
            }));
    }

    public refreshDowngradedJwt() {
        const downgradedToken = JSON.parse(
            localStorage.getItem('downgradedToken'));
        const endpointUrl = `${this.globals.backendUrl}/api/auth/jwt/refresh`;
        return this.http.post<any>(
            endpointUrl,
            downgradedToken,
            {
                headers: new HttpHeaders()
                    .set('API_KEY', this.globals.api_key)
            }
        )
            .pipe(map(user => {
                if (user && user.access_token) {
                    // Overwrite downgraded tokens (access token, expires and expires_max) values.
                    user = Object.assign(downgradedToken, user);
                    localStorage.setItem('downgradedToken', JSON.stringify(user));
                    return user;
                }
            }));
    }

    public currentUserIdValue() {

        this.currentUser.subscribe(
            user => {
                console.log(this.currentUserSubject.value);
                return this.currentUserSubject.value;
            }
        );
        return 0;

    }

    loginWithJaid(code: string, redirectUri: string) {
        return this.handleJAIDCode(code, redirectUri).pipe(switchMap((userTokenResponse: IUserTokenResponse) => {
            const httpOptions = {
                headers: new HttpHeaders({
                    'API_KEY': this.globals.api_key
                })
            };

            return this.http.post<any>(
              `${this.globals.backendUrl}/api/auth/pass-token`,
              {
                  access_token: userTokenResponse.access_token,
                  token_type: userTokenResponse.token_type,
                  iss: this.globals.authServer,
                  expires: userTokenResponse.expires_in
              }, httpOptions).pipe(map(user => {
                  let userResponses: {};

                  if(user && user.redirect){
                      window.location.href = user.redirect;
                  }else if (user && user.access_token) { // login successful if there's a jwt token in the response
                      localStorage.clear();
                      // store user details and jwt token in local storage to keep user logged in between page refreshes
                      userResponses = user;
                      this.currentUserSubject.next(user);
                      localStorage.setItem('currentUser', JSON.stringify(userResponses));

                      this.userService.readUserMeApiUserMeGet()
                        .subscribe(
                            current_user => {
                                userResponses = Object.assign(user, current_user);
                                localStorage.setItem('currentUser', JSON.stringify(userResponses));
                                this.currentUserSubject.next((userResponses as any));
                            },
                            error => {
                                console.log('no users found');
                            }
                        );


                  }

                  return user;
            }));
        }));
    }

    private handleJAIDCode(code: string, redirectUri: string) {
        const jaidHttpOptions = {
            headers: new HttpHeaders({
                'content-type': 'application/x-www-form-urlencoded',
                'cache-control': 'no-cache',
                'accept': 'application/json'
            })
        };

        return this.http.post<any>(`${this.globals.authServer}/oauth2/default/v1/token`, `grant_type=authorization_code&scope=${this.globals.authScope}&client_id=${this.globals.authClientId}&code=${code}&code_verifier=${localStorage.getItem('authPKCEVerifier')}&redirect_uri=${redirectUri}`, jaidHttpOptions)
            .pipe(map((userTokenResponse: IUserTokenResponse) => {
                if (!userTokenResponse || !userTokenResponse.access_token) return; //If authentication did not happen properly, return
                return userTokenResponse;
            }));
    }

    login(loginParams) {
        return this.http.post<any>(`${this.globals.backendUrl}/api/auth/password`,
            loginParams,
            {
                headers: new HttpHeaders()
                    .set('API_KEY', this.globals.api_key)
            })
            .pipe(map(user => {
                let userResponses: {};

                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    localStorage.clear();
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if (user.firstName === undefined) {
                        console.log('sub', (loginParams as any).sub);
                        user.firstName = (loginParams as any).sub;
                    }
                    userResponses = user;
                    this.currentUserSubject.next(user);
                    localStorage.setItem('currentUser', JSON.stringify(userResponses));

                    this.userService.readUserMeApiUserMeGet()
                        .subscribe(
                            current_user => {
                                userResponses = Object.assign(user, current_user);
                                localStorage.setItem('currentUser', JSON.stringify(userResponses));
                                this.currentUserSubject.next((userResponses as any));
                            },
                            error => {
                                console.log('no users found');
                            }
                        );
                }

                return user;
            }));
    }

    logout(message?: any) {
        console.log('logout message', message);
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('downgradedToken');
        this.currentUserSubject.next(null);

        if (message !== undefined) {
            this.router.navigate(['/login'], {
                state: {detail: message}
            });
        } else {
            this.router.navigate(['/login']);
        }
    }
}
