<header>
    <div class="container container-flex justify-content-between">
        <div>
            <h1>{{ 'competitions' | translate }}</h1>
        </div>
        <div *ngIf="currentUser.role !== 'student'" class="container-flex align-items-center">
            <button [routerLink]="['/competitions/create']"
                    class="button-primary">{{ 'createNewCompetition' | translate }}</button>
        </div>
    </div>
</header>

<div *ngIf="loading" class="d-flex justify-content-center">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="dataSource?.data?.length > 0" [ngClass]="{'d-none': loading}" class="row">
    <table #gameTable [dataSource]="dataSource" class="mat-selectable" mat-table>
        <ng-container matColumnDef="id">
            <th *matHeaderCellDef mat-header-cell>{{ 'id' | translate }}</th>
            <td *matCellDef="let competition" mat-cell><span class="column-width">{{competition.id}}</span></td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>{{ 'name' | translate }}</th>
            <td *matCellDef="let competition" mat-cell><span class="column-width">{{competition.name}}</span></td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th *matHeaderCellDef mat-header-cell>{{ 'status' | translate }}</th>
            <td *matCellDef="let competition" mat-cell>{{ competition.status | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="current_round">
            <th *matHeaderCellDef mat-header-cell>{{ 'round' | translate }}</th>
            <td *matCellDef="let competition" mat-cell><span class="column-width">
                {{competition.current_round}} / {{competition.round_count}}
            </span></td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true;" mat-header-row></tr>
        <tr (click)="selection.toggle(row)" *matRowDef="let row; columns: displayedColumns;" [routerLink]="[ '/competitions/detail', row.id ]"
            mat-row></tr>
    </table>

    <div class="my-3">
        <mat-paginator (page)="updatePage($event)"
                       [length]="pageTotal"
                       [pageSizeOptions]="pageSizeOptions"
                       [pageSize]="pageSize">
        </mat-paginator>
    </div>
</div>

<ng-container *ngIf="dataSource?.data?.length === 0 && loading === false">
    <div [ngSwitch]="currentUser.role" class="flex-container empty-container">
        <div *ngSwitchCase="'student'" class="p-5 text-center">
            <p>{{ 'noCompetitionsToDisplay' | translate }}</p>
        </div>
        <div *ngSwitchDefault class="p-5 text-center">
            <p>{{ 'noCompetitionsToDisplay' | translate }}</p>
            <p>{{ 'clickToCreateNewCompetition' | translate }}</p></div>
    </div>
</ng-container>

