<h1>Header 1</h1>
<h2>Header 2</h2>
<label for="text-example">Buttons</label>
<button class="button-primary">Button</button>
<button class="button-primary button-active">Button Active</button>
<div class="button-group">
    <div>
        <input checked class="radio-custom" id="radio-1" name="radio-group" type="radio">
        <label class="radio-custom-label" for="radio-1">First Choice</label>
    </div>
    <div>
        <input class="radio-custom" id="radio-2" name="radio-group" type="radio">
        <label class="radio-custom-label" for="radio-2">Second Choice</label>
    </div>
</div>

<mat-button-toggle-group #group="matButtonToggleGroup">
    <mat-button-toggle aria-label="Text align left" value="first">
        First Choice
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align center" value="second">
        Second Choice
    </mat-button-toggle>
</mat-button-toggle-group>

<mat-button-toggle-group #group1="matButtonToggleGroup">
    <mat-button-toggle aria-label="Text align left" value="first">
        First Choice
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align center" value="second">
        Second Choice
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align right" value="third">
        Third Choice
    </mat-button-toggle>
</mat-button-toggle-group>

<mat-button-toggle-group #group2="matButtonToggleGroup">
    <mat-button-toggle aria-label="Text align left" value="first">
        4 quarters<br>(1 year)
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align center" value="second">
        8 quarters<br>(2 years)
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align right" value="third">
        12 quarters<br>(3 years)
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align center" value="fourth">
        16 quarters<br>(4 years)
    </mat-button-toggle>
    <mat-button-toggle aria-label="Text align right" value="fifth">
        20 quarters<br>(5 years)
    </mat-button-toggle>
</mat-button-toggle-group>

<!-- <div class="example-selected-value">Selected value: {{group.value}}</div>
 -->

<div class="button-group">
    <div>
        <input checked class="radio-custom" id="radio-1a" name="radio-group" type="radio">
        <label class="radio-custom-label" for="radio-1a">First Choice</label>
    </div>
    <div>
        <input class="radio-custom" id="radio-2a" name="radio-group" type="radio">
        <label class="radio-custom-label" for="radio-2a">Second Choice</label>
    </div>
    <div>
        <input class="radio-custom" id="radio-3a" name="radio-group" type="radio">
        <label class="radio-custom-label" for="radio-3a">Third Choice</label>
    </div>
</div>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
    aliqua. Fames ac turpis egestas integer eget aliquet. Condimentum vitae sapien pellentesque habitant morbi tristique
    senectus et netus.</p>
<p>Quam nulla porttitor massa id neque aliquam vestibulum. Iaculis nunc sed augue lacus viverra vitae. Volutpat ac
    tincidunt vitae semper quis lectus nulla at volutpat. Egestas sed sed risus pretium quam vulputate dignissim. Id
    neque aliquam vestibulum morbi blandit cursus.</p>
<ul>
    <li>List Item</li>
</ul>
<nav aria-label="Breadcrumb" class="breadcrumb">
    <ol>
        <li>
            breadcrumb
        </li>
    </ol>
</nav>
<label for="text-example">Text Input Label</label>
<input id="text-example" name="text-example" placeholder="description of field" type="text">

<label for="text-example">Number Input Label</label>
<input id="appt" max="24:00" min="00:00" name="appt"
       placeholder="H:M:PM" required type="time">


<label for="drop-down-example">Drop Down</label>
<select id="" name="drop-down-example" placeholder="search for scenario">
    <option value="null">Select Option</option>
    <option value="1">Option 1</option>
    <option value="1">Option 1</option>
</select>
<nav>
    <ul>
        <li><a href="#">Menu Topic</a></li>
        <li><a class="has-children router-link-active" href="#">Menu with Subs</a>
            <ul>
                <li><a href="">Lorem Ipsum</a></li>
                <li><a href="">Lorem Ipsum</a></li>
                <li><a href="">Lorem Ipsum</a></li>
            </ul>
        </li>
    </ul>
</nav>

<div>
    <button (click)="openDialog()" mat-raised-button>Open Modal</button>
    <div *ngIf="result">
        You chose: <i>{{result}}</i>
    </div>
</div>