import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { DialogComponent } from '../shared/dialog/dialog.component';
import { AuthenticationService } from '../auth/authentication.service';

import { User } from './../users/user.interface';

import { UserService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Game } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';

import { Globals } from '../globals';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  open = false;
  result: string;
  currentUser: User;

  loading = false;
  games: Game[];

  // Pagination arguments
  pageIndex = 0;
  pageSize = 10;

  showEmptyMessage = true;

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private globals: Globals
  ) {}

  delegateRefreshDowngradedJwt() {
      return this.authenticationService.refreshDowngradedJwt()
          .subscribe(user => {
              console.log('Refreshed downgraded token:', user);
          });
  }

  onPlayGame() {
      const downgradedToken = JSON.parse(localStorage.getItem('downgradedToken'));
      const tokenBase64 = downgradedToken['access_token'];
      const tokenType = downgradedToken['token_type'];
      const link = `${this.globals.gameUrl}/auth/token#access_token=${tokenBase64}&token_type=${tokenType}`;
      window.open(link, '_self');
  }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(
        user => {
            console.log('user.id', user.id);
            // Get current user's games
            this.currentUser = user;
            this.getGames(this.currentUser.id);
        }
    );

    console.log('this.currentUser', this.currentUser);

    // Pull an existing downgraded token (if any).
    // Then check if its still valid (expiration date has not been surpassed)
    let downgradedToken = localStorage.getItem('downgradedToken');
    const current_datetime = new Date();
    if (downgradedToken) {
        console.log('Reloaded existing downgraded token');
        downgradedToken = JSON.parse(downgradedToken);
        // Convert epochs to time objects
        const expires = new Date(downgradedToken['expires'] * 1000);
        const expires_max = new Date(downgradedToken['expires_max'] * 1000);

        if (expires >= expires_max || current_datetime > expires) {
            console.log('Token has expired.');
            // null out the downgraded token so a new one is generated below
            downgradedToken = null;
        }
    }
    // Generate a new downgraded token
    if (!downgradedToken) {
        this.authenticationService.getDowngradedJwt()
        .subscribe(
            user => {
                console.log('Generated new downgraded token.');
            }
        );
    }
  }

  getGames( userId: number ) {
    console.log('userId', userId);
    this.loading = true;

    if ( userId !== 0 && userId !== null && userId !== undefined ) {
      this.userService.readUserAllGamesApiUserUserIdAllGamesGet(userId, '', this.pageIndex, this.pageSize).subscribe(
        results => {
          console.log('results', results);
          this.games = results.items;
          this.loading = false;

          if ( this.games.length > 0 ) {

            this.games.forEach(game  => {

              if ( game.is_active === true ) {

                // if game still active and user not a student
                this.showEmptyMessage = false;

              }

            });

          }
        },
        error => {
          console.log('no games found');
        });
      }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '450px',
      data: { message: 'Are you sure you want to revert?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.result = result;
    });
  }

  toggleMenu(): void {
    // TODO: Implement this
  }

}
