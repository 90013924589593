import {Component, OnInit} from '@angular/core';

import {Area} from './area.interface';
import {AreaService} from './area.service';

@Component({
    selector: 'app-areas',
    templateUrl: './areas.component.html',
    styleUrls: ['./areas.component.scss']
})
export class AreasComponent implements OnInit {

    model: Area;
    areas: [{ model }];

    constructor(
        private areaService: AreaService
    ) {
    }

    ngOnInit() {
        this.areaService.getUsers().subscribe(
            results => {
                console.log(results.results);
                this.areas = results.results;
            },
            error => {
                // this.router.navigate(['/courses']);
                console.log('no users found');
            });
    }

}
