import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';

import { Team } from '../../teams/team.interface';
import { TeamsService } from '../../teams/teams.service';

import { GameService, CompanyService } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { Company, VictoryCurrency } from '@jaworldwideorg/staging-jaworldwide-titan-sdk';
import { AuthenticationService } from 'src/app/auth/authentication.service';

import { User } from '../../users/user.interface';


@Component({
  selector: 'app-game-detail-results',
  templateUrl: './game-detail-results.component.html',
  styleUrls: ['./game-detail-results.component.scss']
})
export class GameDetailResultsComponent implements OnInit {

  @Input() gameId: number;
  @Input() activeCompaniesInput: Company[];
  @Input() userCompanyId: number;

  loading = false;

  currentUser: User;

  model: Company;
  teams: [{model}];

  activeCompanies: Company[];
  gameResults: VictoryCurrency[];
  combinedCompanyResults: any[] = null;

  displayedColumns: string[] = ['rank', 'company', 'companyLink', 'budget', 'research', 'production', 'csr', 'marketing', 'total' ];
  dataSource = new MatTableDataSource(this.combinedCompanyResults);
  selection = new SelectionModel(true, []);
  dataExists = true;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private gameService: GameService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.currentUser = this.authenticationService.currentUserValue;

    this.getCompanyGameResults();

  }

  getCompanyGameResults() {

    this.loading = true;

    // console.log('activeCompanies Input', this.activeCompaniesInput);

    if ( this.gameId !== null && this.gameId !== undefined ) {
      this.gameService.readGameResultsApiGameGameIdResultsGet(this.gameId)
          .subscribe(
            results => {
              console.log('Game' + this.gameId, results);
              this.gameResults = results;

              if (results.length > 0) {
                const vcOnly = results.map(({ company_id, id, ...item }) => item);

                vcOnly.forEach((vc, index) => {
                  const vcValues = Object.values(vc);
                  (this.gameResults as any)[index].total = vcValues.reduce(function(a, b) {
                    return a + b;
                    }, 0);
                  // console.log('(vc as any).total', (vc as any).total);
                });

                // sort by total and reverse array
                this.gameResults.sort((a, b) => ((a as any).total > (b as any).total) ? 1 : -1).reverse();
                console.log('this.gameResults after sort', this.gameResults);

                // add a rank property to quarterResults
                this.gameResults.forEach((element, index) => {
                  (element as any).rank = index;
                  (element as any).linkState = true;
                });
                console.log('this.gameResults after adding rank', this.gameResults);

                // TODO change linkState to restrict access to only their company
                if ( this.currentUser.role === 'student') {
                  this.gameResults.forEach((element, index) => {
                    (element as any).linkState = false;

                    if ( element.company_id === this.userCompanyId) {
                      (element as any).linkState = true;
                    }
                  });
                }
                console.log('this.gameResults after adding linkState', this.gameResults);

                // merge array by matching properties
                const merge = (arr1, arr2) => {
                  const temp = [];

                  arr1.forEach(x => {
                    arr2.forEach(y => {
                      if (x.company_id === y.id) {
                        temp.push({ ...x, ...y });
                      }
                    });
                  });

                  return temp;
                };

                this.combinedCompanyResults = merge(this.gameResults, this.activeCompaniesInput);
                console.log('this.combinedCompanyResults after merge', this.combinedCompanyResults);
                this.dataSource.data = this.combinedCompanyResults;
                console.log('this.dataSource.data', this.dataSource.data);
              } else {
                this.dataExists = false;
              }

              // console.log('this.dataSource', this.dataSource);
              this.loading = false;

            }
          );
      }
  }

}
