import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {DialogComponent} from '../shared/dialog/dialog.component';

@Component({
    selector: 'app-styleguide',
    templateUrl: './styleguide.component.html',
    styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent implements OnInit {

    open = false;
    result: string;

    constructor(public dialog: MatDialog) {
    }


    ngOnInit() {
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            width: '450px',
            data: {message: 'Are you sure you want to revert?'}
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
            this.result = result;
        });
    }

    toggleMenu(): void {
        // TODO: Implement this
    }

}

