import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../auth/authentication.service';
import {Router} from '@angular/router';
import {AlertService} from '../alert/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private readonly translateService: TranslateService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {

            console.log('error', error);

            // messages that we can avoid reporting to user
            const messages_to_ignore = [
                'Needs a new JASA token',
                'Could not validate credentials'
            ];
            let logout_and_redirect = false;
            let detail = null;

            if (error.hasOwnProperty('error') && error.error.hasOwnProperty('detail')) {
                detail = error.error.detail;
            }

            const errorDetail = this.translateService.instant(detail.code, detail.params);

            if (error.status === 401) {
                logout_and_redirect = true;
            } else if (error.status === 403 && errorDetail) {
                logout_and_redirect = true;

                if (messages_to_ignore.indexOf(errorDetail) >= 0) {
                    detail = this.translateService.instant('youWereLoggedOut');
                } else {
                    detail = this.translateService.instant('loggedOutAlert') + errorDetail;
                }
            }

            if (logout_and_redirect) {
                console.log('logout_and_redirect', logout_and_redirect);
                console.log('detail', detail);

                // this.alertService.error(detail, true);
                this.authenticationService.logout(detail);
                // this.router.navigate(['/login']);
            }

            return throwError(error);
        }));
    }
}
