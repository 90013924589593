import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../globals';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class GameServiceLocal {

    defaultHeaders: HttpHeaders;
    private configUrl: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Accept': 'text/html, application/xhtml+xml, */*',
            'Content-Type': 'application/x-www-form-urlencoded'
        }),
        responseType: 'text'
    };

    constructor(
        private http: HttpClient,
        private globals: Globals,
    ) {
        this.configUrl = globals.configUrl;
    }

    downloadCsv(id: number): Observable<Blob> {
        this.defaultHeaders = new HttpHeaders();
        const headers = this.defaultHeaders;

        const site = `${this.globals.backendUrl}/api/game/${encodeURIComponent(String(id))}/csv`;
        return this.http.get(site, {
            headers: headers,
            observe: 'body',
            reportProgress: false,
            responseType: 'blob'
        });
    }

    getGameUsers(game_id: number) {
    }


}
